import { createCheckoutBasket } from "@/lib/helper/items";
import { ShippingTypeEnum } from "@/utils/ShippingTypeEnum.ts";

const getReferrerId = (component) => {
  try {
    let referrerId = 1;
    if (component.$touriConfig.runMode === "app") {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i)
      ) {
        referrerId = 12;
      }
      if (navigator.userAgent.match(/Android/i)) {
        referrerId = 13;
      }
    }

    if (
      component.$session.exists("referrerId") &&
      !isNaN(component.$session.get("referrerId"))
    )
      referrerId = component.$session.get("referrerId");

    return referrerId || 2; // 2 = WebShop
  } catch (e) {
    component.$log.error(e);
  }
};
export const getCheckoutData = (component) => {
  if (!component?._isVue) throw new Error(`component is not a VueComponent`);

  const items = createCheckoutBasket(component.$shoppingBasket.uniqueItems);
  const voucherCode = component.$shoppingBasket.discounts.length
    ? component.$shoppingBasket.discounts[0]?.id
    : undefined;
  const shippingType =
    component.$shoppingBasket.shipping?.id || ShippingTypeEnum.Email;
  const referrerId = getReferrerId(component);

  return { items, voucherCode, shippingType, referrerId: parseInt(referrerId) };
};
