<template lang="pug">
  .modal-dialog
    .modal-content
      .modal-header
        h3.modal-title Login
        .float-right
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body
          LoginForm
              template(v-slot:submitButton="{submit}")
                .text-center
                  button.button.button-primary.button-tdays.w-100.mt-3(@click="submit") Anmelden
          hr
          router-link.button.button-secondary.button-tdays.w-100(:to="{name:'UserCreation'}") Registrieren
</template>

<script>
import EventBus from "@/event-bus";
import LoginForm from "@/views/components/userAccount/LoginForm.vue";
import RegistrationForm from "@/views/components/userAccount/RegistrationForm.vue";
import RegistrationBulletinPoints from "@/views/components/userAccount/RegistrationBulletinPoints.vue";

export default {
  name: "LoginModal",
  components: { LoginForm, RegistrationForm, RegistrationBulletinPoints },
  watch: {
    $route() {
      this.close();
    },
  },
  mounted() {
    EventBus.$on(
      "userLoggedIn",
      function () {
        this.close();
      }.bind(this)
    );
    EventBus.$on(
      "closeLoginModal",
      function () {
        this.close();
      }.bind(this)
    );
  },
  methods: {
    close() {
      try {
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
