<template lang="pug">
  .shopping-basket-item
    header.bg-td-grey-basic.txt-white.d-flex.justify-content-between
      b.name.p-3.position-relative(v-if="Name") {{ Name }}
        router-link.link.d-block(v-if="itemUrl" :to="{path:itemUrl}")
      .remover
        .bg-white
          button.button.button-icon.button-secondary.button-td-grey.remove-item-button(@click="$emit('remove');" v-if="!readonly")
            font-awesome-icon(icon="trash")
    .item-body.bg-white
      .image
        img.img-fluid(v-if="ImageUrl" :src="itemImageUrl")
      .voucher-details.d-flex.align-items-center.justify-content-center
        VoucherDetailCard.txt-td-grey-basic(v-for="detail in voucherDetails" :value="detail.value" :title="detail.title" :icon="detail.icon" v-if="showsVoucherDetails")
      .d-flex.align-items-center.justify-content-end(v-if="RegularPrice || CurrentPrice")
        .price.text-right
          .regular-price.txt-td-grey-basic(v-if="RegularPrice && CurrentPrice !== RegularPrice")
            span(:class="{'line-through':RegularPrice && CurrentPrice}") {{ RegularPrice | formatPrice }}
            sup 1
          .h2.current-price(v-if="CurrentPrice") {{ CurrentPrice | formatPrice }}
      .quantity.text-center.d-flex.align-items-center(style="max-width:40px;")
        QuantityInput(:value="Quantity || 1" :max="maxQuantity" @change="$emit('changeQuantity',$event);" @exceed="$emit('exceedQuantity')" v-if="!readonly")
    footer.small.txt-white.d-flex.align-items-center.justify-content-between.p-1(:class="{[`discount-${DiscountType}`]:IsDiscounted}" v-if="IsDiscounted")
      div(v-if="DiscountType === DiscountTypesEnum.LiveShopping") Dealpreis
      div(v-else-if="DiscountType === DiscountTypesEnum.TippsDiscount") touriTipps - Aktionspreis
</template>
<script>
import VoucherDetailCard from "@/views/components/partials/voucherDetailCard/voucherDetailCard.vue";
import QuantityInput from "@/views/checkout/partials/QuantityInput.vue";
import { DiscountTypesEnum } from "@/utils/DiscountTypesEnum.ts";

export default {
  name: "ShoppingBasketItem",
  components: { QuantityInput, VoucherDetailCard },
  props: {
    id: String,
    Name: String,
    Catering: String,
    Nights: Number,
    Persons: Number,
    IsDiscounted: Boolean,
    Quantity: Number,
    CurrentPrice: Number,
    RegularPrice: Number,
    ImageUrl: String,
    HostUrl: String,
    DiscountType: Number,

    showsVoucherDetails: Boolean,
    linkToItem: {
      type: Boolean,
      default: true
    },

    imageSize: {
      type: String,
      default: "small"
    },

    maxQuantity: {
      type: Number,
      default: Infinity
    },

    readonly: Boolean
  },

  setup(props) {
    const voucherDetails = [
      { value: props.Persons, title: "Personen", icon: "user-group" },
      { value: props.Nights, title: "Nächte", icon: "moon" },
      { value: props.Catering, title: "Verpflegung", icon: "utensils" }
    ];
    return { voucherDetails, DiscountTypesEnum };
  },

  computed: {
    itemUrl() {
      if (!this.HostUrl || !this.id) return;
      return `/gastgeber/${this.HostUrl}${
        this.IsDiscounted &&
        this.DiscountType === DiscountTypesEnum.TippsDiscount
          ? "?tipps=true"
          : ""
      }${this.id && this.linkToItem ? `#${this.id}` : ""}`;
    },
    itemImageUrl() {
      return `${this.ImageUrl}${this.imageSize}.jpg`;
    }
  },

  methods: {
    getDiscountType() {
      if (!this.IsDiscounted) return;
      const tippsDiscounts = this.$session.get("appliedTippsDiscounts") || [];
      if (tippsDiscounts.includes(this.id))
        return DiscountTypesEnum.TippsDiscount;
      return DiscountTypesEnum.LiveShopping;
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.shopping-basket-item {
  &.unavailable {
    opacity: 0.5 !important;
  }

  .item-body {
    position: relative;
    display: grid;
    grid-template-columns: 25% 1fr 1fr 45px;
    gap: 1rem;

    .image {
      aspect-ratio: 4 / 3;
    }
  }

  .name {
    .link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  footer {
    background-color: $td-grey-basic;

    &.discount-1 {
      background-color: $tpics-basic;
    }

    &.discount-2 {
      background-color: $ttipps-light;
    }
  }
}
</style>
