<template lang="pug">
div
</template>

<script>
export default {
  name: "DiscountVoucherCategory",
  mounted() {
    // reroute the discount voucher host:
    try {
      this.$router.replace({ path: "/gastgeber/touridat-wertgutschein" });
    } catch (e) {
      this.$log.error(e);
    }
  },
};
</script>

<style scoped></style>
