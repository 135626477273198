<template lang="pug">
  button.no-style.easter-egg(@click="collectEgg" :style="random ? randomStyle : undefined" :class="random ? [randomColor] : undefined")
    svg.shape.w-100.h-100.absolute(xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve")
      path(d="M596.2,970.5c0,31-25.2,56.2-56.2,56.2c-31,0-56.2-25.2-56.2-56.2c0-31,25.2-56.2,56.2-56.2C571.1,914.3,596.2,939.4,596.2,970.5")
      ellipse(style="fill:#fff" cx="538" cy="689" rx="346" ry="377.9")
      defs
        rect(id="layer1" x="131.3" y="40.4" width="817" height="999.2")
      clipPath(id="clip-path")
        use(xlink:href="#layer1" style="overflow:visible;")
      g(style="clip-path:url(#clip-path);")
        path(style="fill:currentColor;" d="M917.9,781.4c-32.7,81.9-100,173-191.9,229.4c-18,11-36.9,20.8-56.7,28.8c31.2-32.9,50.2-77.3,50.1-126.2c-0.2-59.3-28.7-111.9-72.7-145.1c8.8-17.1,13.7-36.5,13.7-57.1c-0.1-30.4-11.2-58.3-29.4-79.8c8.8-9,17.8-19,26.9-30.2c16.3-20.2,48.1-59.9,62.6-112.1c7.8-28.1,21.7-99.8-4.9-114.5c-23-12.7-68.3,21.4-91.3,48.1c-25.3,29.2-59.9,91.3-62.3,118.3c-0.2,2.6-0.9,12.6-7.7,17.4c-4.1,2.9-10.3,3.9-14.8,1.4c-6.4-3.5-7.2-13.1-7.5-15.7c-3-25.6-35.5-91.8-67.9-123.6c-22.9-22.4-65-46.7-86.8-33c-28.2,17.7-8.7,90.7-6.8,97.7c8.9,32.3,25.5,57.4,51,94.1c8.3,11.9,18.4,25.8,30.4,41.2c-24.6,22.8-39.9,55.4-39.8,91.5c0.1,20.6,5.1,40,14.1,57c-43.7,33.5-71.8,86.3-71.6,145.6c0.2,46.9,18,89.5,47.1,121.7c-115.7-50.2-199.8-157-237.9-250.6C49.5,505.2,258.3,118.2,473.5,50.6c21-6.6,42.1-10.1,62.8-10.2C770.7,39.1,1041.1,472.1,917.9,781.4")
</template>
<script>

import { MarketingPromoContestParticipants } from "@/graphql/MarketingPromoContestParticipants.ts";
import EventBus from "@/event-bus";
import { getRandomIntInRange } from "@/lib/helper/math";

export default {
  name: "EasterEgg",
  emits: ["collected"],
  props: {
    random: Boolean,
    interactable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async showAlert(text, title) {
      const args = {
        text,
        customClass: {
          header: "td-easter-alert-header",
          actions: "td-easter-alert-footer",
          confirmButton: "button button-primary button-tdays",
          container: "td-easter-alert"
        },
        buttonsStyling: false
      };
      if (title) args.title = title;
      this.$fire(args);
    },
    async getRandomDiscount() {
      return await this.$apollo.query({
        query: MarketingPromoContestParticipants.Queries.GetRandomPromoParticipantDiscountCode
      }).then((response) => response?.data?.getRandomPromoParticipantDiscountCode);
    },
    async confirmSaveNew(oldDiscount, newDiscount) {
      if (oldDiscount.Value >= newDiscount.Value) return false;
      try {
        return await this.$confirm(
          `Den bereits vorhandenen ${oldDiscount.Value}% Rabatt-Gutschein gegen einen ${newDiscount.Value}% Rabatt-Gutschein eintauschen?`
        );
      } catch {
        return false;
      }
    },
    async save(orderDiscountCode) {
      const currentValue = await this.$apollo.query({
        query: MarketingPromoContestParticipants.Queries.CurrentMarketingPromoDiscount,
        variables: {
          participantId: this.$session.get("easterParticipantId")
        }
      }).then((response) => response?.data?.currentMarketingPromoDiscount?.OrderDiscountCode);
      if (!currentValue) {
        await this.$apollo.mutate({
          mutation: MarketingPromoContestParticipants.Mutations.CreateMarketingPromoParticipantDiscount,
          variables: {
            orderDiscountCodeId: orderDiscountCode.id,
            participantId: this.$session.get("easterParticipantId")
          }
        });
      } else {
        await this.$apollo.mutate({
          mutation: MarketingPromoContestParticipants.Mutations.CreateMarketingPromoParticipantDiscount,
          variables: {
            orderDiscountCodeId: orderDiscountCode.id,
            participantId: this.$session.get("easterParticipantId")
          }
        });
      }
    },
    async collectEgg() {
      if (!this.interactable) return;
      EventBus.$emit("changeLoadingState", true);
      const orderDiscountCode = await this.getRandomDiscount();
      if (!orderDiscountCode) {
        EventBus.$emit("changeLoadingState", false);
        await this.showAlert("Diesmal hat es Leider nicht geklappt", "Niete");
        this.$emit("collected");
        return;
      }
      EventBus.$emit("changeLoadingState", false);
      await this.showAlert(`Ein ${orderDiscountCode.Value}% Rabatt-Gutschein wurde Ihrem Warenkorb hinzugefügt`, "Herzlichen Glückwunsch!");
      await this.save(orderDiscountCode);
      await this.$shoppingBasket.setDiscount(0, {
        id: orderDiscountCode.Code,
        isPercentage: orderDiscountCode.IsPercentage,
        value: orderDiscountCode.Value
      });
      console.log(this.$shoppingBasket.discounts);
      this.$emit("collected");
    }
  },
  computed: {
    randomColor() {
      const colors = [
        "txt-tdays-light",
        "txt-tpics-basic",
        "txt-tdeluxe-lighter",
        "txt-tgolf-dark"
      ];
      return colors[getRandomIntInRange(0, colors.length - 1)];
    },
    randomStyle() {
      const orientation = getRandomIntInRange(1, 100) >= 50 ? "left" : "right";
      const x = getRandomIntInRange(0, 15);
      const y = getRandomIntInRange(0, 90);


      return `${orientation}:${x}%;top:${y}%;width:75px;`;
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/colors";

.easter-egg {
  transition: all 0.4s;
  z-index: 999;
  /*
  position: absolute;

  .shape {
    fill: currentColor;
    filter: drop-shadow(0 0 10px rgba(34, 34, 34, 0.25));
  }

  &:hover {
    transform: scale(1.1);
  }

   */
}
</style>
<style lang="scss">
.td-easter-alert-header,
.td-easter-alert-footer {
  position: relative;
}

.td-easter-alert-header {
  &:before {
    content: "";
    width: 300px;
    height: 240px;
    background-image: url("@/assets/images/eddi.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translate(-50%, -100%);
  }
}
</style>
