<template lang="pug">
  router-link.xmas-header(:to="to")
    img.stars(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/marketing/xmas/littletstars-left-sided.png" style="max-height:40px;align-self:flex-end")
    .d-block.my-1.d-md-flex.align-items-center.justify-content-between
      .text-center.text-box
        div(style="font-size:0.75rem;") Der beliebte touriDat-Adventskalender:
        b(style="font-size:0.875rem;") Mehr als 300 Preise im Wert von über 45.000 € zu gewinnen!
      .text-center
        span.span-btn.p-1.m-md-4(style="text-decoration:none!important") jetzt anmelden
    img.stars(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/marketing/xmas/littletstars-right-sided.png" style="max-height:40px;align-self:flex-start")
</template>
<script>
export default {
  name: "XmasHeader",
  props: {
    to: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.xmas-header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  background: linear-gradient(45deg, rgba(44, 98, 41, 1) 0%, rgba(72, 131, 69, 1) 50%, rgba(44, 98, 41, 1) 100%);
  text-decoration: none;

  &:hover {
    .text-box {
      text-decoration: underline;
    }
  }

  .stars {
    height: 100%;
    width: auto;
    max-height: 40px;
  }

  .span-btn {
    background-color: #d7c8a8;
    color: #fff;
    font-size: 0.75rem;
    transition: all 0.4s;
    margin: 1rem 0;
    padding: 0 0.5rem;

    &:hover {
      background-color: #c8b68d;
    }
  }
}
</style>
