<template lang="pug">
.tipps-discount.bg-td-grey-light.position-relative(:class="{disabled}")
  button.apply-discount.position-absolute.w-100.h-100.no-style(@click="change" style="z-index:1;")
  .d-flex.align-items-center.justify-content-between
    .bg-ttipps-light
      slot(name="logo")
    .mx-2.w-100.d-flex.align-items-center
      .checker.bg-white.position-relative.mr-1.d-flex.align-items-center.justify-content-center.txt-tdays-light
        Transition(name="checkbox")
          font-awesome-icon(icon="check" v-if="isApplied")
      span.txt-td-grey-darker.small(:class="{'line-through':disabled}")
        span.extended-label(v-if="extendLabel") Gutschein einlösen und
        template(v-if="isPercentage") &nbsp;{{value | formatPercentage}}&nbsp;
        template(v-else) &nbsp;{{value | formatPrice}}&nbsp;
        | Sparen
</template>
<script>
export default {
  name: "TippsDiscount",
  emits: ["change"],
  props: {
    value: Number,
    isPercentage: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    extendLabel: {
      type: Boolean,
      default: true,
    },
    isApplied: Boolean,
  },
  methods: {
    change() {
      if (this.disabled) return;
      this.$emit("change", !this.isApplied);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.extended-label {
  @media (max-width: 340px) {
    display: none;
  }
}
.tipps-discount {
  user-select: none;
  transition: filter 0.4s;
  .apply-discount {
    left: 0;
    top: 0;
  }
  .checker {
    aspect-ratio: 1;
    width: 20px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  .tipps-logo {
    max-height: 20px;
    position: relative;
    top: -1px;
  }
  .txt {
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
  }
  &.disabled {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.checkbox-enter-active,
.checkbox-leave-active {
  transition: transform 0.2s, opacity 0.2s;
}
.checkbox-enter,
.checkbox-leave-to {
  opacity: 0;
  transform: scale(2);
}
.checkbox-leave,
.checkbox-enter-to {
  opacity: 1;
  transform: scale(1);
}
</style>
