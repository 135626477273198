<template lang="pug">
  .cmm.call-me-maybe.border-top.pt-3
    h2 Rückruf vereinbaren
    p Wünschen Sie einen Rückruf? Nutzen Sie unseren kostenlosen Rückruf-Service und wir rufen Sie zur gewünschten Zeit an.
      .container-fuid.pl-0.pr-0
        .row.no-gutters
          .col-sm-12.col-md-6.pr-1
            input.form-control.rounded-0.mb-2(type="text" v-model="lastName" id="LastName" name="LastName" placeholder="Name *")
          .col-sm-12.col-md-6.pl-1
            input.form-control.rounded-0.mb-2(type="text" v-model="firstName" id="FirstName" name="FirstName" placeholder="Vorname")
        .row.no-gutters
          .col-12
            input.form-control.rounded-0.mb-2(type="text" v-model="phone" id="Phone" name="Phone" placeholder="Telefonnummer *")
        .row.no-gutters
          .col-12
            input.form-control.rounded-0.mb-2(type="text" v-model="body" id="Body" name="Body" placeholder="Thema")
        .row.no-gutters
          .col-6.date
            .form-group
              DatePicker(prefix-class="td-datepicker" type="date" v-model="date" name="Date" :disabled-date="checkValidDate" value-type="format" format="DD.MM.YYYY" placeholder="Datum")
          .col-6.time
            .form-group
              .dropdown
                b-form-select.form-control(v-model="selectedTime" :options="times")
        .row.no-gutters
          .col-12
            p.small
              | Durch Klicken auf „absenden" bestätige ich, die&nbsp;
              a.txt-tdays-lighter(href="/ds") Datenschutzerklärung
              |  der touriDat GmbH & Co. KG gelesen zu haben und stimme dieser zu.
        .row.no-gutters
          .col-12
            button.button.button-primary.button-tdays.button-js.button-send-contact-form(@click="sendCallbackForm")
              | &nbsp;absenden
</template>

<script>
import Helper from "@/lib/helper";
import * as t from "@/store/main.json";
import DatePicker from "vue2-datepicker";
import EventBus from "@/event-bus";
import moment from "moment";
import { Mailer } from "@/graphql/Mailer.ts";
import config from "@/config/config";
import "vue2-datepicker/locale/de";

DatePicker.locale("de");

const midnight = new Date();
midnight.setHours(0, 0, 0, 0);

const SITE_KEY = config.external.google.recaptcha.siteKey;

export default {
  name: "call-me-back-form",
  components: { DatePicker },
  setup() {
    const recaptchaCallback = (cb) => {
      const grecaptcha = window["grecaptcha"];
      if (!grecaptcha) return;
      grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then(function(token) {
          if (!token) return;
          cb(token);
        });
    };

    return { recaptchaCallback };
  },
  data() {
    return {
      Helper,
      t,
      firstName: null,
      lastName: null,
      phone: null,
      body: null,
      date: null,
      today: new Date(),
      isPushing: false,
      errors: [],
      success: false,
      times: [
        "09-10 Uhr",
        "10-11 Uhr",
        "11-12 Uhr",
        "12-13 Uhr",
        "13-14 Uhr",
        "14-15 Uhr",
        "15-16 Uhr",
        "16-17 Uhr",
        "17-18 Uhr"
      ],
      selectedTime: "09-10 Uhr",
      fails: []
    };
  },
  props: {
    item: Object
  },
  computed: {
    dateValue() {
      if (!this.date) return;
      return moment(this.date, "DD.MM.YYYY").toDate();
    }
  },
  methods: {
    isValidDate(date) {
      return date instanceof Date;
    },
    /**
     * @param { Date } date
     * @param { Date[] } currentValue
     * @returns { boolean }
     */
    checkValidDate(date, currentValue) {
      console.log(currentValue);
      const isAfterToday = date < midnight;
      const isValidDay = [0, 6].includes(date.getDay());
      return isAfterToday || isValidDay;
    },
    async showCaptcha() {
      return new Promise((resolve) => {
        this.recaptchaCallback((token) => {
          //alert(token);
          resolve(token);
        });
      });
    },
    async sendCallbackForm(e) {
      e.preventDefault();
      const token = await this.showCaptcha();
      if (!token) return;
      if (!this.lastName?.trim()?.length) {
        return this.$alert("Bitte geben Sie einen Namen an.");
      }

      if (!this.phone?.trim()?.length) {
        return this.$alert("Bitte geben Sie eine gültige Telefonnummer für den Rückruf an.");
      }

      if (!this.isValidDate(this.dateValue)) {
        return this.$alert("Bitte geben Sie ein gültiges Datum an.");
      }

      if (this.date && this.selectedTime) {
        const rawDate = this.date.split(".");
        const date = new Date(rawDate[2], parseInt(rawDate[1]) - 1, rawDate[0]);
        const selectedStartTime = parseInt(this.selectedTime.split("-")[0]);
        // first check for saturday
        if (date.getDay() === 6) {
          if (selectedStartTime >= 12) {
            return this.$alert("Ein Rückruf ist nur zu den folgenden Geschäftszeiten möglich: Mo.-Fr.: 9-18 Uhr, Sa. 9-12 Uhr");
          }
        }

        if ((date.getMonth() === this.today.getMonth()) && (date.getDay() === this.today.getDay()) && (date.getFullYear() === this.today.getFullYear())) {
          if (this.today.getHours() > selectedStartTime) {
            return this.$alert("Bitte wählen Sie eine gültige Zeit für den Rückruf.");
          }
        }
      }

      let appendix;
      if (this.item?.SystemItemId) {
        appendix = `Artikelnummer: ${this.item.SystemItemId}`;
      }
      const bodyToUse = `${this.body}${appendix ? " // " + appendix : ""}`;

      EventBus.$emit("changeLoadingState", true);
      const mailData = {
        FirstName: this.firstName,
        LastName: this.lastName,
        Content: bodyToUse,
        Phone: this.phone,
        CallbackDate: this.date,
        CallbackTime: this.selectedTime,
        Created: moment()
      };

      this.$apollo.mutate({
        mutation: Mailer.Mutations.sendCallbackMail,
        variables: {
          mailData: mailData,
          token
        }
      }).then(({ data }) => {
        EventBus.$emit("changeLoadingState", false);
        if (data && data.sendCallbackMail) {
          this.$alert("Ihr Rückruf wurde übermittelt");
          this.clear();
        }
      });
    },
    clear() {
      this.firstName = "";
      this.lastName = "";
      this.body = "";
      this.phone = "";
      this.selectedTime = this.times[0];
      this.date = "";
    }
  },
  async mounted() {
    let recaptchaScript = document.querySelector("#recaptcha-script");
    if (recaptchaScript) recaptchaScript.parentNode.removeChild(recaptchaScript);
    recaptchaScript = document.createElement("script");
    recaptchaScript.id = "recaptcha-script";
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
    document.head.appendChild(recaptchaScript);
  }
};
</script>
