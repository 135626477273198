import { CategoriesEnum } from "@/utils/CategoriesEnum.ts";
import { ImageTypesEnum } from "@/utils/ImageTypesEnum.ts";
import { HostViewTemplatesEnum } from "@/utils/HostViewTemplatesEnum.ts";
import { HostViewComponents } from "@/utils/HostViewComponents";
import { asCloudFrontUrl } from "@/lib/helper/strings";
import DefaultHostView from "@/views/host/DefaultHostView.vue";

export const findTouriTippsItem = (host) => {
  // find the tipps item
  const item = host.Items.find((i) => i.IsTouriTippsItem);
  if (!item) return;
  return item.id;
};

export const findTouriTippsItemForCategory = (categoryId, host) => {
  if (parseInt(categoryId) !== CategoriesEnum.TouriTipps) return;
  return findTouriTippsItem(host);
};

export const getDisplayableHostImages = (host) => {
  if (!host?.Images) return [];
  const sortImages = (images) => {
    if (images.every((image) => !!image.Position)) return images;
    return images.sort((a, b) => {
      const posA = a.Position || 0;
      const posB = b.Position || 0;

      return posA > posB;
    });
  };

  let filteredImages = host.Images.filter((i) => {
    return i?.RemoteURL && !i.RemoteURL.includes(".jpg") && !i.Name?.toLowerCase()?.includes("logo") && !i.Name?.toLowerCase()?.includes("ebay");
  });

  // find the product image
  const productImage = filteredImages.find((image) => {
    return ("ImageTypeId" in image && image?.ImageTypeId === ImageTypesEnum.ProductImage) || image?.Channel?.toLowerCase()?.indexOf("artikelbild") !== -1;
  });
  // if there is none, return all valid images
  if (!productImage) return sortImages(filteredImages);

  // return a list with product image on top
  filteredImages = filteredImages.filter((image) => image !== productImage);
  return [productImage, ...sortImages(filteredImages)].map((image) => {
    image.RemoteURL = asCloudFrontUrl(image.RemoteURL);
    return image;
  });
};
/**
 * @typedef {
 *   {
 *    Channel:string
 *     RemoteURL:string
 *   }
 * } Image
 */
/**
 * @typedef { "small" | "medium" | "large" } ImageSize
 */
/**
 * Returns a list of each image's RemoteURL
 * @param { Array<Image> } images
 * @param { ImageSize } [sizes]
 * @returns { string[] }
 */

export const listImageUrls = (images, ...sizes) => {
  const urls = [];
  for (let i = 0; i < images.length; i++) {
    const size = sizes?.length ? (sizes[i] || [...sizes].pop()) || "small" : undefined;
    urls.push(getImageUrl(images[i], size));
  }
  return urls.map((url) => asCloudFrontUrl(url));
};

export const getImageUrl = (image, size) => {
  if (!image?.RemoteURL) return;
  return `${image.RemoteURL}${size ? `${size}.jpg` : ""}`;
};

export const getDisplayableHostImageUrls = (host, size = "medium") => {
  const images = getDisplayableHostImages(host);
  if (!images || images.length === 0) return [];
  return images.map((image) => `${image.RemoteURL}${size}.jpg`);
};

export const getHostRoute = (host, preselectedItemId) => {
  const params = { name: host.Url };
  if (preselectedItemId) params.itemId = preselectedItemId;
  return { name: "Host", params };
};

export const getSearchCriteriaIcon = (name) => {
  name = name.toLowerCase();
  const icons = {
    allgemein: "https://d1xkfcniu0czj4.cloudfront.net/Images/Icons/service.png",
    zimmerausstattung: "https://d1xkfcniu0czj4.cloudfront.net/Images/Icons/facility.png",
    "lage/entfernung/umgebung": "https://d1xkfcniu0czj4.cloudfront.net/Images/Icons/location.png"
  };
  return icons[name];
};

export const getHostSearchCriterias = (host) => {
  try {
    if (!host?.CustomAttributes?.SearchCriterias || host?.CustomAttributes?.SearchCriterias.length === 0) return [];
    const criterias = [];
    host.CustomAttributes.SearchCriterias.forEach((criteria) => {
      if (criteria.Version && criteria.Version == 2) {
        criterias.push({
          label: criteria.Name,
          position: criteria?.Position || 0,
          iconUrl: criteria.Image || getSearchCriteriaIcon(criteria.Name),
          values: criteria.SearchCriterias.map((searchCriteria) => {
            const mappedValue = {
              id: searchCriteria.Id,
              label: searchCriteria.Description,
              position: searchCriteria?.SortNumber || 0
            };
            if (!searchCriteria.HasInput) return mappedValue;
            if (searchCriteria.Value) {
              mappedValue.children = [
                {
                  label: searchCriteria.Value
                }
              ];
              return mappedValue;
            } else {
              mappedValue.children = [
                {
                  label: searchCriteria.SearchCriteriaValue.IntValue || searchCriteria.SearchCriteriaValue.StringValue
                }
              ];
            }
            return mappedValue;
          }).sort((a, b) => {
            return a.position - b.position;
          })
        });
      } else {
        if (!criteria || ["Angebote", "Kategorie"].includes(criteria.Name)) return;
        // skip if feature already exists
        if (criterias.find((c) => c.label === criteria.Name)) return;
        criterias.push({
          position: parseInt(criteria.Position),
          label: criteria.Name,
          values: criteria.SearchCriteria.map((value) => {
            return { label: value };
          }),
          iconUrl: criteria.PictureUrl
        });
      }
    });
    return criterias;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getHostItemSearchCriterias = (host) => {
  if (!host?.Items) return [];
  // since Custom_Product_SearchCriterias is a property of host.Item, gather up all features but without duplicates:
  const criterias = [];
  host.Items.flatMap((item) => {
    return item.CustomAttributes?.Custom_Product_SearchCriterias || [];
  }).forEach((itemCriteria) => {
    if (!itemCriteria || ["Angebote", "Kategorie"].includes(itemCriteria.Name)) return;
    // skip if feature already exists
    if (criterias.find((c) => c.label === itemCriteria.Name)) return;
    criterias.push({
      position: parseInt(itemCriteria.Position),
      label: itemCriteria.Name,
      values: itemCriteria.SearchCriteria.map((value) => {
        return { label: value };
      }),
      iconUrl: itemCriteria.PictureUrl
    });
  });
  // sort by position
  return criterias;
};

export const getHostViewComponentByTemplateId = (templateId) => {
  if (!templateId) return DefaultHostView;
  templateId = parseInt(templateId);
  if (!Object.values(HostViewTemplatesEnum).includes(templateId)) return;
  return HostViewComponents[templateId];
};

export const getNumberOfStars = (host) => {
  if (!(typeof host?.Stars === "string" || typeof host?.Stars === "number")) return 0;
  if (typeof host?.Stars === "string" && !host?.Stars?.length) return 0;
  const value = parseInt(host.Stars);

  return isNaN(value) ? 0 : value;
};

export const isHostSuperior = (host) => {
  if (typeof host?.Stars !== "string") return false;
  return host.Stars.toLowerCase().includes("s");
};

/**
 * Tries to find a fitting image url from the given images. if there is an image
 * present that matches ImageTypeId === ImageTypesEnum.ProductImage or Channel === "Artikelbild", this image will be used,
 * otherwise the first one in the list will be used.
 * returns undefined if there is no matching image
 * @param {
 *   Array<{
 *     ImageTypeId?:number;
 *     Channel?:string;
 *     RemoteURL:string;
 *   }>
 * } images
 * @param {("small" | "medium" | "large")} [size]
 * @returns string
 */
export const getProductImage = (images, size) => {
  if (!images?.length) return undefined;
  const articleImage = images.find((image) => {
    return (
      image?.ImageTypeId === ImageTypesEnum.ProductImage ||
      image?.Channel?.toLowerCase() === "artikelbild" ||
      image?.Name?.toLowerCase() === "artikelbild"
    );
  });
  if (articleImage) {
    return getImageUrl(articleImage, size);
  }
  return getImageUrl(images[0], size);
};
