import { ShoppingBasketPlugin } from "@moloss/shopping-basket";
import gql from "graphql-tag";
import { sendAddToCart, sendRemoveFromCart } from "@/lib/helper/gtm";
import { Hosts } from "@/graphql/Hosts.ts";
import { UserShoppingBasketItems } from "@/graphql/UserShoppingBasketItems.ts";
import { DiscountTypesEnum } from "@/utils/DiscountTypesEnum.ts";
import { useApolloClient } from "@/lib/apollo/client";
import { getProductImage } from "@/lib/helper/host";

export const pluginShoppingBasket = (Vue) => {
  const session = Vue.prototype?.$session;
  if (!session) {
    return Vue.use(ShoppingBasketPlugin);
  }

  const sessionKey = "shoppingBasket";

  Vue.use(ShoppingBasketPlugin, {
    combineItems: true,
    storage: {
      load: async () => {
        if (!session.exists()) {
          session.start();
        }
        const data = session.get(sessionKey) || {
          items: [],
          discounts: [],
          shipping: undefined
        };
        return data;
      },
      save: async (data) => {
        if (!session.exists()) {
          session.start();
        }
        session.set(sessionKey, data);
      },
      clear: async () => {
        if (!session.exists()) {
          session.start();
        }
        session.remove(sessionKey);
      }
    }
  });
};

export const setupShoppingBasket = async (shoppingBasket, apollo, session) => {
  await shoppingBasket.load();

  shoppingBasket.on("additem", async (itm) => {
    const itemId = itm.id;
    if (!itemId) return;
    sendAddToCart(itm);
    if (!apollo || !session || !session.has("token")) return;
    const item = shoppingBasket.uniqueItems.find(
      (i) => i.id === itemId && i.IsDiscounted === itm.IsDiscounted
    );
    if (!item) return;
    const userItem = await apollo
      .query({
        query: UserShoppingBasketItems.Queries.UserShoppingBasketItem,
        variables: {
          itemId,
          isDiscounted: item?.IsDiscounted
        }
      })
      .then((response) => response?.data?.userShoppingBasketItem);

    if (!userItem) {
      return await apollo.mutate({
        mutation: UserShoppingBasketItems.Mutations.AddItemToUserShoppingBasket,
        variables: {
          item: {
            ItemId: item.id,
            Quantity: item.Quantity,
            IsTippsItem:
              item.IsDiscounted &&
              item.DiscountType === DiscountTypesEnum.TippsDiscount
          }
        }
      });
    }

    return await apollo.mutate({
      mutation:
      UserShoppingBasketItems.Mutations
        .UpdateItemQuantityInUserShoppingBasket,
      variables: {
        id: userItem.id,
        quantity: item.Quantity
      }
    });
  });
  shoppingBasket.on("removeitem", async (item) => {
    const itemId = item.id;
    if (!itemId) return;
    sendRemoveFromCart({
      ...item,
      Discount: item.RegularPrice - item.CurrentPrice
    });
    if (!apollo || !session || !session.has("token")) return;

    const basketItem = shoppingBasket.uniqueItems.find(
      (i) => (i.id === itemId && i.IsDiscounted === item.IsDiscounted) || false
    );

    const userShoppingBasketItem = await apollo
      .query({
        query: UserShoppingBasketItems.Queries.UserShoppingBasketItem,
        variables: {
          itemId,
          isDiscounted: item.IsDiscounted
        }
      })
      .then((response) => response?.data?.userShoppingBasketItem);
    if (!userShoppingBasketItem) return;
    if (basketItem && basketItem.Quantity !== userShoppingBasketItem.Quantity) {
      return await apollo.mutate({
        mutation:
        UserShoppingBasketItems.Mutations
          .UpdateItemQuantityInUserShoppingBasket,
        variables: {
          id: userShoppingBasketItem.id,
          quantity: basketItem.Quantity
        }
      });
    }

    await apollo.mutate({
      mutation:
      UserShoppingBasketItems.Mutations.RemoveItemFromUserShoppingBasket,
      variables: {
        id: userShoppingBasketItem.id
      }
    });
  });

  shoppingBasket.on("clear", async () => {
    if (!session.has("token")) return;
    const apollo = useApolloClient({ session });
    await apollo.mutate({
      mutation: UserShoppingBasketItems.Mutations.EmptyShoppingBasket
    });
  });
};

export const toShoppingBasketItem = (itemData, isTippsItem = false) => {
  const IsDiscounted =
    itemData.IsInLiveShopping || (itemData.IsTouriTippsItem && isTippsItem);
  const ImageUrl = getProductImage(itemData?.Host?.Images);
  const item = {
    id: itemData.id,
    SystemItemId: itemData.SystemItemId,
    IsDiscounted,
    Name: itemData.Name,
    HostName: itemData.Host.Name,
    Catering: itemData.Catering,
    Nights: itemData.Nights,
    Persons: itemData.Persons,
    RegularPrice: itemData.RegularPrice,
    CurrentPrice: IsDiscounted
      ? itemData.LiveShoppingPrice
      : itemData.CurrentPrice,
    ImageUrl,
    IsInLiveShopping: itemData.IsInLiveShopping || false,
    IsTouriTippsItem: itemData.IsTouriTippsItem || false,
    WebTemplateId: itemData.WebTemplateId || 1,
    HostUrl: itemData?.Host?.Url
  };

  if (IsDiscounted) {
    const discountType = item.IsInLiveShopping
      ? DiscountTypesEnum.LiveShopping
      : itemData.IsTouriTippsItem && isTippsItem
        ? DiscountTypesEnum.TippsDiscount
        : undefined;
    item.DiscountType = discountType;
  }

  return item;
};
export const loadShoppingBasketItem = async (
  apollo,
  itemId,
  isTippsItem = false,
  lang = "de"
) => {
  const item = await loadItemToAdd(apollo, itemId, lang);
  return toShoppingBasketItem(item, isTippsItem);
};
export const loadItemToAdd = async (apollo, itemId, lang = "de") => {
  if (!apollo?.query) return;
  const item = await apollo
  .query({
    query: gql`
        #graphql
        query Item($itemId: ID) {
            item(id: $itemId) {
                id
                SystemItemId
                Name
                Catering
                Nights
                Persons
                CurrentPrice
                RegularPrice
                LiveShoppingPrice
                IsInLiveShopping
                IsTouriTippsItem
                Host {
                    id
                    Name
                    Images
                }
            }
        }
    `,
    variables: {
      itemId
    }
  })
  .then((response) => response?.data?.item);
  if (!item?.Host?.id) return item;
  const hostUrl = await apollo
    .query({
      query: Hosts.Queries.HostUrl,
      variables: {
        hostId: item.Host.id,
        lang
      }
    })
    .then((response) => response?.data?.hostUrl);
  if (hostUrl) item.Host.Url = hostUrl;
  return item;
};
