<template lang="pug">
  .quantity-input
    button.no-style(@click="raiseQuantity")
      font-awesome-icon(icon="chevron-up")
    input.no-style.no-spinner.p-0.py-1.m-0.text-center.w-100(type="number" :min="min" :max="max" @change="changeInputQuantity($event)" :value="value")
    button.no-style(@click="lowerQuantity")
      font-awesome-icon(icon="chevron-down")
</template>

<script>
import { clamp } from "@/lib/helper/math";

export default {
  name: "QuantityInput",
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: Number,
    value: Number,
  },
  methods: {
    changeInputQuantity(e) {
      this.changeQuantity(e.target.value);
      e.target.value = clamp(this.value, this.min, this.max);
    },
    changeQuantity(quantity) {
      if (this.max && quantity > this.max) this.$emit("exceed");
      this.$emit("change", clamp(quantity, this.min, this.max));
    },
    raiseQuantity() {
      const newQuantity = this.value + 1;
      if (this.max && newQuantity > this.max) {
        this.$emit("exceed");
        return;
      }
      this.changeQuantity(newQuantity);
    },
    lowerQuantity() {
      const newQuantity = this.value - 1;
      if (newQuantity < this.min) return;
      this.changeQuantity(newQuantity);
    },
  },
};
</script>

<style scoped lang="scss"></style>
