<template lang="pug">
  .modal-dialog
    .modal-content
      .modal-body(@click.self="close")
        .container
          .back-button.text-left.sticky-top.bg-white
            button.no-style.px-3.py-2.mt-2(@click="close")
              font-awesome-icon(icon="chevron-left")
          .gallery-content.pb-4
            template(v-for="(image,index) in images")
              .col-12.image-col(v-if="index === 0 || (index % 5) === 0")
                .gallery-item
                  img.img-fluid.w-100(:src="getImageUrl(image,'large')")
              .col-6.image-col(v-else)
                .gallery-item
                  img.img-fluid.w-100(:src="getImageUrl(image)")
</template>
<script>
export default {
  name: "FullHostGallery",
  props:{
    images:{
      type:Array,
      required:true
    }
  },
  methods:{
    close(){
      this.$modal.hide(this.$parent.name);
    },
    getImageUrl(image,size = 'medium'){
      const remoteUrl = image?.RemoteURL;
      if(!remoteUrl) return;
      return `${remoteUrl}${size}.jpg`;
    },
  },
  mounted(){
    document.body.classList.add('no-scroll');
  },
  unmounted(){
    document.body.classList.remove('no-scroll');
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/_colors.scss";

.full-host-gallery {
  width:100%!important;
  height:100%!important;
  left:0!important;
  top:0!important;

  .modal-dialog,.modal-content,.modal-body {
    max-width:none;
    margin:0;
    width:100%;
    height:100%;
  }

  .modal-body {
    pointer-events: all!important;
    overflow:hidden;
    overflow-y:auto;
    padding:0;

    & > .container {
      max-width:800px;

      .image-col {
        padding: 0;
        border: 10px solid #fff;
      }
    }
  }

  .back-button {
    font-size:26px;
    button {
      color:$td-grey-basic;
      &:hover {
        color:$td-grey-dark;
      }
    }
  }

  .gallery-content {
    display:flex;
    flex-wrap:wrap;
  }
}
</style>