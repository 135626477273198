<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
          d="M2 20.35V18.4q.725 0 1.238-.225t1.037-.488t1.163-.475T7 17q.95 0 1.563.213t1.137.474t1.05.488T12 18.4t1.25-.225t1.05-.488t1.15-.475T17 17q.95 0 1.575.213t1.15.474t1.05.488T22 18.4v1.95q-.95 0-1.588-.225t-1.162-.487t-1.025-.475T17 18.95q-.7 0-1.213.213t-1.025.475t-1.15.487T12 20.35t-1.613-.225t-1.15-.487t-1.012-.475T7 18.95q-.7 0-1.213.213t-1.037.475t-1.163.487T2 20.35m0-4.45v-1.95q.725 0 1.237-.225t1.038-.487t1.162-.475T7 12.55q.95 0 1.563.213t1.137.475t1.05.487t1.25.225t1.25-.225t1.05-.487t1.15-.475T17 12.55q.95 0 1.575.213t1.15.475t1.05.487T22 13.95v1.95q-.95 0-1.588-.225t-1.162-.488t-1.025-.474T17 14.5t-1.237.213t-1.026.475t-1.137.487t-1.6.225t-1.612-.225t-1.15-.488t-1.013-.474T7 14.5q-.7 0-1.212.213t-1.038.475t-1.162.487T2 15.9m0-4.45V9.5q.725 0 1.238-.225t1.037-.488t1.163-.475T7 8.1q.95 0 1.563.213t1.137.474t1.05.488T12 9.5t1.25-.225t1.05-.488t1.15-.475T17 8.1q.95 0 1.575.213t1.15.474t1.05.488T22 9.5v1.95q-.95 0-1.588-.225t-1.162-.487t-1.025-.475T17 10.05q-.7 0-1.213.213t-1.025.475t-1.15.487T12 11.45t-1.613-.225t-1.15-.487t-1.012-.475T7 10.05q-.7 0-1.213.213t-1.037.475t-1.163.487T2 11.45M2 7V5.05q.725 0 1.237-.225t1.038-.487t1.162-.475T7 3.65q.95 0 1.563.213t1.137.475t1.05.487T12 5.05t1.25-.225t1.05-.488t1.15-.474T17 3.65q.95 0 1.575.213t1.15.475t1.05.487T22 5.05V7q-.95 0-1.588-.225t-1.162-.487t-1.025-.475T17 5.6q-.7 0-1.213.213t-1.025.475t-1.15.487T12 7t-1.613-.225t-1.15-.488t-1.012-.474T7 5.6q-.7 0-1.213.213t-1.037.475t-1.163.487T2 7"></path>
  </svg>
</template>

<script>
export default {
  name: "WaveIcon"
};
</script>
