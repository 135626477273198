<template lang="pug">
.buyers-protection-box.position-relative.txt-themed-basic(@click="showModal")
    .d-flex.align-items-center
      .icon
        img.m-3(src="https://s3-cloud.td-cdn.de/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png" alt="inkl. 100% Käuferschutz" style="max-width:30px;")
      .text.w-100.ml-4
        .d-none.d-md-block.extended-text
          div Jedes Angebot bei touriDat mit einzigartigem Käuferschutz
          b.d-block Ihr Kauf ist zu 100% sicher
        .d-block.d-md-none touriDays mit 100% Käuferschutz
      button.button.button-secondary.button-themed-dark.mr-2 mehr erfahren
</template>

<script>
import BuyersProtectionModal from "@/views/components/modals/buyersProtection/BuyersProtectionModal";

export default {
  name: "BuyersProtectionBox",
  methods: {
    showModal() {
      try {
        this.$modal.show(
          BuyersProtectionModal,
          {},
          {
            width: "500px",
            height: "auto",
            classes: ["bp-modal", "modalMaxHeight", "rounded-0"],
          }
        );
      } catch (e) {
        console.log(e);
        //this.$alert(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries.scss";
.buyers-protection-box {
  user-select: none;
  cursor: pointer;
  .icon {
    position: relative;
    background-color: currentColor;
    &::after {
      content: "";
      background-color: inherit;
      position: absolute;
      right: 0;
      top: 50%;
      width: 15px;
      aspect-ratio: 1;
      transform: translate(50%, -50%) rotate(45deg);
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color-lighter, #488345);
    opacity: 0.3;
    z-index: -1;
  }

  .extended-text {
    font-size: 1rem;
    @include lg {
      font-size: 1.1rem;
    }
  }

  .text {
    font-size: 12px;
    @include sm {
      font-size: 14px;
    }
  }
  .button {
    font-size: 12px;
    padding: 10px 15px;
    @include sm {
      font-size: 16px;
      padding: 13px 30px;
    }
  }
}
</style>
