<template lang="pug">
  a.whatsapp.d-flex.align-items-center(:href="`https://wa.me/4915140703001/${urlText ? `?text=${urlText}` : ''}`" :target="target")
    font-awesome-icon(icon="fa-brands fa-whatsapp")
</template>
<script>
export default {
  name: "SocialLinkWhatsapp",
  props: {
    target: {
      type: String,
      default: "_blank"
    },
    text: String
  },
  computed: {
    urlText() {
      if (!this.text?.trim()?.length) return;
      return encodeURIComponent(this.text.trim());
    }
  }
};
</script>
<style scoped lang="scss"></style>
