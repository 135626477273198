<template lang="pug">
  .host-preview.position-relative(:title="name")
    router-link.link.d-block.text-decoration-none.position-absolute.w-100.h-100(v-if="url" :to="url" :target="linkToNewTab ? '_blank' : undefined")
    slot(name="gallery")
    slot(name="special")
    .text-box.position-relative
      HostPreviewDiscount.small.position-absolute.d-badge(v-if="showsDiscount && discountPercentage" :value="discountPercentage")
      .p-2
        .d-flex.align-items-center.name(v-if="name")
          HostPreviewStars(v-if="stars" :stars="stars" :is-superior="isSuperior")
          span.ellipse-text(:style="`max-width:${240 - ((stars || 0) * 12) - (isSuperior ? 12 : 0)}px;`") {{name}}
        .d-flex.align-items-center.city-region(v-if="cityRegion")
          font-awesome-icon.mr-1(icon="search-location")
          span {{cityRegion}}
        slot
      footer
        slot(name="footer")
</template>
<script>
import HostPreviewStars from "@/views/components/hostPreview/HostPreviewStars";
import HostPreviewDiscount from "@/views/components/hostPreview/partials/HostPreviewDiscount";

export default {
  name: "HostPreviewBox",
  components: { HostPreviewDiscount, HostPreviewStars },
  props: {
    stars: Number,
    isSuperior: Boolean,
    name: {
      type: String
    },
    cityRegion: {
      type: String
    },
    showsDiscount: {
      type: Boolean,
      default: true
    },
    discountPercentage: Number,
    price: Number,
    url: {
      type: String
    },
    linkToNewTab: Boolean
  },
  data() {
    return {
      hasLoadedGallery: false
    };
  }
};
</script>
<style lang="scss">
.host-preview {
  .text-box footer {
    pointer-events: all !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

.host-preview {
  footer {
    position: relative;
    z-index: 1;
  }

  &:hover,
  &.active {
    box-shadow: 0 0 10px 0 var(--theme-color-basic, $tdays-basic);
  }

  .text-box {
    color: $td-grey-dark;
    font-size: 14px;
    pointer-events: none;
  }

  .d-badge {
    top: 0;
    right: 0;
    transform: translate(-50%, 0%);
    animation: discountBadgeAnimation 0.4s normal forwards;

    @keyframes discountBadgeAnimation {
      0% {
        opacity: 0;
        transform: scale(0.9) translate(-50%, 0%);
      }
      100% {
        opacity: 1;
        transform: scale(1) translate(-50%, 0%);
      }
    }
  }
}
</style>
