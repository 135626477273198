<template lang="pug">
  .voucher-detail-card(v-if="title && value" :title="title")
    font-awesome-icon(:icon="icon")
    b.d-block.text-center {{value}}
</template>

<script>
export default {
  name: "VoucherDetailCard",
  props:{
    title:String,
    icon:String,
    value:[String,Number]
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.voucher-detail-card{
  background-color: $white;
  color:$td-grey-basic;
  padding:6px 10px;
  display:inline-block;
  border-radius:3px;
  text-align:center;
  margin-right:6px;
  cursor:default;
  min-width: 40px;
  * {
    pointer-events: none;
  }
}
</style>
