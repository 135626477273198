<template lang="pug">
.host-preview-footer
  .row
    .col-4.d-flex.align-items-center.justify-content-center(title="Personen")
      template(v-if="combinedPersons")
        font-awesome-icon.icon(icon="user-group")
        div {{combinedPersons}}
      template(v-else) &nbsp;
    .col-4.borders.d-flex.align-items-center.justify-content-center(title="Nächte")
      template(v-if="combinedNights")
        font-awesome-icon.icon(icon="moon")
        div {{combinedNights}}
      template(v-else) &nbsp;
    .col-4.d-flex.align-items-center.justify-content-center(:title="cateringTitle")
      template(v-if="combinedCatering")
        font-awesome-icon.icon(icon="utensils")
        div {{combinedCatering}}
      template(v-else) &nbsp;
</template>

<script>
import {availableCaterings} from "@/lib/helper/caterings";

const combineValue = (value) => {
  if(Array.isArray(value)){
    const min = value[0];
    const max = value[value.length-1];
    return `${min} bis ${max}`;
  }
  return value;
}

export default {
  name: "HostPreviewFooter",
  props:{
    persons:[Number,Array],
    nights:[Number,Array],
    catering:[String,Array]
  },
  computed:{
    combinedPersons(){
      return combineValue(this.persons);
    },
    combinedNights(){
      return combineValue(this.nights);
    },
    combinedCatering(){
      return combineValue(this.catering);
    },
    cateringTitle(){
      const caterings = Array.isArray(this.catering) ? this.catering : [this.catering];
      const matchingCaterings = availableCaterings.filter((catering) => caterings.includes(catering.value));
      if(matchingCaterings.length === 0) return 'Verpflegung';
      if(matchingCaterings.length === 1) return matchingCaterings[0].description;
      return `${matchingCaterings[0].description} bis ${matchingCaterings[1].description}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";
.host-preview-footer {
  cursor:default;
  font-size:13px;
  user-select: none;
  color:$td-grey-basic;
  .icon {
    font-size:14px;
    margin-right:0.25em;
  }
  .borders {
    border-left:1px solid rgba(0,0,0,0.15);
    border-right:1px solid rgba(0,0,0,0.15);
  }
}
</style>
