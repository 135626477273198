<template lang="pug">
  ModalComponent#tbook-info-modal(title="Anfragen & Buchen mit touriBook" @close="$emit('close')" theme="tbook")
    .bg-tbook-basic.txt-white
      .p-3
        .tbook-info-modal-badge jetzt neu
        img.lozad.img-fluid(alt='Anfragen & Buchen mit touriBook' src='https://s3-cloud.td-cdn.de/Images/makaira/articleview/article-tbook-box-image-compressed.png')
        .h3.text-center.mt-4 Anfragen & Buchen auch direkt über touriBook möglich
        .row.no-gutters
          .col-4.text-center
            font-awesome-icon.mr-1(icon="check")
            | einfache Buchung
          .col-4.text-center
            font-awesome-icon.mr-1(icon="check")
            | schnelle Abwicklung
          .col-4.text-center
            font-awesome-icon.mr-1(icon="check")
            | besserer Support
        hr
        ol.pl-3
          li Sobald Sie buchen möchten gehen Sie einfach auf book.touridat.com
          li Geben Sie Ihre Gutscheinnummer und Ihren Zugangscode ein
          li Senden Sie Ihre Anfrage komfortabel an den Gastgeber

</template>
<script>
import ModalComponent from "@/views/components/modals/ModalComponent";
export default {
  name: "TouriBookModal",
  components:{ModalComponent}
}
</script>
<style scoped lang="scss">

</style>