<template lang="pug">
  .modal-dialog.easter-signup-model.m-0.td-easter-alert-header(style="max-width:none!important;")
    .modal-content.p-4.bg-ttipps-lighter
      .modal-header.p-0
        .d-flex.align-items-center.justify-content-between.w-100
          .h2.w-100.d-block.m-0 Willkommen zur großen Ostereiersuchaktion!
          a.close.p-0.m-0.d-block(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body.p-0
        .text-center.pt-2
          .h2
          div
            | Verpassen Sie nicht unsere spannende Ostereiersuche
            br
            | auf der Website!
          .my-4
            .h3 Eddi Hase hat Ostereier mit tollen Überraschungen in unserem Shop versteckt.
            div
              | Finden Sie die Ostereier und sichern Sie sich einen attraktiven Rabatt* auf eine von Ihnen ausgewählte Reise in Höhe von
              br
              b 6%, 8% oder 10%!
          .py-4(style="pointer-events:none")
            EasterEgg.txt-tpics-basic(style="width:85px;")
            EasterEgg.txt-tdays-light(style="width:100px;")
            EasterEgg.txt-tgolf-dark(style="width:85px;")
          div
            | Sichern Sie sich jetzt Ihre Osterüberraschung indem Sie Ihre
            br
            | E-Mail Adresse eintragen:
          EasterSignupForm.my-2(@signedUp="onSignedUp")
</template>
<script>
import EasterSignupForm from "@/views/static/easter/partials/EasterSignupForm.vue";
import EasterEgg from "@/views/static/easter/partials/EasterEgg.vue";

export default {
  name: "EasterSignupModal",
  components: { EasterEgg, EasterSignupForm },
  methods: {
    onSignedUp() {
      this.$fire({
        html: "<br>Sie haben sich erfolgreich Angemeldet. Viel Spaß bei der Suche!",
        customClass: {
          header: "td-easter-alert-header",
          actions: "td-easter-alert-footer",
          confirmButton: "button button-primary button-tdays",
          container: "td-easter-alert"
        },
        buttonsStyling: false
      });
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss">
.modal-dialog.easter-signup-model.td-easter-alert-header {
  &:before {
    top: 31px;
    z-index: 1;
  }
}
</style>
