import { defineStore } from "pinia";
import { ref } from "vue";
export const useErrorStore = defineStore("error", () => {
  const error = ref(undefined);
  const setError = (err) => {
    error.value = err;
  };

  return { error, setError };
});
