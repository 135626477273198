<template lang="pug">
  .loading-spinner
    .bg-white.position-absolute.w-100.h-100.gradient
    LogoComponent.bg-white
</template>

<script>
import LogoComponent from "@/views/components/logo/LogoComponent";
export default {
  name: "LoadingSpinner",
  components: {LogoComponent}
}
</script>
<style scoped lang="scss">
.loading-spinner {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  overflow:hidden;
  animation:spin 2s ease infinite;
  .gradient{
    background-image: linear-gradient(269deg, #bb6a00, #7a1313, #1b3f68, #1a4e17);
    background-size: 800% 800%;
    animation: tD-loading-gradient 2s ease infinite
  }
  .logo{
    z-index:1;
  }
}

@keyframes tD-loading-gradient {
  0% {
    background-position: 0% 51%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 51%
  }
}

@keyframes spin {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
