import { defineStore } from "pinia";
import { ref } from "vue";
export const useUserStore = defineStore("user", () => {
  const user = ref(undefined);
  const changedBasketItems = ref([]);
  const setUser = (u) => {
    user.value = u;
  };

  const setChangedBasketItems = (items) => {
    changedBasketItems.value = items;
  };

  return { user, setUser, changedBasketItems, setChangedBasketItems };
});
