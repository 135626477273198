<template lang="pug">
button.add-to-shopping-basket(:title="title" @click="addToShoppingBasket")
  slot
</template>

<script>
import { loadShoppingBasketItem } from "@/lib/plugins/shoppingBasket";
import EventBus from "@/event-bus";

export default {
  name: "AddToShoppingBasketButton",
  props: {
    title: {
      type: String,
      default: "in den Warenkorb",
    },
    itemId: {
      type: String,
      required: true,
    },
    isTippsItem: Boolean,
  },
  methods: {
    async addToShoppingBasket() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const item = await loadShoppingBasketItem(
          this.$apollo,
          this.itemId,
          this.isTippsItem,
          this.$session.get("lang") || "de"
        );
        await this.$shoppingBasket.addItem({
          ...item,
          Discount: item.RegularPrice - item.CurrentPrice,
        });
        EventBus.$emit("showShoppingBasket", true);
      } catch (e) {
        this.$log.error(e);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
