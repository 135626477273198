<template lang="pug">
  .accordion-selector-option(:class="{selected}" @click="$emit('select',value)")
    label.w-100
      slot(name="label")
    .description-box.d-grid(v-if="$slots.default")
      .description
        slot(name="default")
</template>
<script>
export default {
  name: "AccordionSelectorOption",
  emits: ["select"],
  props: {
    value: [String, Number, Boolean],
    selected: Boolean
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';
@import '@/assets/styles/buttons.scss';

.accordion-selector-option {
  label {
    line-height: 50px;
    padding: 0;
    margin: 0;
    transition: all 0.4s;
    color: #fff;
    cursor: pointer;
    @include td-button-bg-effect($td-grey-light, $td-grey-light, $td-grey-basic);
  }


  .description-box {
    overflow: hidden;
    transition: grid-template-rows 0.4s;

    .description {
      min-height: 0;
    }
  }

  &:not(.selected) {
    .description-box {
      grid-template-rows: 0fr;
    }
  }

  &.selected {
    label {
      @include td-button-bg-effect($tdays-lighter, $tdays-lighter, $tdays-light);
    }

    .description-box {
      grid-template-rows: 1fr;
    }
  }
}
</style>
