<template lang="pug">
  .touri-book-info
    b
      img.icon.mr-1(src="https://s3-cloud.td-cdn.de/Images/Logos/touribook/branding/touribook-branding-50x50.png" style="max-width:30px;")
      span Termine / Reisezeitraum
    .mb-4 {{ bookability }}
    template(v-if="showsValidity && !blockedItemIds.includes(id)")
      b Gültigkeit
      p.user-select-none.mb-4
        template(v-if="validity")
          template(v-if="validity.ProductValidityTypeId == 1")
            | Der Gutschein ist ab Kaufdatum 3 Jahre gültig.
          template(v-else-if="validity.ProductValidityTypeId == 2")
            span Der Gutschein ist bis zum {{ moment().add(validity.ValidityMonths, "months").endOf("month").format("DD.MM.YYYY") }} gültig.
            span(v-if="!isHilton") &nbsp;Nach Fristablauf ist der gezahlte Kaufpreis bis zum Ablauf der dreijährigen Verjährungsfrist auf den Kaufpreis einer regulären Buchung anrechenbar. Eine Kaufpreiserstattung ist ausgeschlossen.
          template(v-else-if="validity.ProductValidityTypeId == 3")
            | Der Gutschein ist bis zum {{ moment(validity.ValidityDate).format("DD.MM.YYYY") }} gültig.
        template(v-else)
          | Der Gutschein ist ab Kaufdatum 3 Jahre gültig.
    template(v-if="showsCta")
      span Buchen Sie Ihren Urlaub nach Kauf direkt über&nbsp;
      a.txt-tbook-basic.text-underline(href="https://book.touridat.com/" target="_blank") touriBook
      | :
      .point.d-flex.align-items-center(v-for="point in ['einfache Buchung','schnelle Abwicklung','besserer Support']")
        font-awesome-icon.mr-1(icon="check")
        | {{point}}
      a.txt-tbook-basic.small.cursor-pointer(@click="showModal") mehr erfahren
</template>

<script>
import TouriBookModal from "@/views/components/modals/touriBook/TouriBookModal";
import moment from "moment";

export default {
  name: "TouriBookInfo",
  props: {
    id: {
      type: String,
      required: true
    },
    bookability: {
      type: String,
      required: true
    },
    validity: {
      type: Object,
      required: false
    },
    showsValidity: {
      type: Boolean,
      default: true
    },
    showsCta: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      blockedItemIds: ["4704", "4703", "4702", "6406", "6407", "6408", "4680", "4681", "4682", "3989", "3990", "3991", "3992", "5"],
      moment: moment
    };
  },
  methods: {
    showModal() {
      try {
        this.$modal.show(
          TouriBookModal, {},
          {
            width: "500px",
            height: "auto",
            classes: [
              "bp-modal",
              "modalMaxHeight",
              "rounded-0"
            ]
          });
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    isHilton() {
      try {
        if (!this.id) {
          return false;
        }
        if (this.id == 6997 || this.id == 6998) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  }
};
</script>

<style scoped>

</style>
