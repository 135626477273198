<template lang="pug">
header.app-header
  XmasHeader(v-if="xmasEventStarted && showsXmasHeader")
  ReisereporterHeader(v-if="showsReisereporterHeader")
  TopNavigation(v-if="showsTopNav" :template-type="templateType")
</template>

<script>
import AppTabbar from "@/views/components/app/appTabbar/AppTabbar";
import ReisereporterHeader from "@/views/components/reisereporter/ReisereporterHeader";
import XmasHeader from "@/views/static/adventCalendar/partials/XmasHeader";
import TopNavigation from "@/views/components/header/partials/TopNavigation";
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import config from "@/config/config";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import moment from "moment";

export default {
  name: "AppHeader",
  components: {
    TopNavigation,
    XmasHeader,
    ReisereporterHeader,
    AppTabbar,
  },
  props: {
    showsTopNav: {
      type: Boolean,
      default: true,
    },
    templateType: {
      type: Number,
      default: WebTemplatesEnum.Default,
    },
  },
  data() {
    return {
      xmasEventStarted: false,
    };
  },
  computed: {
    showsReisereporterHeader() {
      try {
        if (
          !this.$session ||
          !this.$session.exists() ||
          !this.$session.has("referrerId")
        )
          return false;
        return this.$session.get("referrerId") == config.reisereporterRefId;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    showsXmasHeader() {
      try {
        return (
          !["AdventCalendar", "AdventCalendarWinners"].includes(
            this.$route?.name
          ) &&
          moment(new Date()).isSameOrBefore(
            new Date(Date.parse("24 Dec 2023 23:59:00"))
          )
        );
      } catch (e) {
        this.$log.error(e);
        return true;
      }
    },
  },
  async mounted() {
    if (!this.showsXmasHeader) return;
    const marketingPromoContestId = 9;
    const xmasContest = await this.$apollo
      .query({
        query: MarketingPromoContest.Queries.MarketingPromoContest,
        variables: {
          marketingPromoContestId,
        },
      })
      .then((response) => {
        if (!response?.data?.marketingPromoContest)
          throw new Error(
            `failed to load marketing promo contest with id ${marketingPromoContestId}: response is empty`
          );
        return response?.data?.marketingPromoContest;
      });

    const now = new Date();
    this.xmasEventStarted =
      xmasContest?.RegistrationFrom &&
      xmasContest?.PrizeEnabledTill &&
      moment(now).isSameOrBefore(xmasContest.PrizeEnabledTill) &&
      moment(now).isSameOrAfter(new Date(Date.parse("17 Nov 2023 09:00:00")));
  },
};
</script>

<style scoped lang="scss">
.app-header {
  position: relative;
  z-index: 1;
}
</style>
