<template lang="pug">
.shopping-basket-selector.p-4
    .text-center.mb-4
      .h2 Warenkorb auswählen
      span Ihr aktueller Warenkorb stimmt nicht mit den Artikeln überein, die in Ihrem Account gespeichert sind.
    .row.text-left
      .col-12.col-md-6.py-2.py-md-0.items
        .text-center.font-weight-bold.txt-td-grey-basic Aktueller Warenkorb
        .item.d-flex.p-2(v-for="item in currentItems" :title="item.Name1 || item.Name")
            .col-10
                .name.ellipse-text.font-weight-bold {{item.Name1 || item.Name}}
            .col-2
                .amount x{{item.Quantity || 1}}
        .text-center.mt-4
          button.button.button-secondary.button-tdays(@click="select(currentItems)") beibehalten
      .col-12.col-md-6.py-2.py-md-0.items
          .text-center.font-weight-bold.txt-td-grey-basic Warenkorb aus Ihrem Account
          .item.d-flex.p-2(v-for="item in userItems" :title="item.Name1 || item.Name")
              .col-10
                  .name.ellipse-text.font-weight-bold {{item.Name1 || item.Name}}
              .col-2
                  .amount x{{item.Quantity || 1}}
          .text-center.mt-4
            button.button.button-secondary.button-tdays(@click="select(userItems)") übernehmen
    .text-center
        hr
    .text-center
        button.button.button-primary.button-tdays(@click="select(combinedItems)") Warenkörbe zusammenführen
</template>
<script>
import HostItemMenuItem from "@/views/components/host/layout/partials/HostItemMenuItem.vue";
import ContainerHeadline from "@/views/components/partials/ContainerHeadline.vue";

export default {
  name: "ShoppingBasketSelector",
  components: { ContainerHeadline, HostItemMenuItem },
  props: {
    currentItems: {
      type: Array,
      default: () => [],
    },
    userItems: {
      type: Array,
      default: () => [],
    },
    onSelect: {
      type: Function,
      required: true,
    },
  },
  computed: {
    combinedItems() {
      return [...new Set([...this.currentItems, ...this.userItems])];
    },
  },
  methods: {
    select(items) {
      this.onSelect(items);
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/colors";
.items {
  .item {
    box-shadow: 0 0 5px rgba($td-grey-darker, 0.15);
  }
}
</style>
