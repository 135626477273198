import gql from "graphql-tag";

export const Login = {
    Mutations: {
        Login: gql`
        mutation login($email: String, $password: String) {
            login(email: $email, password: $password) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
