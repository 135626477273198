<template lang="pug">
  #corona-modal.modal-dialog(role="document")
    .modal-content.rounded-0
      .modal-header
        #corona-title.modal-title Wichtiger Hinweis
        button.close(type="button" @click="$emit('close');")
          span(aria-hidden="true") &times;
      .modal-body
        p Bitte beachten Sie, dass es aufgrund der Corona-Bestimmungen in einzelnen Häusern und Regionen zu Einschränkungen sowohl vor Ort als auch in Bezug auf die Inklusivleistungen kommen kann. Informieren Sie sich gegebenenfalls vorab direkt bei unserem gastgebenden Partner oder bei der jeweiligen Tourist- oder Gästeinformation des Zielortes. Auch unser touriDat Service-Team steht Ihnen gerne für Rückfragen oder zur Unterstützung zur Verfügung. Die Reisegutscheine von touriDat sind darüber hinaus ohnehin 3 Jahre gültig und einlösbar und auch das Rückgaberecht sowie der Käuferschutz bleiben selbstverständlich bestehen.

      .modal-footer
        span.button.button-secondary.button-td-grey(@click="$emit('close');") schließen
</template>

<script>
export default {
  name: "corona-modal"
}
</script>

<style scoped>

</style>
