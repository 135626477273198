<template lang="pug">
.benefits
  .h2 Die touriDays-Leistungen im Überblick
  ul.pl-3
    li
      .headline Vermittlung von Top-Urlaubsangeboten
      .text Verantwortungsvoll und kompetent verhandelte Preise schaffen bestmögliche Konditionen für motivierte Gastgeber mit begeisterten Gästen
    li
      .headline Urlaubsgutschein mit touriDays-Zertifikat
      .text touriDat steht zu 100% hinter seinen gastgebenden Anbietern sowie den präsentierten Angeboten – und das bekommen Sie schriftlich
    li
      .headline Schutz vor finanziellen Verlusten
      .text Die touriDays-Garantie sichert Sie vor finanziellen Verlusten durch Insolvenz des Gastgebers, Betriebsschließung oder Inhaberwechsel
    li
      .headline 30 Tage Rückgaberecht
      .text Sie können Ihren gekauften Gutschein ohne Angabe von Gründen 30 Tage lang problemlos zurückgeben
    li
      .headline Kostenlose Urlaubsberatung
      .text Für Rückfragen oder bei Unklarheiten steht Ihnen die kostenlose Hotline werktags von 9-18 Uhr und samstags von 9-12 Uhr zur Verfügung
    li
      .headline Support Kaufabwicklung
      .text Als Hilfestellung beim Kauf oder in der Kaufabwicklung steht Ihnen das Service-Team per Telefon, Chat oder Mail kostenlos zur Verfügung
</template>

<script>
export default {
  name: "BuyersProtectionBenefits"
}
</script>