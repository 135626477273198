import gql from "graphql-tag";

export const DeadEndUrls = {
  Queries: {
    DeadEndUrls: gql`
        query deadEndUrls {
            deadEndUrls {
                RelativeDeadEndUrl
                RelativeSourceUrl
            }
        }`,
    DeadEndUrl: gql`
        #graphql
        query DeadEndUrl($sourceUrl: String!) {
            deadEndUrl(sourceUrl: $sourceUrl) {
                RelativeDeadEndUrl
            }
        }
    `
  }
};
