<template lang="pug">
  section#host-description(:class="{extended:isExtended}")
    .position-relative.host-description-header
      HostSectionHeader.item-h2.mb-0 {{title}}
    .container.position-relative.py-4
      .pb-5.pb-lg-0.mb-5.mb-lg-0
        h3.hotel-content-headline.d-block(v-if="host.CustomAttributes.Title") {{host.CustomAttributes.Title}}
        .d-block.small.font-weight-bold(v-if="host.CustomAttributes.Subtitle") {{host.CustomAttributes.Subtitle}}
        .item-hotel-description
          // .host-video.mb-3(v-if="video")
            .responsive-video
              VimeoPlayer(:player-width="1600" :player-height="900" :videoUrl="`${video}?dnt=1`")
          span(style="white-space: pre-wrap;" v-if="host.CustomAttributes.SeoDescription" v-html="host.CustomAttributes.SeoDescription")
          template(v-if="host.CustomAttributes.SeoGeneralDescription")
            h3.hotel-content-headline Allgemein
            span(style="white-space: pre-wrap;") {{host.CustomAttributes.SeoGeneralDescription}}
          template(v-if="host.CustomAttributes.SeoEquipmentDescription")
            h3.hotel-content-headline Ausstattung
            span(style="white-space: pre-wrap;") {{host.CustomAttributes.SeoEquipmentDescription}}
          template(v-if="host.CustomAttributes.SeoEnvironmentDescription")
            h3.hotel-content-headline Umgebung
            span(style="white-space: pre-wrap;") {{host.CustomAttributes.SeoEnvironmentDescription}}
        .fader
          .text-center.position-absolute.w-100.read-more-button(style="bottom:0")
            button.button.button-primary.button-tdays(@click="toggle")
              template(v-if="isExtended") weniger
              template(v-else) mehr
              | &nbsp;anzeigen
</template>

<script>
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader";
/*import { vueVimeoPlayer } from "vue-vimeo-player";*/

export default {
  name: "HostDescriptionSection",
  components: {
    HostSectionHeader,
    /*VimeoPlayer: vueVimeoPlayer*/
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "Betrieb & Region"
    },
    video: {
      type: String
    }
  },
  data() {
    return {
      isExtended: false
    };
  },
  methods: {
    toggle() {
      this.isExtended = !this.isExtended;
      this.$scrollTo("#host-description");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries.scss";

#host-description {
  & > .container {
    overflow-x: hidden;
  }

  &:not(.extended) > .container {
    height: 400px;
    overflow-y: hidden;
  }

  .fader {
    width: calc(100% - 30px);
    height: 100px;
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
        0deg,
        rgba(240, 240, 240, 1) 0%,
        rgba(240, 240, 240, 1) 50%,
        rgba(240, 240, 240, 0) 100%
    );
  }

  @include lg {
    & > .container {
      height: initial !important;

      .fader {
        display: none;
      }
    }
  }

  .hotel-content-headline {
    font-size: 1.4rem;
  }

  .host-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    @include lg {
      float: right;
      max-width: 640px;
      margin-left: 1rem;
    }

    .responsive-video {
      position: relative;
      padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
      padding-top: 0px;
      margin-bottom: 15px;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
