<template lang="pug">
  .checkout-overview
    .bg-white
      .numbers.p-3
        .row.my-2
          .col-6.text-left.name Warenkorb
          .col-6.text-right.value {{totalItemValue | formatPrice}}
        .row.my-2(v-if="totalItemReduction")
          .col-6.text-left.name Ersparnis
          .col-6.text-right
            .d-inline-flex.align-items-center
              .small.mr-1.txt-td-grey-basic ({{totalItemReductionPercentage | formatPercentage}})
              .value.negative {{totalItemReduction | formatPrice}}
        template(v-if="totalItemPrice !== totalItemValue")
          hr.m-0
          .row.my-2
            .col-6.text-left.name.font-weight-bold Zwischensumme
            .col-6.text-right.value {{totalItemPrice | formatPrice}}
        hr.m-0
        .row.my-2.discount(v-if="discountValue > 0")
          .col-6.text-left.name.font-weight-bold Rabatt-/Wertgutschein
          .col-6.d-flex.align-items-center.justify-content-end
            template(v-if="remainingDiscountValue !== discountValue")
              span.small.mr-1.txt-td-grey-basic(v-if="discountPercentage") ({{discountPercentage | formatPercentage}})
              .value(:class="{negative:remainingDiscountValue !== discountValue}") {{discountValue | formatPrice}}
            template(v-else) 0 €
          .col-12.text-right.small(v-if="remainingDiscountValue")
            | ({{remainingDiscountValue | formatPrice}}
            template(v-if="remainingDiscountValue !== discountValue") &nbsp;von {{totalDiscountValue | formatPrice}}&nbsp;
            | verbleibend)
        .row.my-2
          .col-6.text-left.name.font-weight-bold Versand
          .col-6.text-right.value.positive {{shippingCosts | formatPrice}}
        .row.mt-3.mb-2.warranty
          .col-8.text-left.name.font-weight-bold
            img.d-inline-block.tdays-warranty(src="https://s3-cloud.td-cdn.de/Images/Logos/touridays/branding/touridays-branding-50x50.png")
            span.ml-1 touriDays Garantie
          .col-4.text-right Inklusive
        .row
          .px-3.w-100.my-3
            slot(name="voucherInput")
        .row.my-4
          .col-12
            .seperator
        .row.align-items-center
          .col-5.col-xl-6.text-left.name.font-weight-bold Gesamtsumme
          .col-7.col-xl-6.text-right
            .calculated-price.price-overall {{totalPrice | formatPrice}}
</template>

<script>
import { clamp } from "@/lib/helper/math";

export default {
  name: "CheckoutOverview",
  props: {
    totalItemValue: Number,
    totalItemPrice: Number,
    shippingCosts: Number,
    discountValue: Number,
    discountPercentage: Number,
    remainingDiscountValue: Number,
    totalPrice: Number
  },
  computed: {
    totalDiscountValue() {
      return (this.discountValue || 0) + (this.remainingDiscountValue || 0);
    },
    /** Difference between Current Price and Regular Price **/
    totalItemReduction() {
      const totalItemValue = this.totalItemValue || 0;
      return clamp(totalItemValue - this.totalItemPrice, 0);
    },
    /** Percentage difference between Current Price and Regular Price **/
    totalItemReductionPercentage() {
      if (!this.totalItemValue) return 0;
      return (this.totalItemReduction / this.totalItemValue) * 100;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.checkout-overview {
  .seperator {
    position: absolute;
    width: 75%;
    right: 15px;
    top: 0;
    border-top: 2px dashed $td-grey-lighter;
    height: 2px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: -2px;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .input-group-append .button {
    cursor: pointer;
  }

  .value {
    &.positive:before,
    &.negative:before {
      margin-right: 5px;
      opacity: 0.5;
    }

    &.positive:before {
      content: "+";
    }

    &.negative:before {
      content: "-";
    }

    .percentage {
      margin-right: -3px;
    }
  }

  .tdays-warranty {
    width: 20px;
    height: 20px;
  }

  .calculated-price {
    font-size: 1.3em;
    font-weight: bold;
  }

  .price-overall {
    font-size: 1.6em;
    font-weight: bold;
  }
}
</style>
