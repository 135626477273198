<template lang="pug">
  header.xmas-header
    .row.header-row
      .text-center.text-md-left.col-12.col-md-6
        .pl-md-4.pt-4
          slot
      .col-12.col-md-6.d-none.d-md-block.text-right.user-select-none.relative.ball-column
        img.img-fluid.w-100(src="@/assets/images/kugeln.png" style="max-width:434px;")
        .position-absolute.link-1.cursor-pointer(@click="handleLink1")
        .position-absolute.link-2.cursor-pointer(@click="showInfoModal")
    .tree.text-center.user-select-none
      img.img-fluid.w-100(src="@/assets/images/tree.png" style="max-width:331px;")
</template>

<script>
export default {
  name: "XmasHeader",
  inject: ["showInfoModal"],
  methods: {
    async handleLink1() {
      if (this.$route.name === "AdventCalendar") {
        this.$scrollTo("#signup");
        return;
      }
      this.$router.push({ name: "AdventCalendar" });
      setTimeout(() => {
        this.$scrollTo("#signup");
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mediaqueries";

.xmas-header {
  overflow-x: hidden;
}

.header-row {
  margin-bottom: 0;
  @include md {
    margin-bottom: -13rem;
  }
}

.tree {
  img {
    margin-left: 0;
    @include md {
      margin-left: 60px;
    }
  }
}

.ball-column {
  .link-1 {
    width: 28.57143%;
    height: 5.28169%;
    right: 48.16327%;
    top: 240px;
    @include lg {
      width: 130px;
      height: 38px;
      right: 225px;
      top: 306px;
    }
  }

  .link-2 {
    // 375 x 618,767
    width: 14.66667%;
    height: 15px;
    right: 73px;
    top: 278px;
    @include lg {
      width: 72px;
      height: 15px;
      right: 87px;
      top: 354px;
    }
  }
}
</style>
