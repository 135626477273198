<template lang="pug">
  .easter-test.container
    EasterSignupForm(v-if="!participantId")
    template(v-else)
      EasterEgg
</template>
<script>
import EasterSignupForm from "@/views/static/easter/partials/EasterSignupForm.vue";
import EasterEgg from "@/views/static/easter/partials/EasterEgg.vue";

export default {
  name: "TestView",
  components: { EasterEgg, EasterSignupForm },
  computed: {
    participantId() {
      return this.$session.get("easterParticipantId");
    }
  }
};
</script>
<style scoped lang="scss">

</style>
