<template lang="pug">
  .error-message
    .row.pt-lg-5
      .col-12.text-center
        .td-gradient-circle-container.d-block.w-100
          .td-gradient-circle-inner.mx-auto
            .td-gradient-circle-img-position
              img.td-gradient-circle-img.img-fluid(src="https://s3-cloud.td-cdn.de/Images/makaira/td-loading.png" alt="tD")
        .h1.mt-3 Achtung, Fehler!
        p {{message}}
      .col-12.col-lg-8.offset-lg-2.mt-3.border-top.pt-4
        router-link.button.button-secondary.button-tdays.d-table.mx-auto(:to="{ name: 'Home' }") zurück zur Startseite
</template>

<script>
import httpErrorMessages from "@/lib/httpErrorMessages";

export default {
  name: "ErrorMessage",
  props:{
    message:{
      type:String,
      default(){
        return httpErrorMessages.default
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>