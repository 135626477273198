import gql from "graphql-tag";

export const Users = {
  Queries: {
    User: gql`
      query user {
        user {
          id
          FirstName
          LastName
          LoginEmail
        }
      }
    `,
  },
  Mutations: {
    CreateUser: gql`
      mutation createUser($user: UserInput) {
        createUser(user: $user) {
          IsSuccess
          Message
        }
      }
    `,
    UpdateUserPassword: gql`
      mutation updateUserPassword($password: String!, $guid: String!) {
        updateUserPassword(password: $password, guid: $guid) {
          IsSuccess
          Message
        }
      }
    `,
    ResetUserPassword: gql`
      mutation resetUserPassword($email: String) {
        resetUserPassword(email: $email) {
          IsSuccess
          Message
        }
      }
    `,
  },
};
