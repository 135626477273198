<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
          d="M11 13h2v3h3v-5h2l-6-5l-6 5h2v5h3zm1-12l9 4v6c0 5.55-3.84 10.74-9 12c-5.16-1.26-9-6.45-9-12V5z"></path>
  </svg>
</template>

<script>
export default {
  name: "HomeShieldIcon"
};
</script>
