import cfg from "@/config/config";

export default {
    isEnabled: true,
    logLevel: cfg.runMode === "production" ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}