<template lang="pug">
  .hotel-description(:class="{'has-tabs':hasTabs}")
    template(v-if="contextHost")
      .h2.hotel-content-headline.title.mb-3(v-if="contextHost.CustomAttributes.Title") {{contextHost.CustomAttributes.Title}}
      .h3.hotel-content-headline.subtitle(v-if="contextHost.CustomAttributes.Subtitle") {{contextHost.CustomAttributes.Subtitle}}
      div(v-if="contextHost.CustomAttributes.SeoDescription" v-html="contextHost.CustomAttributes.SeoDescription")
      nav.tab-nav(v-if="hasTabs")
        a.tab-link(@click="activeIndex = 0" :class="{active:activeIndex === 0}" v-if="contextHost.CustomAttributes.SeoGeneralDescription") Allgemein
        a.tab-link(@click="activeIndex = 1" :class="{active:activeIndex === 1}" v-if="contextHost.CustomAttributes.SeoEquipmentDescription") Ausstattung
        a.tab-link(@click="activeIndex = 2" :class="{active:activeIndex === 2}" v-if="contextHost.CustomAttributes.SeoEnvironmentDescription") Umgebung
      .tab(v-if="contextHost.CustomAttributes.SeoGeneralDescription" v-show="hasTabs ? activeIndex === 0 : true")
        .h3.hotel-content-headline Allgemein
        | {{contextHost.CustomAttributes.SeoGeneralDescription}}
      .tab(v-if="contextHost.CustomAttributes.SeoEquipmentDescription" v-show="hasTabs ? activeIndex === 1 : true")
        .h3.hotel-content-headline Ausstattung
        | {{contextHost.CustomAttributes.SeoEquipmentDescription}}
      .tab(v-if="contextHost.CustomAttributes.SeoEnvironmentDescription" v-show="hasTabs ? activeIndex === 2 : true")
        .h3.hotel-content-headline Umgebung
        | {{contextHost.CustomAttributes.SeoEnvironmentDescription}}
    .py-3(v-else)
      TextContentLoader(:lines="12")
</template>
<script>
import { Hosts } from "@/graphql/Hosts.ts";
import TextContentLoader from "@/views/components/partials/contentLoader/TextContentLoader";

export default {
  name: "HotelDescription",
  components: { TextContentLoader },
  props: {
    host: Object,
    hostId: String,
    hasTabs: Boolean
  },
  watch: {
    async hostId() {
      this.contextHost = await this.loadHostById((this.hostId));
    }
  },
  data() {
    return {
      contextHost: undefined,
      activeIndex: undefined
    };
  },
  methods: {
    async loadHostById(id) {
      try {
        const response = await this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: {
            id
          }
        });

        return response?.data?.host;
      } catch (e) {
        this.$log.error(e);
      }
    }
  },
  async created() {
    if (this.hasTabs) this.activeIndex = 0;
    if (this.host) {
      this.contextHost = this.host;
      return;
    }
    this.contextHost = await this.loadHostById((this.hostId));
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.hotel-description {
  &.has-tabs {
    --tab-bg: #{$white};

    .tab-nav {
      margin-top: 1em;

      .tab-link {
        font-weight: bold;
        padding: 0.5em;
        cursor: pointer;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &.active {
          background-color: var(--tab-bg);
        }
      }
    }

    .tab {
      background-color: var(--tab-bg);
      padding: 1em;

      .hotel-content-headline {
        display: none !important;
      }
    }
  }
}
</style>
