<template lang="pug">
svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.63 53.26" :style="`fill:${color};`")
  path(d="M42,0H30.25A30,30,0,0,1,18.11,24.23l-4.65,3.48,18,24.57H46.29L29.71,29.67A41.56,41.56,0,0,0,42,0Z")
  polygon(points="0 52.28 12.03 52.28 12.03 0 0 0 0 52.28 0 52.28")
  polygon(points="49.79 52.26 61.12 52.26 61.12 0.01 49.79 0.01 49.79 52.26 49.79 52.26")
  path(d="M160.49,15.15c-4.32,0-8.41,1.34-11.14,5V16.12H138.57V52.26h10.91v-19c0-5.5,3.68-8.19,8.12-8.19,4.76,0,7.49,2.84,7.49,8.11V52.26H175.9v-23c0-8.41-6.69-14.13-15.41-14.13Z")
  path(d="M85.51,43.49a9.56,9.56,0,0,1-9.8-9.3,9.82,9.82,0,0,1,19.61,0,9.56,9.56,0,0,1-9.81,9.3Zm9.84-27.37v2.31A19.07,19.07,0,1,0,84.63,53.26,18.89,18.89,0,0,0,95.35,50v2.31h10.83V16.12Z")
  path(d="M122.92,20.83V16.12H111.84V52.26h11.1V35.39c0-5.7,6.17-8.76,10.46-8.76h.12V16.12c-4.39,0-8.43,1.88-10.6,4.71Z")
  path(d="M199.68,43.49a9.56,9.56,0,0,1-9.8-9.3,9.82,9.82,0,0,1,19.61,0,9.56,9.56,0,0,1-9.81,9.3Zm9.85-27.37v2.31a19.07,19.07,0,1,0,0,31.52v2.31h10.82V16.12Z")
  path(d="M231.84,39.44a6.8,6.8,0,1,0,6.79,6.8,6.79,6.79,0,0,0-6.79-6.8Z")
</template>

<script>
export default {
  name: "KlarnaIcon",
  props:{
    color:{
      type:String,
      default:'#fff'
    }
  }
}
</script>

<style scoped>

</style>