import {WebTemplatesEnum} from "@/utils/WebTemplatesEnum.ts";

export const brands = [
    {name:'touriDays',route:{ name:'Home' },templateId:WebTemplatesEnum.Default,imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/touridays/white-plain/centered-horizontal-baseline/touridays-white-plain-centered-hotizontal-baseline-250x71.png',description:'Urlaubstage mit<br>100% Käuferschutz'},
    {name:'touriGolf',route:{ path:'/golf' },templateId:WebTemplatesEnum.Golf,imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/tourigolf/white-plain/centered-horizontal-baseline/tourigolf-white-plain-centered-hotizontal-baseline-250x71.png',description:'Golferlebnisse der<br>Extraklasse'},
    {name:'touriTipps',route:{ path:'/tipps' },templateId:WebTemplatesEnum.Tipps,imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/touritipps/white-plain/centered-horizontal-baseline/touritipps-white-plain-centered-hotizontal-baseline-250x71.png',description:'Hotelempfehlungen<br>des Monats'},
    {name:'touriDeluxe',route:{ path:'/deluxe' },templateId:WebTemplatesEnum.Deluxe,imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/tourideluxe/white-plain/centered-horizontal-baseline/tourideluxe-white-plain-centered-hotizontal-baseline-250x71.png',description:'Luxus-Offerten<br>ab 4*S'},
    {name:'touriMaps',route:{ path:'/karte' },templateId:WebTemplatesEnum.Map,imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/tourimaps/white-plain/centered-horizontal-baseline/tourimaps-white-plain-centered-hotizontal-baseline-250x71.png',description:'Kartensuche'},
    {name:'touriBook',link:'https://book.touridat.com/',imageUrl:'https://s3-cloud.td-cdn.de/Images/Logos/touribook/white-plain/centered-horizontal-baseline/touribook-white-plain-centered-hotizontal-baseline-250x71.png',description:'Anfragen & Buchen<br>über touriBook'}
]

export const getTopBrands = () => {
    const selection = ['touriDays','touriGolf','touriBook','touriTipps']
    return brands.filter(brand => selection.indexOf(brand.name) !== -1)
}
