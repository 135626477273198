import { render, staticRenderFns } from "./ReisereporterHeader.vue?vue&type=template&id=2c9425ab&scoped=true&lang=pug"
import script from "./ReisereporterHeader.vue?vue&type=script&lang=js"
export * from "./ReisereporterHeader.vue?vue&type=script&lang=js"
import style0 from "./ReisereporterHeader.vue?vue&type=style&index=0&id=2c9425ab&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9425ab",
  null
  
)

export default component.exports