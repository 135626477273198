<template lang="pug">
.mobile-frame.mobile-frame-header.d-flex.align-items-center
  button.back.no-style.d-flex.align-items-center(:disabled="!hasAnyHistory" @click="navigateBack")
    font-awesome-icon.mr-1.small(icon="chevron-left")
    | zurück
  router-link.branding(:to="{name:'Home'}")
    img.img-fluid.w-100(src="https://s3-cloud.td-cdn.de/Images/Logos/touridat/branding/touridat-branding-50x50.png" alt="touriDat")
</template>
<script>
export default {
  name: "MobileFrameHeader",
  computed:{
    hasAnyHistory(){
      return window.history.length !== 0
    }
  },
  methods:{
    navigateBack(){
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">

.mobile-frame-header {
  top:0;
  left:0;
  .branding {
    aspect-ratio: 1;
    width:30px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
}
</style>