<template lang="pug">
  #hiw-tdays-modal.modal-dialog
    .modal-content
      .modal-header
        h3.modal-title So funktioniert's
        .float-right
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body
        .hiw-tdays-seperate-headline Einfach. Ein Paar. Schöne Tage.
        img.img-fluid.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/hiw-tdays/chart-1-compressed.jpg")
        .hiw-tdays-seperate-txt Helge und Klara möchten sich einfach ein paar schöne Tage machen. Sie wissen aber noch nicht, wohin sie fahren möchten. Klara würde gerne nach Berlin. Helge ist aber noch nicht zu 100% überzeugt.
        .hiw-tdays-seperate-headline touriDays
        img.img-fluid.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/hiw-tdays/chart-2-compressed.jpg")
        .hiw-tdays-seperate-txt Auf touriDat.com finden Helge und Klara ein tolles touriDays-Reisepaket nach Berlin. Dieses Angebot beinhaltet viele Leistungen für einen unschlagbaren Preis. Hinzu kommt, dass die Reise zu 100% von touriDat abgesichert wird.
        .hiw-tdays-seperate-headline Reise kaufen
        img.img-fluid.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/hiw-tdays/chart-3-compressed.jpg")
        .hiw-tdays-seperate-txt Nach dem Kaufabschluss erhalten Helge und Klara ihre touriDays nach Berlin - wie gewünscht kostenlos per E-Mail. Mit der Gutscheinnummer können sie nun ihren Kurzurlaub - innerhalb der nächsten 3 Jahre - ganz einfach und flexibel buchen.
        .hiw-tdays-seperate-headline Wunschtermin buchen
        img.img-fluid.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/hiw-tdays/chart-4-compressed.jpg")
        .hiw-tdays-seperate-txt Helge und Klara entscheiden sich für die Sommerzeit und buchen ihren Kurzurlaub unter Angabe der Gutscheinnummer ganz bequem mit dem Telefon.
          br
          br
          | touriDat wünscht Helge und Klara einfach ein paar schöne Tage.
      .modal-footer

</template>

<script>
export default {
  name: "ProcedureInfoModal"
}
</script>

<style scoped>

</style>
