<template lang="pug">
  .login-form
    .form-group
        input.form-control.custom-control(v-model="email" type="email" placeholder="E-Mail Adresse" ref="emailInput" required)
    .form-group
        input.form-control.custom-control(v-model="password" placeholder="Passwort" type="password" v-on:keyup.enter="login" required)
    .form-group.text-right
        router-link.small.text-underline.txt-td-grey-basic(:to="{ name: 'ResetUserPassword' }" target="_blank") Passwort vergessen?
    slot(name="submitButton" :submit="login")
</template>
<script>
import EventBus from "@/event-bus";
import { Login } from "@/graphql/Login.ts";
import { sendLogin } from "@/lib/helper/gtm";
import { authUser } from "@/lib/helper/user";

export default {
  name: "LoginForm",
  emits: ["loggedIn"],
  props: {
    redirect: {
      type: [Object, Boolean],
      default() {
        return { name: "Account" };
      },
    },
    updateShoppingBasket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        if (!this.$refs?.emailInput?.checkValidity())
          throw new Error(`Bitte geben Sie eine gültige E-Mail Adresse an.`);
        if (!this.password?.length)
          throw new Error(`Es wurde kein Passwort angegeben.`);
        EventBus.$emit("changeLoadingState", true);
        const token = await this.$apollo
          .mutate({
            mutation: Login.Mutations.Login,
            variables: {
              email: this.email,
              password: this.password,
            },
          })
          .then(({ data }) => {
            this.$emit("loggedIn", { email: this.email });
            if (
              !data.login ||
              !data.login?.IsSuccess ||
              !data.login?.StringData?.length
            )
              throw new Error(
                data.login?.Message || `E-Mail oder Passwort ist nicht korrekt.`
              );
            return data.login.StringData;
          });
        this.$session.set("token", token);
        this.$session.remove("hideChangedItemsMessage");
        sendLogin();
        await authUser(true, this.$apollo, this.$shoppingBasket, this.$modal);
        if (this.redirect) {
          this.$router.push(this.redirect);
        } else {
          this.$emit("loggedIn", { email: this.email });
        }
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
  },
  mounted() {
    EventBus.$on("submitLoginForm", this.login);
  },
  unmounted() {
    EventBus.$off("submitLoginForm", this.login);
  },
  destroyed() {
    EventBus.$off("submitLoginForm", this.login);
  },
};
</script>
<style scoped lang="scss"></style>
