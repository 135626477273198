<template lang="pug">
.host-gallery.small-gallery.w-100.loader
  template(v-if="images")
    img.img-fluid(:src="imageUrls[0]" v-if="imageUrls.length === 1")
    b-carousel(:interval="0" ref="imageCarousel" controls v-else)
      .carousel-inner
        b-carousel-slide(v-for="(image,index) of imageUrls" :img-src="image" :class="{'active':index === 0}")
          span
</template>
<script>
export default {
  name: "SmallHostGallery",
  props: {
    images: {
      type: Array,
      validator(images) {
        return !images || images.every((image) => typeof image === "string");
      },
    },
    size: {
      type: String,
      validator(size) {
        return ["small", "medium", "large"].includes(size);
      },
      default: "medium",
    },
  },
  computed: {
    imageUrls() {
      if (!this.images) return [];
      return this.images.map((image) => `${image}${this.size}.jpg`);
    },
  },
};
</script>
<style scoped lang="scss">
.host-gallery.small-gallery {
  aspect-ratio: 4 / 3;
  background-image: linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 0%,
    rgba(255, 255, 255, 1) 12.5%,
    rgba(221, 221, 221, 1) 50%,
    rgba(255, 255, 255, 1) 62.5%,
    rgba(221, 221, 221, 1) 100%
  );
  background-size: 200%;
  animation: loaderAnimation 2s linear infinite normal forwards;

  @keyframes loaderAnimation {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 100%;
    }
  }
}
</style>
