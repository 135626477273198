<template lang="pug">
  .logo
    img.spacer.img-fluid.w-100(src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NgAAIAAAUAAR4f7BQAAAAASUVORK5CYII=")
</template>

<script>
export default {
  name: "LogoComponent",
}
</script>

<style scoped>
.logo{
  min-width:85px;
  mask:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTUwIDE1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTUwIDE1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgogICAgPHBhdGggY2xhc3M9InN0MCIgZmlsbD0iI2ZmZiIgZD0iTTYxLDExNi4zSDQ1LjJWMzMuN0g2MVY1Nmg5LjR2MTNINjFMNjEsMTE2LjNMNjEsMTE2LjN6IE0xMTQuMyw5My43Yy0yLjIsNS4zLTUuMyw5LjgtOS4xLDEzLjNjLTMuOSwzLjUtOC4xLDUuOS0xMi42LDcuM2MtNC41LDEuMy0xMi40LDItMjMuNiwyaC0wLjl2LTE1LjNoNWM3LjUsMCwxMy0wLjgsMTYuMy0yLjVjMy40LTEuNiw2LjItNC40LDguMy04LjNjMi4yLTMuOSwzLjItOC43LDMuMi0xNC40YzAtOC44LTIuNi0xNS42LTcuNy0yMC40Yy00LjYtNC4zLTEyLTYuNS0yMi4yLTYuNWgtM1YzMy43aDAuNmMxMi41LDAsMjEuOSwxLjUsMjgsNC41YzYuMSwzLDExLjEsNy44LDE1LjEsMTQuNWMzLjksNi43LDUuOSwxNC41LDUuOSwyMy41QzExNy42LDgyLjUsMTE2LjUsODguNCwxMTQuMyw5My43eiIgLz4KPC9zdmc+");
}
</style>