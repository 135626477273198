<template lang="pug">
  .bp-teaser.w-100.position-relative(:style="`background-image:url('${backgroundImage}');`")
    img.spacer.w-100.img-fluid(v-if="size === 'lg'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAACAQMAAABBkz8dAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjAAIAAAQAASDSLW8AAAAASUVORK5CYII=")
    img.spacer.w-100.img-fluid(v-else :src="backgroundImage")
</template>

<script>
export default {
  name: "BuyersProtectionTeaser",
  props:{
    size:{
      type:String,
      validator(size){
        const availableSizes = [
          'lg',
          'sm'
        ];

        return availableSizes.indexOf(size) !== -1
      },
      default:'lg'
    }
  },
  computed:{
    backgroundImage(){
      if(this.size === 'sm')return "https://s3-cloud.td-cdn.de/Images/makaira/modals/about-tdays/about-tdays-modal.jpg";
        return "https://s3-cloud.td-cdn.de/Images/makaira/subpages/buyer-protection/buyer-protection-teaser-pic-compressed.jpg";
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mediaqueries.scss';

.bp-teaser {
  background-size: cover;
  background-position: center;
  min-height:180px;
  max-height:400px;
  .spacer{
    visibility: hidden;
    pointer-events:none;
  }
}
</style>