<template lang="pug">
  .item-details(:class="{'fade-in':animated}")
    .article-number.txt-td-grey-basic(v-if="articleNumber && showsArticleNumber")
      span.user-select-none Art.-Nr.&nbsp;
      | {{articleNumber}}
    h2.title.item-h1.my-4(v-if="title") {{title}}
    template(v-if="accommodation")
      b Unterbringung
      ul.accommodations.listing.mb-4.pl-3
        li.accommodation {{accommodation}}
    template(v-if="caterings && caterings.length > 0")
      b Verpflegung
      ul.caterings.listing.mb-4.pl-3
        li.catering(v-for="catering in caterings") {{catering}}
    template(v-if="holidayServices && holidayServices.length > 0")
      b Folgende Leistungen sind inklusive:
      ul.holiday-services.listing.mb-4.pl-3
        li.holiday-service(v-for="holidayService in holidayServices") {{holidayService}}
    template(v-if="touristServices && touristServices.length > 0")
      b.italic Zusätzlich enthaltene touristische Leistungen (Urlaubsguthaben):
      ul.tourist-services.listing.mb-4.pl-3
        li.tourist-service(v-for="touristService in touristServices") {{touristService}}
    .mb-4(v-if="additionalInfo")
      b Zusatzinformationen:
      div {{additionalInfo}}
    TouriBookInfo(:bookability="bookability" :validity="validity" :id="id" :shows-validity="showsTouriBookInfo" :shows-cta="showsTouriBookInfo")

</template>

<script>
import TouriBookInfo from "@/views/components/host/layout/partials/TouriBookInfo";
import moment from "moment";

export default {
  name: "HostItemDetails",
  components: { TouriBookInfo },
  data() {
    return {
      moment: moment
    };
  },
  props: {
    id: String,
    title: String,
    articleNumber: [String, Number],
    accommodation: String,
    animated: {
      type: Boolean,
      default: true
    },
    showsArticleNumber: {
      type: Boolean,
      default: true
    },
    showsTouriBookInfo: {
      type: Boolean,
      default: true
    },
    caterings: {
      type: Array,
      validator(value) {
        return value.every((v) => typeof v === "string");
      }
    },
    holidayServices: {
      type: Array,
      validator(value) {
        return value.every((v) => typeof v === "string");
      }
    },
    touristServices: {
      type: Array,
      validator(value) {
        return value.every((v) => typeof v === "string");
      }
    },
    additionalInfo: String,
    bookability: String,
    validity: {
      type: Object,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries";

.item-details {
  .title {
    font-weight: 500;
    font-size: 1.3rem;
    @include md {
      font-size: 1.75rem;
    }
    line-height: 1.2;
  }

  &.fade-in {
    animation: fadeIn 0.8s normal forwards;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translate(-25px, 0px);
      }
      100% {
        opacity: 1;
        transform: translate(0px, 0px);
      }
    }
  }
}
</style>
