<template lang="pug">
.container-headline.text-center.position-relative
  .d-inline-block.px-3.bg-page
    slot
</template>

<script>
export default {
  name: "ContainerHeadline",
};
</script>

<style scoped lang="scss">
.container-headline {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: -1;
    isolation: isolate;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      currentColor 15%,
      currentColor 50%,
      currentColor 85%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.3;
  }
}
</style>
