<template lang="pug">
aside.host-sidebar
  header.menu-header.d-flex.align-items-center.justify-content-center.bg-td-grey-darker
    template(v-if="!$slots.header")
      .td-logo
        LogoComponent.bg-white
    slot(name="header" v-else)
  .contents
    slot
</template>

<script>
import LogoComponent from "@/views/components/logo/LogoComponent";

export default {
  name: "HostViewSidebar",
  components: {
    LogoComponent,
  },
};
</script>
