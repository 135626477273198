<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 41.5 41.5">
    <path fill="currentColor" d="M23,29v5.6c0,.9.7,1.6,1.6,1.6h1.6v-8.8h-1.6c-.9,0-1.6.7-1.6,1.6Z" />
    <path fill="currentColor"
          d="M37.1,26h-4.7v-3.4c0-1.1-.9-1.9-1.9-1.9h0c-.4,0-.7.2-.9.6l-2.7,6.2v8.8h9c.7,0,1.3-.5,1.4-1.2l1.3-7.3c.2-.9-.5-1.7-1.4-1.7Z" />
    <path fill="currentColor"
          d="M25.6,25.4l2.1-5c.5-1.1,1.5-1.8,2.7-1.8h0c2.2,0,3.9,1.8,3.9,3.9v1.4h.2v-13l-13.3,11.4c-.2.2-.4.2-.6.2s-.5,0-.6-.2L6.7,11v19.7c0,.5.5,1,1,1h13.3v-2.7c0-2,1.6-3.6,3.6-3.6h1Z" />
    <polygon fill="currentColor" points="33.2 9.7 20.7 9.7 8.3 9.7 20.7 20.3 33.2 9.7" />
  </svg>
</template>

<script>
export default {
  name: "EnvelopeThumbsUpIcon"
};
</script>
