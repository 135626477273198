<template lang="pug">
  nav#bottom-nav
    .container-hd
      .collapsable.bg-td-grey-lighter.cursor-pointer(v-for="(navItem,index) in navItems")
        .d-flex.align-items-center.justify-content-between.container.py-1(v-b-toggle="`nav-item-${index+1}`")
          b {{navItem.label}}
          font-awesome-icon(:icon="collapseStates[index] ? 'minus' : 'plus'")
        b-collapse(:id="`nav-item-${index+1}`" v-model="collapseStates[index]")
          .bg-page.py-3.children
            .container
              ul.list-style-none.p-0.m-0
                li.my-2(v-for="child in navItem.children")
                  a(:href="`${child.link}${child.noRef ? '' : '?ref=from_footer'}`" v-if="child.link") {{child.label}}
                  a(@click="showModal(child.modal)" v-if="child.modal") {{child.label}}
</template>

<script>
import CoronaModal from "@/views/components/modals/corona-modal";

export default {
  name: "BottomNavigation",
  setup() {
    const navItems = [
      {
        label: "Gäste",
        children: [
          { label: "touriDat Benutzerkonto", link: "/login" },
          { label: "Widerrufsrecht", link: "/widerrufsrecht" },
          { label: "AGB", link: "/agb" },
          { label: "Datenschutzerklärung", link: "/datenschutz" },
          { label: "FAQ", link: "/faq" },
          { label: "Kontakt", link: "/kontakt" },
          { label: "Newsletter", link: "/newsletter" },
          { label: "touriDays-Garantie", link: "/touridays" },
          { label: "Versand", link: "/versand" },
          //{label:'Geschenkbox',link:'/geschenkbox'},
          //{label:'App',link:'/app'},
          { label: "Corona Hinweis", modal: CoronaModal }
        ]
      },
      {
        label: "Gastgeber",
        children: [
          { label: "Gastgeber-Login", link: "https://dashboard.touridat.com", noRef: true },
          { label: "Als Gastgeber bewerben", link: "/werde-ein-gastgeber" },
          {
            label: "Auftrags-AGB (für Gastgeber)",
            link: "https://service.touridat.com/Intern/htmlpage.aspx?ID=248",
            noRef: true
          }
        ]
      },
      {
        label: "Allgemein",
        children: [
          { label: "Über uns", link: "/ueber-uns" },
          { label: "Jobs", link: "/karriere" },
          { label: "Partnerprogramm", link: "/partner" },
          { label: "Sponsoring", link: "/sponsoring" }
        ]
      }
    ];

    return { navItems };
  },
  data() {
    return {
      collapseStates: []
    };
  },
  methods: {
    showModal(modal) {
      try {
        this.$modal.show(modal, {}, {
          height: "auto"
        });
      } catch (e) {
        this.$log.error(e);
      }
    }
  },
  created() {
    this.navItems.forEach(() => {
      this.collapseStates.push(false);
    });
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.collapsable {
  border-bottom: 1px solid $page-bg;

  &, .children a {
    user-select: none;
    color: $td-grey-basic;
    transition: color 0.25s;

    &:hover {
      color: $td-grey-dark;
    }
  }

  .children a {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
