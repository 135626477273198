import gql from "graphql-tag";

export const Items = {
  Queries: {
    Item: gql`
        query item($id: ID) {
            item(id: $id) {
                id
                Name
                SystemItemId
                WebTemplateId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                LiveShoppingPrice
                CurrentPrice
                IsVisibleInItemListing
                IsVisibleInShop
                IsTouriTippsItem
                IsHighlightOfTheWeekOnStartPage
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                StockAvailableQuantityRegularPrice
                StockReservedQuantityRegularPrice
                StockAvailableQuantityDealPrice
                StockReservedQuantityDealPrice
                SoldWithin24h
                SoldWithin1W
                SoldWithin1M
                SoldWithin1Y
                HostId
                Host {
                    Images
                    Url
                }
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                    Accomodations
                    Caterings
                    ProductValidity
                }
            }
        }
    `,
    HostItems: gql`
        query hostItems($hostId: ID, $isVisibleInItemListing: Boolean, $mergeAttributes: Boolean) {
            hostItems(hostId: $hostId, isVisibleInItemListing: $isVisibleInItemListing, mergeAttributes: $mergeAttributes) {
                id
                Name
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                LiveShoppingPrice
                CurrentPrice
                Name1
                Name2
                Name3
                MetaKeywords
                MetaDescription
                UrlPath
                ItemText
                TechnicalText
                IsTouriTippsItem
                IsVisibleInShop
                IsVisibleInItemListing
                IsHighlightOfTheWeekOnStartPage
                Custom_IsTouriBookCalendarActive
                Custom_VoucherPersons
                Custom_HostStarRating
                Custom_HostCateringService
                Custom_Nights
                Custom_HostId
                Custom_BlauItemId
                Custom_HostId_Homepage
                Custom_Longitude
                Custom_Latitude
                Custom_Hotel_Address
                Custom_Host_Address
                Custom_Hotel_Title
                Custom_Hotel_Subtitle
                Custom_Hotel_Description
                Custom_Product_Headline01
                Custom_Product_Headline02
                Custom_Product_Headline03
                Custom_Product_Description01
                Custom_Product_Description02
                Custom_Product_Arrangement
                Custom_Product_HotelServices
                Custom_Product_TouristServices
                Custom_Product_ServicesExtra
                Custom_Product_Bookability
                Custom_Product_BookabilityInfo
                Custom_Product_VimeoURL
                Custom_Product_Images
                Custom_Product_SearchCriterias
                Custom_ImagePositionUsage
                Custom_Slider_Img_Url
                Custom_Slider_Img_Vibrant_Color
                Custom_Hotel_Type
                Custom_Slider_Text
                Custom_Region_City
                Custom_Hotel_Accommodation
                Custom_Product_Specials
                Custom_Hotel_Name
                Custom_LiveShopping_Headline
                Custom_LiveShopping_Subline
                Custom_Flat_Filter_Criteria
                Custom_Hotel_Description_02
                Custom_Hotel_Description_03
                Custom_Hotel_Description_04
                Custom_MetaTitle
                Custom_Img_Banner_Html_Snippet
                Custom_Slider_Images
                Custom_Product_Note
                HighlightImages
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                StockAvailableQuantityRegularPrice
                StockReservedQuantityRegularPrice
                StockAvailableQuantityDealPrice
                StockReservedQuantityDealPrice
                Caterings
                Accomodations
                ProductValidity
                HostId
            }
        }
    `,
    ShoppingBasketItems: gql`
        query shoppingBasketItems {
            shoppingBasketItems {
                id
                SystemItemId
                WebTemplateId
                Name
                IsInLiveShopping
                IsTouriTippsItem
                Nights
                Persons
                Catering
                Quantity
                StockAvailableQuantityRegularPrice
                StockReservedQuantityRegularPrice
                StockAvailableQuantityDealPrice
                StockReservedQuantityDealPrice
                DiscountedPrice
                LiveShoppingPrice
                RegularPrice
                CustomAttributes {
                    Name1
                }
                Host {
                    id
                    Name
                    Images
                    Url
                }
            }
        }
    `,
    LiveShoppingItems: gql`
        query liveShoppingItems {
            liveShoppingItems {
                id
                RegularPrice
                DiscountedPrice
                CurrentPrice
                RegularPrice
                DiscountedPrice
                CurrentPrice
                AdvertisedTill
                AdvertisedFrom
                Name1
                Name2
                Name3
                MetaKeywords
                MetaDescription
                UrlPath
                ItemText
                TechnicalText
                Custom_IsTouriBookCalendarActive
                Persons
                Catering
                Nights
                Custom_HostId
                SystemItemId
                Custom_HostId_Homepage
                Custom_Longitude
                Custom_Latitude
                Custom_Hotel_Address
                Custom_Host_Address
                Custom_Hotel_Title
                Custom_Hotel_Subtitle
                Custom_Hotel_Description
                Custom_Product_Headline01
                Custom_Product_Headline02
                Custom_Product_Headline03
                Custom_Product_Description01
                Custom_Product_Description02
                Custom_Product_Arrangement
                Custom_Product_HotelServices
                Custom_Product_TouristServices
                Custom_Product_ServicesExtra
                Custom_Product_Bookability
                Custom_Product_BookabilityInfo
                Custom_Product_VimeoURL
                Custom_Product_Images
                Custom_Product_SearchCriterias
                Custom_ImagePositionUsage
                Custom_Slider_Img_Url
                Custom_Slider_Img_Vibrant_Color
                Custom_Hotel_Type
                Custom_Slider_Text
                Custom_Region_City
                Custom_Hotel_Accommodation
                Custom_Product_Specials
                Custom_Hotel_Name
                Custom_LiveShopping_Headline
                Custom_LiveShopping_Subline
                Custom_Flat_Filter_Criteria
                Custom_Hotel_Description_02
                Custom_Hotel_Description_03
                Custom_Hotel_Description_04
                Custom_MetaTitle
                Custom_Img_Banner_Html_Snippet
                Custom_Slider_Images
                Custom_Product_Note
                Quantity
            }
        }
    `,
    HighlightItem: gql`
        query HighlightItem {
            highlightItem {
                id
                Name
                CustomAttributes {
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    HighlightImages
                }
                RegularPrice
                CurrentPrice
                AdvertisedFrom
                AdvertisedTill
                Quantity
                Host {
                    Url
                }
            }
        }
    `,
    HighestPriceByCategoryId: gql`
        query highestPriceByCategoryId($categoryId: ID) {
            highestPriceByCategoryId(categoryId: $categoryId)
        }
    `,
    LowestPriceByCategoryId: gql`
        query lowestPriceByCategoryId($categoryId: ID) {
            lowestPriceByCategoryId(categoryId: $categoryId)
        }
    `,
    HighestNightsByCategoryId: gql`
        query highestNightsByCategoryId($categoryId: ID) {
            highestNightsByCategoryId(categoryId: $categoryId)
        }
    `,
    LowestNightsByCategoryId: gql`
        query lowestNightsByCategoryId($categoryId: ID) {
            lowestNightsByCategoryId(categoryId: $categoryId)
        }
    `,
    DiscountVoucherItem: gql`
        query discountVoucherItem($value: Float) {
            discountVoucherItem(value: $value) {
                id
                Name
                SystemItemId
                IsInLiveShopping
                IsTouriTippsItem
                RegularPrice
                CurrentPrice
                WebTemplateId
                Host {
                    Name
                    Images
                    Url
                }
            }
        }
    `,
    ItemByHostIdAndSelectionVoucherId: gql`
        query itemByHostIdAndSelectionVoucherId($hostId: ID, $selectionVoucherId: ID) {
            itemByHostIdAndSelectionVoucherId(hostId: $hostId, selectionVoucherId: $selectionVoucherId) {
                id
                RegularPrice
                DiscountedPrice
                CurrentPrice
                RegularPrice
                DiscountedPrice
                CurrentPrice
                AdvertisedTill
                AdvertisedFrom
                Name1
                Name2
                Name3
                MetaKeywords
                MetaDescription
                UrlPath
                ItemText
                TechnicalText
                Custom_IsTouriBookCalendarActive
                Persons
                Catering
                Nights
                Custom_HostId
                SystemItemId
                Custom_HostId_Homepage
                Custom_Longitude
                Custom_Latitude
                Custom_Hotel_Address
                Custom_Host_Address
                Custom_Hotel_Title
                Custom_Hotel_Subtitle
                Custom_Hotel_Description
                Custom_Product_Headline01
                Custom_Product_Headline02
                Custom_Product_Headline03
                Custom_Product_Description01
                Custom_Product_Description02
                Custom_Product_Arrangement
                Custom_Product_HotelServices
                Custom_Product_TouristServices
                Custom_Product_ServicesExtra
                Custom_Product_Bookability
                Custom_Product_BookabilityInfo
                Custom_Product_VimeoURL
                Custom_Product_Images
                Custom_Product_SearchCriterias
                Custom_ImagePositionUsage
                Custom_Slider_Img_Url
                Custom_Slider_Img_Vibrant_Color
                Custom_Hotel_Type
                Custom_Slider_Text
                Custom_Region_City
                Custom_Hotel_Accommodation
                Custom_Product_Specials
                Custom_Hotel_Name
                Custom_LiveShopping_Headline
                Custom_LiveShopping_Subline
                Custom_Flat_Filter_Criteria
                Custom_Hotel_Description_02
                Custom_Hotel_Description_03
                Custom_Hotel_Description_04
                Custom_MetaTitle
                Custom_Img_Banner_Html_Snippet
                Custom_Slider_Images
                Custom_Product_Note
                Quantity
                HostId
            }
        }
    `,
    ItemsBySelectionVoucherId: gql`
        query itemsBySelectionVoucherId($selectionVoucherId: ID) {
            itemsBySelectionVoucherId(selectionVoucherId: $selectionVoucherId) {
                id
                SystemItemId
                RegularPrice
                DiscountedPrice
                CurrentPrice
                RegularPrice
                DiscountedPrice
                CurrentPrice
                AdvertisedTill
                AdvertisedFrom
                Persons
                Catering
                Nights
                Quantity
                HostId
                WebTemplateId
                IsVisibleInShop
                IsVisibleInItemListing
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                    Accomodations
                    Caterings
                    ProductValidity
                }
            }
        }
    `,
    RedeemableItemsBySelectionVoucherId: gql`
        query redeemableItemsBySelectionVoucherId($selectionVoucherId: ID) {
            redeemableItemsBySelectionVoucherId(selectionVoucherId: $selectionVoucherId) {
                id
                SystemItemId
                RegularPrice
                DiscountedPrice
                CurrentPrice
                RegularPrice
                DiscountedPrice
                CurrentPrice
                AdvertisedTill
                AdvertisedFrom
                Persons
                Catering
                Nights
                Quantity
                HostId
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                    Accomodations
                    Caterings
                    ProductValidity
                }
            }
        }
    `,
    AvailableShoppingBasketItemQuantities: gql`
        query AvailableShoppingBasketItemQuantities($shoppingBasketItems: [ShoppingBasketItem]!) {
            availableShoppingBasketItemQuantities(shoppingBasketItems: $shoppingBasketItems) {
                ItemId
                AvailableRegularQuantity
                AvailableDealQuantity
            }
        }
    `
  }
};
