<template lang="pug">
  .modal-dialog.m-0
    .modal-content
      .modal-header
        .text-xxl.modal-title.text-center.w-100.mt-4 Kläppchen {{day}}
        .txt-tpics-lighter.text-center(v-if="wasDrawn") Verlosung abgeschloßen
      .modal-body.px-4.m-0
        .prize.text-white.p-4.my-4(v-for="prize in itemPrizes")
          .font-weight-semibold.d-inline-flex.align-items-start(v-if="getTicket(prize)")
            CrownIcon.mr-1(style="font-size:150%;")
            span Dieser Gewinn geht an die Losnummer {{getTicket(prize)}}
          .image-gallery.w-100(v-if="getGalleryImages(prize).length > 1")
            .image.d-md-block(v-for="image in getGalleryImages(prize)")
              img.img-fluid(:src="image.RemoteURL")
          .single-image.w-100(v-else)
            img.img-fluid(:src="prize.ImageUrl")
          .position-relative(style="display: grid;grid-template-columns: 1fr auto;")
            .mb-4.mt-2
              .text-lg.font-weight-semibold {{prize.HtmlHeadline}}
              .text-md {{prize.HtmlSubline}}
            .position-relative(style="height:1rem")
              .value-badge.position-relative.aspect-square.text-lg.bg-xmas.p-2.text-center.d-flex.align-items-center.justify-content-center.txt-td-grey-darker.rounded-circle(style="min-width:max-content;right:0;top:0;transform:translateY(-50%);")
                div
                  | Wert:
                  br
                  | {{(prize.Value || prize.Item.RegularPrice) | formatPrice}}
          .text-md.mb-4(style="line-height:1.2;") {{prize.HtmlDescription}}
          .text-center
            router-link.button.button-primary.button-xmas(:to="`/gastgeber/${prize.Item.Host.Url}#${prize.Item.id}`" target="_blank" v-if="prize.Item.id != 3930")
              .text-lg zum Angebot
        .prize.text-white.p-4.my-4(v-if="hasDiscountOnlyPrizes")
          .font-weight-semibold(v-if="discountPrizeWinners && discountPrizeWinners.length")
            .d-flex.align-items-start.justify-content-between
              .d-inline-flex.align-items-start
                CrownIcon.mr-1(style="font-size:150%;")
                span Diesen Gewinn erhalten mehrere Losnummern:
              div
                button.m-0.p-0.ml-1.text-underline.cursor-pointer.no-style.font-weight-semibold.text-white(@click="showCombinedWinners = !showCombinedWinners")
                  template(v-if="showCombinedWinners") Gewinner Ausblenden
                  template(v-else) Gewinner Anzeigen
                div(v-if="showCombinedWinners")
                  div(v-for="ticket in discountPrizeWinners") {{ticket}}
          .single-image.w-100
            img.img-fluid(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2024/xmas_24_wertgutscheine.jpg")
          .mb-4.mt-2
            .text-lg.font-weight-semibold Wertgutscheine
            .text-md.mb-4(style="line-height:1.2;") Neben den traumhaften Reisen von touriDat und den diesjährigen Luxusreisen haben Sie täglich die Zusatz-Chance, einen von zehn 25-EUR-Wertgutscheinen zu gewinnen und Ihre nächste Reise bei touriDat noch günstiger zu schnappen. An Nikolaus und an Heiligabend werden sogar jeweils 15 Wertgutscheine verlost. Die Wertgutscheine sind frei übertragbar, nicht an einen Mindestbestellwert gebunden und können bis zum 31.12.2025 eingelöst werden.
        //.prize.text-white.p-4.my-4
          .font-weight-semibold(v-if="getTicket(prize)") Dieser Gewinn geht an die Losnummer {{getTicket(prize)}}
          .image-gallery.w-100
            .image.d-md-block(v-for="image in getGalleryImages(prize)")
              img.img-fluid(:src="image.RemoteURL")
          .mb-4.mt-2
            .text-lg.font-weight-semibold {{prize.HtmlHeadline}}
            .text-md {{prize.HtmlSubline}}
          .text-md.mb-4(style="line-height:1.2;") {{prize.HtmlDescription}}
          .text-center
            router-link.button.button-primary.button-xmas(:to="`/gastgeber/${prize.Item.Host.Url}#${prize.Item.id}`" target="_blank")
              .text-lg zum Angebot
      .modal-footer
        .px-4.pb-3.text-center.text-md-right
          button.button.button-primary.button-tdays.txt-white(@click="$emit('close')") schließen
</template>
<script>
import ResponsiveHostGallery from "@/views/components/host/gallery/ResponsiveHostGallery.vue";
import { getDisplayableHostImages } from "@/lib/helper/host";
import CrownIcon from "@/views/components/partials/icons/CrownIcon.vue";

export default {
  name: "XmasPrizesModal",
  components: { CrownIcon, ResponsiveHostGallery },
  props: {
    day: {
      type: Number,
      required: true
    },
    prizes: {
      type: Array,
      required: true
    }
  },
  setup() {
    return { getDisplayableHostImages };
  },
  data() {
    return {
      showCombinedWinners: false
    };
  },
  computed: {
    wasDrawn() {
      return this.prizes.filter((prize) => prize.MarketingPromoParticipant).length === this.prizes.length;
    },
    discountPrizeWinners() {
      const prizes = this.prizes.filter((prize) => {
        const winners = prize.MarketingPromoParticipant?.MarketingPromoContestParticipants || [];
        return !!winners.map((winner) => winner.Ticket).length;
      });
      return prizes.flatMap((prize) => {
        const winners = prize.MarketingPromoParticipant?.MarketingPromoContestParticipants || [];
        return winners.map((winner) => winner.Ticket);
      });
    },
    hasDiscountOnlyPrizes() {
      return !!this.prizes.find((prize) => prize.IsDiscountOnlyPrize);
    },
    itemPrizes() {
      return this.prizes.filter((prize) => !!prize?.Item);
    }
  },
  methods: {
    getTicket(prize) {
      const participants = prize?.MarketingPromoParticipant?.MarketingPromoContestParticipants || [];
      const [participant] = participants;
      return participant?.Ticket;
    },
    getGalleryImages(prize) {
      if (!prize?.Item?.Host?.Images?.length) {
        return [].map((RemoteURL) => ({ RemoteURL }));
      }
      return getDisplayableHostImages(prize.Item.Host).slice(0, 3).reverse().map((image, index) => ({
        ...image,
        RemoteURL: `${image.RemoteURL}${index === 2 ? "medium" : "small"}.jpg`
      }));
    }
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  }
};
</script>
<style lang="scss">
.xmas-prizes-modal {
  max-width: 800px !important;
  left: auto !important;
  margin: 0 auto !important;

  .modal-body {
    .prize:first-child {
      margin-top: 0 !important;
    }
  }

  .modal-dialog {
    max-width: none !important;
  }

  .modal-content {
    background-color: transparent !important;
    background-image: url("@/assets/images/bg2.jpg");
    background-repeat: repeat;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries";

.value-badge {
  &:before {
    content: "";
    position: absolute;
    left: -0.5rem;
    top: -0.5rem;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    border: 4px solid #D6CDB6;
    border-radius: 50%;
  }
}

.prize {
  background-color: rgba(26, 78, 23, 0.6);
  backdrop-filter: blur(3px);
}

.image-gallery {
  .image img {
    width: 100%;
  }

  @include md {
    display: grid;
    gap: 0.25rem;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(2, minmax(0, 1fr));

    .image:nth-child(3) {
      grid-row: span 2;
      overflow: hidden;
      aspect-ratio: 4 / 3.05;

      img {
        height: 100%;
        width: auto;
        max-width: calc(100% + 0.25rem);
        margin-left: -0.25rem;
      }
    }
  }


  .image {
    &:nth-child(2) {
      grid-area: 2 / 1;
    }

    &:not(:nth-child(3)) {
      display: none;
    }
  }
}
</style>
