export default class MailValidator {
  /**
   *
   * @param { string } email
   * @returns { boolean }
   */
  static validate(email) {
    const mailformat = /(.+)@(.+){2,}\.(.+){2,}/;
    return email.match(mailformat) !== null;
  }
}
