import config from "@/config/config";

/**
 * @returns {HTMLScriptElement}
 */
export const useScript = () => {
  let recaptchaScript = document.querySelector("#recaptcha-script");
  if (recaptchaScript) return recaptchaScript;
  recaptchaScript = document.createElement("script");
  recaptchaScript.id = "recaptcha-script";
  recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${config.external.google.recaptcha.siteKey}`;
  document.head.appendChild(recaptchaScript);
  return recaptchaScript;
};

/**
 * @returns { Promise<string> } a Recaptcha-Token on success
 */
export const useRecaptcha = async () => {
  return new Promise((resolve) => {
    const grecaptcha = window["grecaptcha"];
    if (!grecaptcha) throw new Error(`grecaptcha is not defined`);
    grecaptcha.execute(config.external.google.recaptcha.siteKey, { action: "submit" }).then(function(token) {
      resolve(token);
    });
  });
};
