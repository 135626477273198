import gql from "graphql-tag";

export const MarketingPromoContest = {
  Queries: {
    MarketingPromoContestPrizesByDrawPrizeAt: gql`
        query MarketingPromoContestPrizesByDrawPrizeAt($marketingPromoContestId: ID!, $drawPrizeAt: Date) {
            marketingPromoContestPrizesByDrawPrizeAt(marketingPromoContestId: $marketingPromoContestId, drawPrizeAt: $drawPrizeAt) {
                id
                IsDiscountOnlyPrize
                Value
                HtmlHeadline
                HtmlSubline
                HtmlDescription
                ImageUrl
                MarketingPromoParticipant {
                    MarketingPromoContestParticipants {
                        Ticket
                    }
                }
                Item {
                    id
                    Name
                    Persons
                    Nights
                    Catering
                    RegularPrice
                    Host {
                        Url
                        Images
                    }
                }
            }
        }
    `,
    MarketingPromoContest: gql`
        query MarketingPromoContest($marketingPromoContestId: ID!) {
            marketingPromoContest(id: $marketingPromoContestId) {
                id
                Name
                PrizeEnabledFrom
                PrizeEnabledTill
                RegistrationFrom
                RegistrationTill
                MarketingPromoContestPrizes {
                    id
                    DrawPrizeAt
                    PrizeDrawnAt
                }
            }
        }
    `,
    ListWinnerPrizes: gql`
        query ListMarketingPromoContestPrizes($ids: [ID!]!) {
            listMarketingPromoContestPrizes(ids: $ids) {
                id
                IsDiscountOnlyPrize
                Value
                PrizeDrawnAt
                DrawPrizeAt
                Item {
                    Name
                }
            }
        }
    `
  },
  Mutations: {
    SignupForContest: gql`
        mutation SignupForContest($contestId: ID!, $token: String!, $email: String!, $source: String, $referrerGuid: String) {
            signupForContest(contestId: $contestId, token: $token, email: $email, source: $source, referrerGuid: $referrerGuid) {
                id
                Ticket
                MarketingPromoParticipantReferrerId
            }
        }
    `
  }
};
