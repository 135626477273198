<template lang="pug">
.modal-dialog.m-0
  .modal-content
    .modal-body.p-0
      .items.bg-page.p-3
        details(v-for="(group,index) in itemGroups" :open="activeGroup && activeGroup.label === group.label || index === 0")
          summary.h2.m-0.py-3 {{group.label}}
          HostItemMenuItem.item(v-for="(item,index) in group.items" :item="item" :class="index < group.items.length-1 ? 'mb-2' : ''" :has-tipps-discount-applied="tippsItems.includes(item.id)")
</template>
<script>
import HostItemMenuItem from "@/views/components/host/layout/partials/HostItemMenuItem.vue";
import { isItemInLiveShopping } from "@/lib/helper/items";

export default {
  name: "ItemMenuModal",
  components: { HostItemMenuItem },
  emits: ["close"],
  props: {
    itemGroups: {
      type: Array,
      required: true,
    },
    activeGroup: Object,
  },
  data() {
    return {
      tippsItems: [],
    };
  },
  watch: {
    tippsItems(itemIds) {
      if (!itemIds?.length) {
        this.$session.remove("appliedTippsDiscounts");
        return;
      }
      this.$session.set("appliedTippsDiscounts", itemIds);
    },
    $route() {
      this.$emit("close");
    },
  },
  setup() {
    return { isItemInLiveShopping };
  },
  methods: {
    getHasTippsDiscount(item) {
      const showTipps = this.$route?.query?.tipps || false;
      return showTipps && item.IsTouriTippsItem;
    },
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  },
  unmounted() {
    document.body.classList.remove("no-scroll");
  },
};
</script>
<style lang="scss">
.item-menu-modal {
  width: 100vw !important;
  max-width: 500px !important;
  left: 50% !important;
  transform: translate(-50%, 0%);
  margin: 0 !important;
}
</style>
