// @ts-ignore
import {Checkout} from "../../../graphql/Checkout.ts";

export class CheckoutComponent {
    handleKlarnaCheckout(
        apollo,
        items,
        voucherCode,
        shippingType,
        referrerId,
        cb
    ) {
        try {
            apollo
                .mutate({
                    mutation: Checkout.Mutations.CreateKlarnaOrder,
                    variables: {
                        shoppingBasketItems: items,
                        code: voucherCode,
                        shippingTypeId: shippingType,
                        referrerId: referrerId,
                    },
                })
                .then(({data}) => {
                    if (!data || !data.createKlarnaOrder) {
                        return cb(undefined);
                    }
                    return cb(data.createKlarnaOrder);
                });
        } catch (e) {
            return cb({
                IsSuccess: false,
                Message: e.message,
            });
        }
    }

    handlePaypalCheckout(
        apollo,
        items,
        voucherCode,
        shippingType,
        referrerId,
        cb
    ) {
        try {
            apollo
                .mutate({
                    mutation: Checkout.Mutations.CreatePaypalOrder,
                    variables: {
                        shoppingBasketItems: items,
                        code: voucherCode,
                        shippingTypeId: shippingType,
                        referrerId: referrerId,
                    },
                })
                .then(({data}) => {
                    if (!data || !data.createPaypalOrder) {
                        return cb(undefined);
                    }
                    return cb(data.createPaypalOrder);
                });
        } catch (e) {
            console.log(e);
            return cb(undefined);
        }
    }

    async getAmazonButtonSignature(apollo, payload) {
        try {
            const amazonButtonSignature = await apollo
                .query({
                    query: Checkout.Queries.AmazonButtonSignature,
                    fetchPolicy: "no-cache",
                    variables: {
                        payload: payload,
                    },
                })
                .then(({data}) => data?.amazonButtonSignature)
            .catch((e) => {
                console.error(e);
                return undefined;
            });
            if (!amazonButtonSignature) {
                return undefined;
            }
            return amazonButtonSignature;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async getAmazonPayload(apollo, items, voucherCode, shippingType, referrerId) {
        try {
            const amazonPayload = await apollo
                .query({
                    query: Checkout.Queries.AmazonPayload,
                    fetchPolicy: "no-cache",
                    variables: {
                        shoppingBasketItems: items,
                        code: voucherCode,
                        shippingTypeId: shippingType,
                        referrerId: referrerId,
                    },
                })
                .then(({data}) => data?.amazonPayload)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!amazonPayload) {
                return undefined;
            }
            return amazonPayload;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async getAmazonPayloadByOrderIdentifier(apollo, orderIdentifier) {
        try {
            const amazonPayload = await apollo
                .query({
                    query: Checkout.Queries.AmazonPayloadByOrderIdentifier,
                    fetchPolicy: "no-cache",
                    variables: {
                        orderIdentifier: orderIdentifier,
                    },
                })
                .then(({data}) => data?.amazonPayloadByOrderIdentifier)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!amazonPayload) {
                return undefined;
            }
            return amazonPayload;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }
}
