import { render, staticRenderFns } from "./KlarnaIcon.vue?vue&type=template&id=24d161f6&scoped=true&lang=pug"
import script from "./KlarnaIcon.vue?vue&type=script&lang=js"
export * from "./KlarnaIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d161f6",
  null
  
)

export default component.exports