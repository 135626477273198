import gql from "graphql-tag";

export const UserHostFavorites = {
  Queries: {
    UserHostFavorite: gql`
        query userHostFavorite($hostId: ID) {
            userHostFavorite(hostId: $hostId) {
                id
                UserId
                HostId
            }
        }
    `,
    UserHostFavorites: gql`
        query userHostFavorites {
            userHostFavorites {
                id
                UserId
                HostId
                Host {
                    id
                    Name
                    Images
                    CityRegion
                    Stars
                    Url
                    Longitude
                    Latitude
                    Items {
                        id
                        RegularPrice
                        CurrentPrice
                        Nights
                        Persons
                        Catering
                    }
                }
            }
        }
    `
  }
};
