<template lang="pug">
  #app-tabbar
    .app-tabbar-wrap
      .container-fluid
        .row
          .col-3
            a(href="/")
              span.tdays(:class="{ active: checkDefault}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridays/only-mark/colored/touridays-only-mark-height-55.png" alt="touriDays - Urlaubstage mit 100% Käuferschutz")
          .col-3
            a(href="/kategorien/golf")
              span.tgolf(:class="{ active: checkGolf}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/tourigolf/only-mark/colored/tourigolf-only-mark-height-55.png" alt="touriGolf - Golfreisen vom Testsieger")
          .col-3
            a(href="/kategorien/tipps")
              span.ttipps(:class="{ active: checkTipps}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touritipps/only-mark/colored/touritipps-only-mark-height-55.png" alt="touriTipps - Hotelempfehlungen des Monats")
          .col-3
            a(@click="showHotlineModal" style="cursor:pointer;")
              font-awesome-icon(icon="phone")


</template>

<script>
import {showHotlineModal} from "@/lib/components/hotlineModal";

export default {
  name: "AppTabbar",
  methods: {
    showHotlineModal,
  },
  computed: {
    checkTipps() {
      return window.location.href.includes('kategorien/tipps')
    },
    checkGolf() {
      return window.location.href.includes('kategorien/golf')
    },
    checkDefault() {
      return !this.checkGolf && !this.checkTipps;
    }
  },
}
</script>
<style scoped>
</style>
