<template lang="pug">
#overlay.d-flex.align-items-center.justify-content-center.loading-spinner-overlay(:class="{active:isVisible}")
  .backdrop
  LoadingSpinner(v-if="isVisible")
</template>

<script>
import LoadingSpinner from "@/views/components/loading/LoadingSpinner";

export default {
  name: "AppLoadingSpinner",
  components:{
    LoadingSpinner
  },
  props:{
    isVisible:Boolean
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.loading-spinner-overlay{
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 1200;

  .backdrop {
    position:absolute;
    background-color:rgba($td-grey-darker,0.7);
    width:100%;
    height:100%;
    opacity:0;
    transition:all 0.4s;
  }

  &.active {
    .backdrop {
      opacity:1;
    }
  }

  &:not(.active){
    pointer-events:none!important;
  }
}
</style>
