<template lang="pug">
.preview-host-gallery(:class="{loader:showLoader}")
  template(v-if="firstImage")
    img.img-fluid.w-100(:src="getImageUrl(firstImage)" v-show="isLoaded && images.length === 1" :alt="firstImage.alt" @load="isLoaded = true")
    b-carousel.gallery-slider.img-fluid(:interval="0" ref="imageCarousel" v-if="isLoaded && images.length > 1" lazyload="ondemand" @sliding-start="changeSlide" controls)
      .carousel-inner
        b-carousel-slide(v-for="(image,index) of images" :class="{'active':index === 0}")
          template(v-slot:img)
            b-img-lazy(class="img-fluid w-100" :src="getImageUrl(image)" :alt="image.alt" :show="index === 0")
</template>

<script>
const validateImage = (image) => {
  // image should not be undefined and should at least have an url property of type string
  return image && "url" in image && typeof image.url === "string";
};

export default {
  name: "PreviewHostGallery",
  props: {
    images: {
      type: Array,
      required: true,
      validator(images) {
        return images.every((image) => {
          return validateImage(image);
        });
      },
    },
    imageSize: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      isLoaded: false,
      showLoader: true,
    };
  },
  computed: {
    firstImage() {
      if (this.images.length === 0) return;
      return this.images[0];
    },
  },
  watch: {
    isLoaded(value) {
      if (value) this.showLoader = false;
    },
  },
  methods: {
    getImageUrl(image) {
      if (!validateImage(image)) return;
      return `${image.url}${this.imageSize}.jpg`;
    },
    changeSlide() {
      this.showLoader = true;
    },
    trackImageLoading() {
      this.showLoader = false;
    },
  },
  mounted() {
    this.$el.addEventListener("load", this.trackImageLoading, true);
  },
  unmounted() {
    this.$el.removeEventListener("load", this.trackImageLoading, true);
  },
  destroyed() {
    this.$el.removeEventListener("load", this.trackImageLoading, true);
  },
};
</script>
<style lang="scss">
.preview-host-gallery {
  .gallery-slider {
    .carousel-control-prev,
    .carousel-control-next {
      pointer-events: all !important;
      z-index: 1;
    }
  }
}
</style>
<style lang="scss" scoped>
.preview-host-gallery {
  width: 100%;
  &,
  .carousel-item img {
    aspect-ratio: 4 / 3;
  }
  .gallery-slider {
    pointer-events: none;
  }
}
</style>
