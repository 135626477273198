import gql from "graphql-tag";

export const OrderDiscountCodes = {
  Queries: {
    OrderDiscountCode: gql`
        query orderDiscountCode($code: String, $itemIds: [ID]) {
            orderDiscountCode(code: $code, itemIds: $itemIds) {
                id
                Value
                IsPercentage
                IsValidForLiveShoppingDiscount
                IsConstraintToItems
                Code
            }
        }
    `
  }
};
