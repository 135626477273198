<template lang="pug">
.mobile-frame.mobile-frame-footer.d-flex.align-items-center.justify-content-between
  a(v-for="item in navItems" :href="item.link" :class="[item.className,{active:item === activeItem}]")
    img.img-fluid.item-logo(:src="item.imageUrl" :alt="item.description")
  a(@click="showHotlineModal" style="cursor:pointer;")
    font-awesome-icon(icon="phone")
//
  router-link(:to="{name:'Home'}")
    span.tdays(:class="{ active: checkDefault}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridays/only-mark/colored/touridays-only-mark-height-55.png" alt="touriDays - Urlaubstage mit 100% Käuferschutz")
  a(href="/kategorien/golf")
    span.tgolf(:class="{ active: checkGolf}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/tourigolf/only-mark/colored/tourigolf-only-mark-height-55.png" alt="touriGolf - Golfreisen vom Testsieger")
  a(href="/kategorien/tipps")
    span.ttipps(:class="{ active: checkTipps}"): img.lozad(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touritipps/only-mark/colored/touritipps-only-mark-height-55.png" alt="touriTipps - Hotelempfehlungen des Monats")
  a(@click="showHotlineModal" style="cursor:pointer;")
      font-awesome-icon(icon="phone")
</template>
<script>
import {showHotlineModal} from "@/lib/components/hotlineModal";
import {WebTemplatesEnum} from "@/utils/WebTemplatesEnum.ts";
export default {
  name: "MobileFrameFooter",
  props:{
    templateType: Number
  },
  setup(){
    const navItems = [
      {className:'tdays',link:'/',description:'touriDays - Urlaubstage mit 100% Käuferschutz',imageUrl:'https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridays/only-mark/colored/touridays-only-mark-height-55.png',templateType:WebTemplatesEnum.Default},
      {className:'tgolf',link:'/kategorien/golf',description:'touriGolf - Golfreisen vom Testsieger',imageUrl:'https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/tourigolf/only-mark/colored/tourigolf-only-mark-height-55.png',templateType:WebTemplatesEnum.Golf},
      {className:'ttipps',link:'/kategorien/tipps',description:'touriTipps - Hotelempfehlungen des Monats',imageUrl:'https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touritipps/only-mark/colored/touritipps-only-mark-height-55.png',templateType:WebTemplatesEnum.Tipps}
    ];

    return {navItems}
  },
  computed:{
    activeItem(){
      try {
        if(!this.templateType) return undefined;
        return this.navItems.find((navItem) => navItem.templateType === this.templateType);
      } catch (e) {
        this.$log.error(e);
        return undefined;
      }
    }
  },
  methods:{
    showHotlineModal
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.mobile-frame-footer {
  left:0;
  bottom:0;

  a {
    flex:0 0 25%;
    display:flex;
    align-items:center;
    justify-content: center;
    height:100%;
    position:relative;
    transition: filter 0.4s,opacity 0.4s;

    &:before {
      content:'';
      position:absolute;
      width:100%;
      height:5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transform:scaleX(0);
      transition:transform 0.4s;
      top:0;
      left:0;
    }

    &.tdays:before {
      background-color: $tdays-basic;
    }
    &.tgolf:before {
      background-color: $tgolf-basic;
    }
    &.ttipps:before {
      background-color: $ttipps-basic;
    }

    &:not(.active){
      img {
        filter:grayscale(1);
      }

      &.tdays {
        img {
          opacity:0.6;
        }
      }
    }

    &.active {
      &:before {
        transform:scaleX(1);
      }
    }
  }

  .item-logo {
    width:auto;
    height:100%;
    max-height:25px;
  }
}
</style>