import gql from "graphql-tag";

export const Referrers = {
  Queries: {
    Referrer: gql`
      query Referrer($value: Int) {
        referrer(value: $value) {
          id
          Name
          InternalNote
          Value
          ShopCompanyId
          createdAt
          updatedAt
          deletedAt
        }
      }
    `,
  },
};
