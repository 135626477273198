<template lang="pug">
  #error
    .row.pt-lg-5
      .col-12
        .td-gradient-circle-container.d-block.w-100
          .td-gradient-circle-inner.mx-auto
            .td-gradient-circle-img-position
              img.td-gradient-circle-img.img-fluid(src="https://s3-cloud.td-cdn.de/Images/makaira/td-loading.png" alt="tD")
        .text-center
          .h1.mt-3 Achtung, Fehler!
          p {{message}}
        .container(v-if="showDev && stack")
          b Stacktrace
          textarea.stack.smaller.text-left.bg-white.p-4 {{stack}}
          .mt-4(v-if="errorJson && errorJson.length")
            b Parameter
            pre.info {{errorJson}}
      .col-12.col-lg-8.offset-lg-2
        hr
        .pt-4
          router-link.button.button-secondary.button-tdays.d-table.mx-auto(:to="{ name: 'Home' }") zurück zur Startseite
</template>

<script>
import ErrorMessage from "@/views/error/partials/ErrorMessage";
import FatalError from "@/lib/components/error/FatalError";
import httpErrorMessages from "@/lib/httpErrorMessages";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error",
  components: { ErrorMessage },
  props: {
    error: {
      type: Error,
      validator(err) {
        return err instanceof FatalError;
      }
    }
  },
  computed: {
    errorJson() {
      if (!this.error?.options) return;
      return JSON.stringify(this.error.options, null, 4);
    },
    showDev() {
      return process?.env?.NODE_ENV !== "production";
    },
    stack() {
      return this?.error?.stack;
    },
    message() {
      return this?.error?.message || httpErrorMessages["404"];
    }
  }
};
</script>

<style lang="scss" scoped>
.stack {
  min-height: 300px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  line-height: 2;
}
</style>
