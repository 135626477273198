<template lang="pug">
  .host-default
    .container
      BuyersProtectionBox
    .container
      HostHeader.py-4(:title="host.Name" :host-id="host.id" :sub-title="host.CityRegion" :stars="getNumberOfStars(host)" :is-superior="isHostSuperior(host)")
        template(v-slot:afterTitle)
          HostFavoriteIcon(:host="host" :items="items" v-if="host && items && items.length")
      ResponsiveHostGallery.pb-4(:images="getDisplayableHostImages(host)")
    HostSectionHeader.item-h1.mb-0.position-relative(style="z-index:1") Produktdetails
    .container
      .row.position-relative.align-items-stretch
        .col-lg-7.col-xl-8.pb-lg-0.details-column
          MetaItem(:item="selectedItem" :description="selectedItem.CustomAttributes.Custom_Hotel_Description || host.CustomAttributes.SeoDescription || host.CustomAttributes.HotelDescription" :host-url="host.Url" :host-name="host.Name" :region="host.CityRegion" :stars="getNumberOfStars(host)" :images="getDisplayableHostImages(host)")
          HostItemDetails.mt-2(v-if="selectedItem" ref="itemDetails" v-bind="itemDetailAttributes")
        .col-lg-5.col-xl-4.menu-column
          HostItemMenu(:host="host" :items="sortedItems" :selected-item-id="selectedItem.id" :is-open="hasOpenMenu" @toggle="hasOpenMenu = !hasOpenMenu")
            template(v-slot:meta="{item}")
              MetaItem(:item="item" :host-url="host.Url")
    HostDescriptionSection(:host="host" :video="hostVideoUrl")
    HostSectionHeader.item-h2.mb-0 Betriebsdetails
    .container.py-4
      HostFeatureDrawer(:host="host")
    template(v-if="host.CustomAttributes.HotelAddress")
      hr.m-0
      .container.py-4
        .host-address
          b Gastgeber / Reiseanbieter:
          .adress.d-block(v-if="host.CustomAttributes.HotelAddress")
            | {{host.CustomAttributes.HotelAddress.Name}}
            br
            | {{host.CustomAttributes.HotelAddress.Street}}
            br
            | {{host.CustomAttributes.HotelAddress.Zip}}
            | {{host.CustomAttributes.HotelAddress.City}}
            br
            | {{host.CustomAttributes.HotelAddress.Country}}
          .adress.d-block(v-else-if="selectedItem.CustomAttributes.Custom_Host_Address")
            | {{selectedItem.CustomAttributes.Custom_Host_Address.Name}}
            br
            | {{selectedItem.CustomAttributes.Custom_Host_Address.Street}}
            br
            | {{selectedItem.CustomAttributes.Custom_Host_Address.Zip}}
            | {{selectedItem.CustomAttributes.Custom_Host_Address.City}}
            br
            | {{selectedItem.CustomAttributes.Custom_Host_Address.Country}}
    HostMap(:hosts="[host]" v-if="host")
</template>

<script>
import BuyersProtectionBox from "@/views/components/partials/buyersProtection/BuyersProtectionBox";
import HostHeader from "@/views/components/host/layout/HostHeader";
import ResponsiveHostGallery from "@/views/components/host/gallery/ResponsiveHostGallery";
import {
  getDisplayableHostImages,
  getNumberOfStars,
  isHostSuperior
} from "@/lib/helper/host";
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader";
import HostItemDetails from "@/views/components/host/layout/HostItemDetails";
import {
  getIsItemPurchasable,
  getItemAccommodation,
  getItemCaterings,
  getItemHolidayServices,
  hasTippsDiscount
} from "@/lib/helper/items";
import HostItemMenu from "@/views/components/host/layout/HostItemMenu";
import HostFeatureDrawer from "@/views/components/host/layout/HostFeatureDrawer";
import HostMap from "@/views/components/partials/hostMap/HostMap";
import HostDescriptionSection from "@/views/components/host/layout/partials/HostDescriptionSection";
import MetaItem from "@/views/components/meta/MetaItem";
import {
  sendSelectItem,
  sendViewItem,
  sendViewItemList
} from "@/lib/helper/gtm";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon.vue";

export default {
  name: "DefaultHostView",
  components: {
    HostFavoriteIcon,
    MetaItem,
    HostDescriptionSection,
    HostMap,
    HostFeatureDrawer,
    BuyersProtectionBox,
    HostHeader,
    ResponsiveHostGallery,
    HostItemDetails,
    HostItemMenu,
    HostSectionHeader
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    itemId: String
  },
  setup() {
    return {
      getDisplayableHostImages,
      getNumberOfStars,
      isHostSuperior
    };
  },
  data() {
    return {
      hasOpenMenu: false
    };
  },
  computed: {
    items() {
      return this.host?.Items || [];
    },
    hostVideoUrl() {
      if (!this.items?.length) return;
      if (
        typeof this.host?.CustomAttributes.VimeoVideoUrl === "string" &&
        this.host.CustomAttributes.VimeoVideoUrl.length
      )
        return this.host?.CustomAttributes.VimeoVideoUrl;
      const itemWithVideo = this.items.find(
        (item) => !!item.CustomAttributes.Custom_Product_VimeoURL?.length
      );
      return itemWithVideo?.CustomAttributes.Custom_Product_VimeoURL;
    },
    itemDetailAttributes() {
      if (!this.selectedItem) return {};
      return {
        title: this.selectedItem?.CustomAttributes?.Custom_Product_Arrangement,
        articleNumber: this.selectedItem?.SystemItemId,
        accommodation: getItemAccommodation(this.selectedItem),
        caterings: getItemCaterings(this.selectedItem),
        holidayServices: getItemHolidayServices(this.selectedItem),
        touristServices: this.selectedItem?.CustomAttributes?.Custom_Product_TouristServices,
        additionalInfo: this.selectedItem?.CustomAttributes?.Custom_Product_ServicesExtra,
        bookability: this.selectedItem?.CustomAttributes?.Custom_Product_Bookability,
        validity: this.selectedItem?.CustomAttributes?.ProductValidity,
        id: this.selectedItem.id
      };
    },
    sortedItems() {
      if (!this.items || this.items.length === 0) [];
      const items = [...this.items];
      return items.sort((a, b) => {
        if (a.Nights > b.Nights && a.CurrentPrice > b.CurrentPrice) return 1;
        return -1;
      });
    },
    availableItems() {
      return this.sortedItems.filter((item) => !getIsItemPurchasable(item));
    },
    selectedItem() {
      if (!this.itemId && this.availableItems.length > 0)
        return this.availableItems[0];
      if (!this.itemId && this.sortedItems.length > 0)
        return this.sortedItems[0];
      return this.sortedItems.find((item) => item.id == this.itemId);
    }
  },
  watch: {
    selectedItem(item) {
      try {
        sendSelectItem(
          {
            ...item,
            CurrentPrice: hasTippsDiscount(this.$session, item)
              ? item.DiscountedPrice
              : item.CurrentPrice
          },
          {
            item_list_id: "host_view_items_menu",
            item_list_name: "Host View Items Menu",
            item_name: this.host.Name
          }
        );
        sendViewItem(item, this.host.Name);
      } catch (e) {
        console.log(e);
      }
    },
    $route(to, from) {
      if (
        to.name !== "Host" ||
        from.name !== "Host" ||
        !this.$refs?.itemDetails?.$el ||
        to?.hash === from?.hash
      )
        return;
      // trigger animation on hash change
      this.$refs.itemDetails.$el.classList.remove("fade-in");
      void this.$refs.itemDetails.$el.offsetWidth;
      this.$refs.itemDetails.$el.classList.add("fade-in");
    }
  },
  mounted() {
    if (this.selectedItem && this.host?.Name)
      sendViewItem(this.selectedItem, this.host.Name);
    if (this.sortedItems && this.host?.Name) {
      sendViewItemList(
        this.sortedItems.map((item) => {
          return {
            ...item,
            CurrentPrice: hasTippsDiscount(this.$session, item)
              ? item.DiscountedPrice
              : item.CurrentPrice
          };
        }),
        {
          item_list_id: "host_view_items_menu",
          item_list_name: "Host View Items Menu",
          item_name: this.host.Name
        }
      );
    }
  }
};
</script>
