import { createHttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, concat } from "apollo-link";
import { onError } from "apollo-link-error";
import cfg from "@/config/config";

/**
 * @callback errorHandler
 * @param { Record<string,any> } args
 * @returns void
 */

/**
 * @typeDef {{
 *   error?:(...args:any[]) => void
 * }} Logger
 */

/**
 * Creates an instance of ApolloClient
 * @param { {
 *  session:Record<string,any>,
 *  onError:errorHandler | undefined,
 *  logger:Logger
 * } } options
 * @returns { InstanceType<typeof ApolloClient> }
 */
export const useApolloClient = (options) => {
  const session = options.session;
  const logger = options.logger;
  if (!session) throw new Error(`session is undefined`);
  const httpLink = createHttpLink({
    uri: cfg.graphQl.uri
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (!session.exists()) session.start();
    const authorization = session.has("token")
      ? `Bearer ${session.get("token")}`
      : null;
    operation.setContext({
      headers: {
        authorization
      }
    });
    return forward(operation);
  });

  const errorLink = onError((args) => {
    if (options.onError) options.onError(args);
    const { graphQLErrors } = args;
    if (!graphQLErrors?.length) return;
    graphQLErrors.forEach(({ message, path, locations, extensions }) => {
      const [location] = locations?.length ? locations : [undefined];
      const pathInfo = path ? ` in resolver ${path.join(" > ")}${location ? ` @ ${location.line}:${location.column}` : ``}` : "";
      const logData = [
        `[GraphQL ${extensions?.code || "Error"}]: ${message}${pathInfo}`
      ];
      const originalError = extensions?.originalError;
      if (originalError) logData.push({ originalError });
      logger.error(...logData);
    });
  });

  // Cache implementation
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    }
  };

  return new ApolloClient({
    link: errorLink.concat(concat(authMiddleware, httpLink)),
    cache: new InMemoryCache(),
    resolvers: {},
    defaultOptions
  });
};
