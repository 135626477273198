<template lang="pug">

</template>
<script>
export default {
  name: "HostFeature",
  props:{
    label:{
      type:String,
      required:true,
    },
    values:{
      type:Array,
      validator(values){
        return values.length > 0
      }
    },
    version: {
      type: String,
      required: false,
    }
  },
  methods:{
    concatChildren(children){
      return children.map((child) => child.label).join(', ');
    }
  }
}
</script>
<style scoped lang="scss">
.host-feature{
  break-inside: avoid-column;
  .icon{
    max-width:30px;
  }
  .value {
    &:not(:last-child){
      margin-right:5px;
      &:after {
        content:',';
      }
    }
  }
}
</style>
