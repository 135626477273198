import gql from "graphql-tag";

export const MarketingPromoContestParticipants = {
  Queries: {
    CurrentMarketingPromoDiscount: gql`
        query CurrentMarketingPromoDiscount($participantId: ID!) {
            currentMarketingPromoDiscount(participantId: $participantId) {
                OrderDiscountCode {
                    id
                    Code
                    Value
                }
            }
        }
    `,
    GetRandomPromoParticipantDiscountCode: gql`query GetRandomPromoParticipantDiscountCode {
        getRandomPromoParticipantDiscountCode {
            Value
            IsPercentage
            Code
            id
        }
    }`,
    MarketingPromoContestPrizeTicket: gql`
        query marketingPromoContestPrizeTicket($marketingPromoContestPrizeId: ID) {
            marketingPromoContestPrizeTicket(marketingPromoContestPrizeId: $marketingPromoContestPrizeId) {
                id
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                MarketingPromoParticipantId
                MarketingPromoParticipantReferrerId
                Ticket
                SignUpThroughSource
                RawRequest
            }
        }`
  },
  Mutations: {
    CreateMarketingPromoParticipantDiscount: gql`
        mutation CreateMarketingPromoParticipantDiscount($participantId: String!, $orderDiscountCodeId: ID!) {
            createMarketingPromoParticipantDiscount(participantId: $participantId, orderDiscountCodeId: $orderDiscountCodeId) {
                id
                MarketingPromoParticipant {
                    id
                    Email
                }
                OrderDiscountCode {
                    id
                    IsPercentage
                    Value
                    Code
                }
            }
        }
    `,
    CreateMarketingPromoParticipant: gql`
        mutation CreateMarketingPromoParticipant($email: String!, $signUpForNewsletter: Boolean, $newsletterSource: String) {
            createMarketingPromoParticipant(email: $email, signUpForNewsletter: $signUpForNewsletter, newsletterSource: $newsletterSource) {
                id
            }
        }
    `,
    CreateMarketingPromoContestParticipant: gql`
        mutation createMarketingPromoContestParticipant($marketingPromoContestId: ID, $email: String, $token: String, $marketingPromoParticipantReferrerGuid: String, $signUpThroughSource: String) {
            createMarketingPromoContestParticipant(marketingPromoContestId: $marketingPromoContestId, email: $email, token: $token, marketingPromoParticipantReferrerGuid: $marketingPromoParticipantReferrerGuid, signUpThroughSource: $signUpThroughSource) {
                IsSuccess
                Message
            }
        }`
  }
};
