<template lang="pug">
.d-flex.align-items-center.price
  font-awesome-icon.mr-1(icon="tag")
  span
    template(v-if="value || (items && items.length > 0)") ab&nbsp;
    b {{price | formatPrice}}
</template>

<script>
export default {
  name: "HostPreviewPrice",
  props:{
    value:Number,
    items:Array
  },
  computed:{
    price(){
      if(this.value) return this.value;
      if(!this.items || this.items.length === 0) return;
      const sortedItemPrices = this.items.map((item) => item.CurrentPrice).sort((a,b) => a - b);
      return sortedItemPrices[0]
    }
  }
}
</script>

<style scoped>

</style>
