<template lang="pug">
  .flip-countdown.position-relative
    .tick(ref="tick")
      div(data-repeat="true" aria-hidden="true")
        span(data-view="flip")
    .labels.align-items-center(:style="`display:grid;grid-template-columns: repeat(${format.length}, minmax(0, 1fr));gap:var(--gap);`")
      .label(v-if="format.includes('d')") Tage
      .label(v-if="format.includes('h')") Stunden
      .label(v-if="format.includes('m')") Minuten
      .label(v-if="format.includes('s')") Sekunden
</template>

<script>
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import { clamp } from "@/lib/helper/math";

export default {
  name: "FlipCountdown",
  props: {
    deadline: {
      type: [Date, String],
      required: true
    },
    format: {
      type: Array,
      default: () => ["d", "h", "m", "s"]
    }
  },
  computed: {
    /**
     * @returns { Date }
     */
    deadlineDate() {
      if (this.deadline instanceof Date) return this.deadline;
      return new Date(this.deadline);
    }
  },
  methods: {
    getRemainingMilliseconds() {
      const now = new Date();
      return this.deadlineDate.getTime() - now.getTime();
    },
    getRemaining() {
      const remainingMilliseconds = this.getRemainingMilliseconds();
      let delta = Math.abs(remainingMilliseconds) / 1000;

      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      const seconds = Math.floor(delta % 60);
      return {
        days: clamp(days, 0, 99).toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0")
      };
    },
    /**
     * @param {Array<"d" | "h" | "m" | "s">} [format]
     */
    getTimeString(format) {
      format = format || ["d", "h", "m", "s"];
      let r = "";
      if (format.includes("d")) r += `${this.getRemaining().days}`;
      if (format.includes("h")) r += `${this.getRemaining().hours}`;
      if (format.includes("m")) r += `${this.getRemaining().minutes}`;
      if (format.includes("s")) r += `${this.getRemaining().seconds}`;
      return r;
    }
  },
  mounted() {
    this._tick = Tick.DOM.create(this.$refs.tick, {
      value: this.getTimeString(this.format)
    });

    let ticker = setInterval(() => {
      this._tick.value = this.getTimeString(this.format);
      if (this.getRemainingMilliseconds() <= 0) {
        clearInterval(ticker);
      }
    }, 1000);
  },
  destroyed() {
    Tick.DOM.destroy(this.$refs.tick);
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/colors";

.flip-countdown {
  display: inline-block;
  user-select: none;
  pointer-events: none;
  width: max-content;
  color: #777;
  --gap: 0.35em;

  .label {
    color: var(--label-color, #333);
    font-size: clamp(14px, 75%, 2rem);
    text-align: center;
  }

  .tick {
    font-size: 1.75em;

    .tick-flip-panel {
      background-color: var(--counter-color, #fff);
    }

    .tick-flip:nth-child(2n):not(:last-child) {
      margin-right: var(--gap);
    }

    .tick-flip-panel {
      color: inherit;
    }
  }

  .tick-credits {
    color: transparent !important;
  }
}
</style>
