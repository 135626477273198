import * as t from "../../store/main.json";
import { asCloudFrontUrl } from "./strings.js";
export default class URLHelper {
  static getCloudFrontPath(path) {
    return new URL(path, t.cloudFront.cloudFrontTouriDatPath);
  }

  static getImageCDNPath(path) {
    return new URL(path, t.cloudFront.cloudFrontImagesPath);
  }

  static getSpacesPath(path) {
    return new URL(path, t.cloudFront.spacesCdnPath);
  }

  static RewriteUrlWithcloudFrontTouriDatPath(url) {
    return asCloudFrontUrl(url);
  }
}
