<template lang="pug">
  .modal-dialog.m-0.w-100
    .modal-content
      .modal-header
        h3.modal-title.text-center.w-100.ml-3 touriDays-Garantie
        .float-right
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body.p-0.m-0
        BuyersProtectionTeaser(size="sm")
        .p-3.pt-4
          BuyersProtectionHeader.text-center(:heading-type="2")
        BuyersProtectionManagement
        .p-3
          BuyersProtectionBenefits
        BuyersProtectionFooter
      .modal-footer
        .row.my-3
          .col-12
            .text-right
              button.button.button-secondary.button-td-grey(@click="$emit('close')") schließen
</template>

<script>
import StaticBuyerProtection from "@/views/static/buyer-protection";
import BuyersProtectionTeaser from "@/views/components/partials/buyersProtection/partials/BuyersProtectionTeaser";
import BuyersProtectionHeader from "@/views/components/partials/buyersProtection/partials/BuyersProtectionHeader";
import BuyersProtectionManagement from "@/views/components/partials/buyersProtection/partials/BuyersProtectionManagement";
import BuyersProtectionBenefits from "@/views/components/partials/buyersProtection/partials/BuyersProtectionBenefits";
import BuyersProtectionFooter from "@/views/components/partials/buyersProtection/partials/BuyersProtectionFooter";
export default {
  name: "BuyerProtectionModal",
  components: {
    BuyersProtectionFooter,
    BuyersProtectionBenefits,
    BuyersProtectionManagement,
    BuyersProtectionHeader,
    BuyersProtectionTeaser,
    StaticBuyerProtection
  }
}
</script>

<style scoped lang="scss">

</style>