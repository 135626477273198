<template lang="pug">
  .text-content-loader.user-select-none
    template(v-for="lineLength in lineLengths")
      .loader.my-1
        template(v-for="n in lineLength") &nbsp;
</template>
<script>
import { getRandomIntInRange } from "@/lib/helper/math";

const rollForNewLine = () => {
  const d = getRandomIntInRange(1, 20);
  return d > 15;
};

export default {
  name: "TextContentLoader",
  props: {
    lines: {
      type: Number,
      required: true
    },
    minLineLength: {
      type: Number,
      default: 95
    },
    randomize: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lineLengths: []
    };
  },
  methods: {},
  created() {
    if (!this.randomize) {
      this.lineLengths = Array(this.lines).fill(100);
      return;
    }

    let i;
    for (i = 0; i < this.lines; i++) {
      if (rollForNewLine()) {
        this.lineLengths.push(undefined);
      } else {
        this.lineLengths.push(getRandomIntInRange(this.minLineLength, 100));
      }
    }
  }
};
</script>
