import MailValidator from "/src/lib/helper/mail_validator.ts"
import PhoneValidator from "/src/lib/helper/phone_validator.ts";
import URLHelper from "/src/lib/helper/url_helper.ts";

const Helper = {
    MailValidator,
    PhoneValidator,
    URLHelper
};

export default Helper;
