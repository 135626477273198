<template lang="pug">
  .easter-signup-form
    .d-flex.align-items-center.justify-content-between(style="font-size: 16px !important;")
      input.d-block.p-2.w-100(type="email" v-model="email" ref="mailInput" placeholder="E-Mail Adresse" @keypress.enter="signUp")
      button.button-primary.button.button-tdays.btn-signup(@click="signUp" :disabled="!email || !email.length" :title="!email || !email.length ? 'Bitte geben Sie zunächst Ihre E-Mail Adresse in das Textfeld ein' : undefined") Teilnehmen
    .small.mt-2(style="opacity:0.65;") Durch die Teilnahme an dieser Aktion willigen Sie ein, dass Ihre E-Mail-Adresse automatisch für unseren Newsletter registriert wird. Sie erhalten regelmäßig Informationen zu unseren Produkten, Aktionen und Neuigkeiten per E-Mail. Falls Sie den Newsletter nicht erhalten möchten, können Sie sich jederzeit abmelden.
    .small.mt-2(style="opacity:0.65;") *Rabatt-Codes sind nur bis einschließlich zum 19.04.2024 einlösbar
</template>
<script>
import { useUserStore } from "@/store/user-store";
import { MarketingPromoContestParticipants } from "@/graphql/MarketingPromoContestParticipants.ts";
import EventBus from "@/event-bus";
import MailValidator from "@/lib/helper/mail_validator.ts";

export default {
  name: "EasterSignupForm",
  emits: ["signedUp"],
  data() {
    return { email: "" };
  },
  setup() {
    return {
      validateEmail: MailValidator.validate
    };
  },
  watch: {
    existingEmail(value) {
      this.email = value;
    }
  },
  computed: {
    existingEmail() {
      const user = useUserStore().user;
      if (user?.LoginEmail) return user?.LoginEmail;
      return undefined;
    }
  },
  methods: {
    async signUp() {
      if (!this.validateEmail(this.email)) {
        await this.$alert("Bitte geben Sie eine gültige E-Mail Adresse an.");
        this.$nextTick(() => {
          this.$refs?.mailInput?.focus();
        });
        return;
      }
      EventBus.$emit("changeLoadingState", true);
      try {
        const id = await this.$apollo.mutate({
          mutation: MarketingPromoContestParticipants.Mutations.CreateMarketingPromoParticipant,
          variables: {
            email: this.email,
            signUpForNewsletter: true,
            newsletterSource: "Ostern 2024"
          }
        }).then((response) => response?.data?.createMarketingPromoParticipant?.id);
        this.$session.set("easterParticipantId", id);
        this.$emit("signedUp");
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    }
  },
  mounted() {
    this.email = this.existingEmail || "";
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/colors";

.easter-signup-form {
  .btn-signup[disabled] {
    background-color: $ttipps-darker !important;
    filter: none !important;
  }
}
</style>
