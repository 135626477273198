<template lang="pug">
  .winner-listing
    .container.p-0
      XmasHeader
        .header-text
          .text-xl.text-uppercase.my-3
            | Diese Preise wurden
            br
            | bereits gewonnen:
          .text-lg Wenn Sie auch zu den glücklichen Gewinnern zählen wollen, dann melden Sie sich jetzt an und gewinnen Sie einen Reisegutschein!
      .px-4
        .prizes.my-4.my-md-2.d-md-flex.text-center.align-items-start.justify-content-between(v-for="day in Object.keys(prizes).toReversed()" style="gap:1.5rem;")
          PrizeDoor.d-inline-block.d-md-block(:number="parseInt(day) + 1" :current-day="currentDay" style="width:140px;" :show-disabled-state="false")
          .textbox.d-inline-block.d-md-block.text-left.w-100.text-white.text-md(:style="`min-height:124px;${day == currentDay ? 'background-color: rgba(26, 78, 23, 0.6);' : 'background-color: rgba(26, 78, 23, 0.4);'}`")
            ul.star-list
              li.my-4(v-for="prize in getItemPrizesForDay(parseInt(day) + 1)") {{prize.Item.Name}}
              li.my-4(v-if="getDiscountPrizeForDay(parseInt(day) + 1)") {{getDiscountPrizeForDay(parseInt(day) + 1).quantity}} x {{getDiscountPrizeForDay(parseInt(day) + 1).prize.Value | formatPrice}} Wertgutschein
            .text-center.text-md-right.p-4.pt-0
              button.button.button-primary.button-tdays(@click="triggerPrizeModal(parseInt(day) + 1)") mehr infos
        .text-center.mt-4.pt-4
          router-link.button.button-primary.button-tdays.w-100(:to="{name:'AdventCalendar'}")
            .text-xl zurück
        //.prizes(v-for="day in Object.keys(prizes)")
          span {{day}}:
          ul(v-for="prize in prizes[day]")
            li(v-if="prize.IsDiscountOnlyPrize")
              template(v-if="prize.Quantity > 1") {{prize.Quantity}} x {{prize.Value | formatPrice}} Wertgutscheine
              template(v-else) {{prize.Value | formatPrice}} Wertgutschein
            li(v-else) {{prize.Item.Name}}
</template>
<script>
import XmasHeader from "@/views/static/adventCalendar/partials/XmasHeader.vue";
import PrizeDoor from "@/views/static/adventCalendar/partials/PrizeDoor.vue";
import { createError } from "@/lib/components/error/createError";

export default {
  name: "AdventCalendarWinners",
  components: { PrizeDoor, XmasHeader },
  inject: ["listWinnerPrizes", "showPrizeModal", "loadPrizesForDay"],
  props: {
    contest: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      prizes: []
    };
  },
  computed: {
    hasAnyWinners() {
      return !!this.contest?.MarketingPromoContestPrizes?.filter((prize) => !!prize.PrizeDrawnAt)?.length;
    },
    currentDay() {
      if (process?.env?.NODE_ENV !== "production") return 10;
      const now = new Date();
      return now.getDate();
    }
  },
  methods: {
    async triggerPrizeModal(day) {
      const prizes = await this.loadPrizesForDay(day);
      await this.showPrizeModal(day, prizes);
    },
    getPrizesForDay(day) {
      const p = this.prizes.filter((prize) => prize.DrawPrizeAt.includes(`2024-12-${day.toString().padStart(2, "0")}`));
      console.log({ day, p });
      return p;
    },
    getItemPrizesForDay(day) {
      const prizes = this.getPrizesForDay(day);
      return prizes.filter((p) => !p.IsDiscountOnlyPrize);
    },
    getDiscountPrizeForDay(day) {
      const prizes = this.getPrizesForDay(day).filter((p) => p.IsDiscountOnlyPrize);
      const [prize] = prizes;
      if (!prize) return;
      return { prize, quantity: prizes.length };
    }
  },
  async mounted() {
    if (!this.hasAnyWinners) throw createError();
    this.prizes = await this.listWinnerPrizes();
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mediaqueries";

.header-text {
  @include md {
    margin-top: 14.5rem;
    margin-bottom: 7.75rem;
  }
}

.prizes {
  .textbox {
    backdrop-filter: blur(3px);
    margin-top: -2rem;
    padding-top: 1.5rem;
    @include md {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>
