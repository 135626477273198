<template lang="pug">
.small-host-item
  header.small Zurzeit leider nicht Verfügbar
  .d-flex.align-items-center.justify-content-stretch
    AddToShoppingBasketButton.button.button-primary.button-tdays.px-3(title="In den Warenkorb" :item-id="item.id" :is-tipps-item="hasTippsDiscount" :disabled="!purchasable")
      font-awesome-icon(icon="shopping-cart")
    div.w-100.ml-2.d-flex.align-items-center.justify-content-stretch
      .prices.text-left
        .discount-percentage(v-if="discountPercentage && isFinite(discountPercentage)")
        .regular-price.line-through.d-inline-block(v-if="regularPrice") {{regularPrice | formatPrice}}
        .current-price.h2.m-0(v-if="currentPrice") {{currentPrice | formatPrice}}
  //TippsDiscount(v-if="true")
</template>
<script>
import AddToShoppingBasketButton from "@/views/components/partials/buttons/addToShoppingBasket/AddToShoppingBasketButton.vue";
import TippsDiscount from "@/views/host/partials/tippsDiscount/TippsDiscount.vue";

export default {
  name: "SmallHostItem",
  components: { TippsDiscount, AddToShoppingBasketButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
    hasTippsDiscountApplied: Boolean,
    hasTippsDiscount: Boolean,
    purchasable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    regularPrice() {
      return this.item.RegularPrice || 0;
    },
    liveShoppingPrice() {
      return this.item.LiveShoppingPrice || 0;
    },
    currentPrice() {
      if (this.hasTippsDiscountApplied) return this.liveShoppingPrice();
      return this.item.CurrentPrice || 0;
    },
    discountPercentage() {
      if (this.hasTippsDiscountApplied)
        return (
          ((this.regularPrice - this.liveShoppingPrice) / this.regularPrice) *
          100
        );
      return (
        ((this.regularPrice - this.currentPrice) / this.regularPrice) * 100
      );
    },
  },
};
</script>
<style scoped lang="scss">
.small-host-item {
  pointer-events: auto !important;
  .regular-price {
    position: relative;
    &:after {
      content: "¹";
      position: absolute;
      right: -6px;
    }
  }
}
</style>
