import gql from "graphql-tag";

export const FavoriteHost = {
  Mutations: {
    FavoriteHost: gql`
      mutation favoriteHost($hostId: ID) {
        favoriteHost(hostId: $hostId) {
          IsSuccess
          Message
        }
      }
    `,
    UnfavoriteHost: gql`
      mutation unfavoriteHost($hostId: ID) {
        unfavoriteHost(hostId: $hostId) {
          IsSuccess
          Message
        }
      }
    `,
  },
};
