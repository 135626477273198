<template lang="pug">
  .modal-dialog.m-0.w-100
    .modal-content
      .modal-header
        h3.modal-title.w-100.ml-3 Zahlungsarten
        .float-right
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body.p-0.m-0
        .p-3
          section.py-3.klarna.border-bottom
            .text-left.px-3
              img.payment-logo(src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.png")
            p.p-3.
              Klarna ist einer der führenden Zahlungsanbieter in Europa und gibt Online-Shoppern die Möglichkeit, per Rechnung, Ratenzahlung oder sofort beim Bestellabschluss per Lastschrift, Sofortüberweisung oder Visa- und Mastercard zu bezahlen – und bietet dabei ein sicheres und einfaches Checkout-Erlebnis.
          section.py-3.paypal.border-bottom
            .text-left.px-3
              img.payment-logo(src="https://s3-cloud.td-cdn.de/Images/makaira/foreign-logos/paypal/de-pp-logo-150px.png")
            p.p-3.
              PayPal ist ein weltweit benutztes System und für den Konsumenten gratis. Sobald Sie ein PayPal-Konto haben, bezahlen Sie nur noch mit Ihrer E-Mail-Adresse und Ihrem Passwort.
          section.py-3.paypal.border-bottom
            .text-left.px-3
              img.payment-logo(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/foreign-logos/amazon_pay.svg")
            p.p-3.
              Amazon Pay ist ein Service für Amazon-Kunden. Deine Transaktionen sind sicher und geschützt und alle Details lassen sich in deinem Amazon-Konto nachverfolgen.
          section.py-3.vorkasse
            .text-left.px-3
                img.payment-logo(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/foreign-logos/transfer.png" style="max-height:75px;margin:-7px;")
            p.p-3.m-0
              |Bitte verwenden Sie als Verwendungszweck Ihre Kundennummer und Ihre Auftragsnummer!
              br
              br
              b Inhaber:&nbsp;
              | touriDat GmbH & Co.KG
              br
              b Kto-Nr.:&nbsp;
              | 5547126402
              br
              b BLZ:&nbsp;
              |46062817
              br
              b Institut:&nbsp;
              |Volksbank Bigge-Lenne eG
              br
              b BIC/SWIFT:&nbsp;
              |GENODEM1SMA
              br
              b IBAN:&nbsp;
              |DE10 460628175547126402
      .modal-footer
        .row.my-3
          .col-12
            .text-right
              button.button.button-secondary.button-td-grey(@click="$emit('close')") schließen
</template>

<script>
export default {
  name: "PaymentTypesModal",
};
</script>

<style scoped lang="scss">
.payment-logo {
  max-width: 150px;
  max-height: 60px;
}
</style>
