<template lang="pug">
  .host-selection-voucher(:class="{'static-voucher':selectionVoucher && selectionVoucher.IsStaticSelectionVoucher}")
    LoadingSpinnerOverlay(v-if="isLoading")
    .container
      BuyersProtectionBox
    .container(v-if="host")
      HostHeader.py-4(:title="host.Name" :sub-title="host.CityRegion")
        template(v-slot:afterTitle)
          HostFavoriteIcon(:host="host" v-if="host")
      .pb-4
        .voucher-hero.loader
          img.img-fluid.w-100(v-if="selectionVoucher.VoucherImage" :src="selectionVoucher.VoucherImage")
    HostSectionHeader.item-h1.mb-0.position-relative(style="z-index:1") Gutscheindetails
    .container
      .row.position-relative.align-items-stretch
        .col-lg-7.col-xl-8.pb-lg-0.details-column
          template(v-if="!selectionVoucher.IsStaticSelectionVoucher")
            .article-number.txt-td-grey-basic.mt-2(v-if="voucherItem.SystemItemId")
              span.user-select-none Art.-Nr.&nbsp;
              | {{voucherItem.SystemItemId}}
            .headline.item-h1.mt-4.mb-2(v-if="selectionVoucher.Headline") {{selectionVoucher.Headline}}
            .subline.item-h2.mb-4.mt-0.txt-td-grey-basic(v-if="selectionVoucher.Subline") {{selectionVoucher.Subline}}
            .mt-4(v-if="selectionVoucher.ChecklistElements && selectionVoucher.ChecklistElements.length > 0")
              b Folgende Leistungen sind inklusive:
              ul.pl-3
                li(v-for="point in selectionVoucher.ChecklistElements") {{point}}
            .mt-4(v-if="selectionVoucher.Description") {{selectionVoucher.Description}}
          HostItemDetails.mt-2(v-else v-bind="itemDetailAttributes")
        .col-lg-5.col-xl-4.menu-column
          HostItemMenu(:host="host" :items="listOfItems" :selected-item-id="selectedItem.id" :is-open="hasOpenMenu" @toggle="hasOpenMenu = !hasOpenMenu" :group-items="selectionVoucher.IsStaticSelectionVoucher" :show-item-footer="false" :show-discount-info="selectionVoucher.IsStaticSelectionVoucher" :show-accommodations="false")
            template(v-slot:customFeature="{item}")
              .feature.d-flex.align-items-center(v-if="!selectionVoucher.IsStaticSelectionVoucher")
                font-awesome-icon.icon.px-2(icon="home")
                .value {{numberOfHosts}} Hotels
              template(v-else-if="selectionVoucher.IsStaticSelectionVoucher && getSimpleItemAccommodation(item)")
                font-awesome-icon.icon(icon="bed")
                .value {{getSimpleItemAccommodation(item)}}
              template(v-else) &nbsp;
    HostSectionHeader.item-h2.mb-0 Übersicht der Hotels
    .container.pt-4
      .hosts.row
        .col-lg-4(v-for="host in containedHosts")
          .mb-4
            HostPreviewBox(:name="host.Name" :city-region="host.CityRegion" :stars="getNumberOfStars(host)" :is-superior="isHostSuperior(host)")
              template(v-slot:gallery)
                PreviewHostGallery(:images="getDisplayableHostImages(host)" v-if="!selectionVoucher.IsStaticSelectionVoucher")
                .thumbnail(:style="`background-image:url('${host.Images[0].RemoteURL}');}`" v-else)
              template(v-slot:footer)
                template(v-if="host.Item")
                  hr.m-0.p-0
                  .text-center.txt-td-grey-basic.py-1
                    .d-inline-flex.align-items-center
                      font-awesome-icon.mr-1(icon="moon")
                      div
                        | {{host.Item.Nights}}&nbsp;
                        template(v-if="host.Item.Nights === 1") Nacht
                        template(v-else) Nächte
            .text-center.pt-2(v-if="selectionVoucher && !selectionVoucher.IsStaticSelectionVoucher")
              button.button.button-primary.button-tdays(@click="showHostInfoModal(host)") Details
    hr.mb-4.mt-0
    HostMap(:hosts="mapHosts")
</template>

<script>
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay.vue";
import BuyersProtectionBox from "@/views/components/partials/buyersProtection/BuyersProtectionBox.vue";
import HostHeader from "@/views/components/host/layout/HostHeader.vue";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon.vue";
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader.vue";
import HostItemDetails from "@/views/components/host/layout/HostItemDetails.vue";
import TextContentLoader from "@/views/components/partials/contentLoader/TextContentLoader.vue";
import HostItemMenu from "@/views/components/host/layout/HostItemMenu.vue";
import {
  getItemAccommodation,
  getItemCaterings,
  getItemHolidayServices,
  getSimpleItemAccommodation, orderItemsAscendingByPrice
} from "@/lib/helper/items";
import { getNumberOfStars, isHostSuperior, getDisplayableHostImages } from "@/lib/helper/host";
import HostPreviewBox from "@/views/components/hostPreview/partials/HostPreviewBox.vue";
import PreviewHostGallery from "@/views/components/host/gallery/PreviewHostGallery.vue";
import HostMap from "@/views/components/partials/hostMap/HostMap.vue";
import SelectionVoucherItemInfoModal
  from "@/views/components/modals/selectionVoucherItemInfo/SelectionVoucherItemInfoModal.vue";
import { Hosts } from "@/graphql/Hosts.ts";
import { definedObject, objectWithout } from "@/lib/helper/object";
import { parseNumber } from "@/lib/helper/math";

export default {
  name: "HostSelectionVoucherView",
  components: {
    HostMap,
    PreviewHostGallery,
    HostPreviewBox,
    HostItemMenu,
    TextContentLoader,
    HostItemDetails,
    HostSectionHeader,
    HostFavoriteIcon,
    HostHeader,
    BuyersProtectionBox,
    LoadingSpinnerOverlay
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    itemId: String
  },
  setup() {
    return { getNumberOfStars, isHostSuperior, getSimpleItemAccommodation };
  },
  data() {
    return {
      isLoading: false,
      hasOpenMenu: false
    };
  },
  computed: {
    listOfItems() {
      if (this.selectionVoucher.IsStaticSelectionVoucher) return orderItemsAscendingByPrice(this.host?.Items) || [];
      return [this.voucherItem];
    },
    selectedItem() {
      return this.listOfItems.find((itm) => itm.id == this.itemId);
    },
    itemDetailAttributes() {
      if (!this.selectedItem) return {};
      return {
        title: this.selectedItem.CustomAttributes?.Custom_Product_Arrangement,
        articleNumber: this.selectedItem.SystemItemId,
        accommodation: getItemAccommodation(this.selectedItem),
        caterings: getItemCaterings(this.selectedItem),
        holidayServices: getItemHolidayServices(this.selectedItem),
        touristServices: this.selectedItem.CustomAttributes?.Custom_Product_TouristServices,
        additionalInfo: this.selectedItem.CustomAttributes?.Custom_Product_ServicesExtra,
        bookability: this.selectedItem.CustomAttributes?.Custom_Product_Bookability
      };
    },
    voucherItem() {
      const [item] = this.host?.Items || [undefined];
      return item;
    },
    selectionVoucher() {
      return this.host?.SelectionVoucher;
    },
    containedItems() {
      if (this.selectionVoucher?.IsStaticSelectionVoucher) {
        if (!this.selectionVoucher?.JsonItems?.length) return [];
        return this.selectionVoucher.JsonItems.map((jsonItem) => {
          return {
            Host: {
              Name: jsonItem.Name,
              CityRegion: jsonItem.City,
              Images: [{ RemoteURL: jsonItem.ImageUrl }],
              Latitude: jsonItem?.Latitude || undefined,
              Longitude: jsonItem?.Longitude || undefined
            }
          };
        });
      }
      return this.selectionVoucher?.Items || [];
    },
    containedHosts() {
      const hosts = this.containedItems.filter((item) => !!item.Host).map((item) => {
        const r = {
          ...definedObject(item.Host)
        };
        if (!this.selectionVoucher.IsStaticSelectionVoucher) {
          r.Item = objectWithout(item, ["Host"]);
        }

        return r;
      });
      return [...hosts].sort((a, b) => {
        const [valueA, valueB] = [a, b].map((host) => {
          const value = this.getNumberOfStars(host);
          return this.isHostSuperior(host) ? value + 1 : value;
        });

        return valueB - valueA;
      });
    },
    mapHosts() {
      return this.containedHosts.map((h) => {
        if (h.Latitude) h.Latitude = parseNumber(h.Latitude);
        if (h.Longitude) h.Longitude = parseNumber(h.Longitude);
        return h;
      }).filter((h) => h.Latitude && h.Longitude);
    },
    numberOfHosts() {
      return this.containedHosts?.length || 0;
    }
  },
  methods: {
    getDisplayableHostImages(host) {
      const images = this.selectionVoucher.IsStaticSelectionVoucher ? host.Images : getDisplayableHostImages(host);
      return images.map((image) => {
        return { url: image.RemoteURL };
      });
    },
    async loadHostDetails(id) {
      this.isLoading = true;
      try {
        return await this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: { id }
        }).then((response) => {
          return response?.data?.host;
        });
      } finally {
        this.isLoading = false;
      }

    },
    async showHostInfoModal(host) {
      const fullHost = await this.loadHostDetails(host.id);
      this.$modal.show(
        SelectionVoucherItemInfoModal,
        {
          item: host.Item,
          host: fullHost
        },
        {
          height: "auto",
          classes: ["rounded-0", "sv-info-modal", "modalMaxHeight"]
        }
      );
    }
  }
};
/*
import HostHeader from "@/views/components/host/layout/HostHeader";
import BuyersProtectionBox from "@/views/components/partials/buyersProtection/BuyersProtectionBox";
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader";
import HostItemMenuItem from "@/views/components/host/layout/partials/HostItemMenuItem";
import AddToShoppingBasketButton
  from "@/views/components/partials/buttons/addToShoppingBasket/AddToShoppingBasketButton";
import HostViewSidebar from "@/views/components/host/layout/partials/HostViewSidebar";
import TelephoneOrderButton from "@/views/components/partials/buttons/telephoneOrder/TelephoneOrderButton";
import { showHotlineModal } from "@/lib/components/hotlineModal";
import SmallHostGallery from "@/views/components/host/gallery/SmallHostGallery";
import { getDisplayableHostImages } from "@/lib/helper/host";
import HostPreviewStars from "@/views/components/hostPreview/HostPreviewStars";
import HostMap from "@/views/components/partials/hostMap/HostMap";
import SelectionVoucherItemInfoModal
  from "@/views/components/modals/selectionVoucherItemInfo/SelectionVoucherItemInfoModal";
import { SelectionVouchers } from "@/graphql/SelectionVouchers.ts";
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay";
import { isNumeric } from "@/lib/helper/math";
import { sendViewItem } from "@/lib/helper/gtm";
import { Items } from "@/graphql/Items.ts";
import { Hosts } from "@/graphql/Hosts.ts";
import HostPreviewBox from "@/views/components/hostPreview/partials/HostPreviewBox";
import PreviewHostGallery from "@/views/components/host/gallery/PreviewHostGallery";
import HostItemMenu from "@/views/components/host/layout/HostItemMenu";
import MetaItem from "@/views/components/meta/MetaItem";
import TextContentLoader from "@/views/components/partials/contentLoader/TextContentLoader";
import {
  getIsItemOutOfStock,
  getItemAccommodation,
  getItemCaterings,
  getItemHolidayServices,
  getSimpleItemAccommodation
} from "@/lib/helper/items";
import HostItemDetails from "@/views/components/host/layout/HostItemDetails";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon.vue";
export default {
  name: "HostSelectionVoucherView",
  components: {
    HostFavoriteIcon,
    HostItemDetails,
    TextContentLoader,
    HostItemMenu,
    MetaItem,
    LoadingSpinnerOverlay,
    HostMap,
    HostPreviewStars,
    SmallHostGallery,
    HostViewSidebar,
    TelephoneOrderButton,
    AddToShoppingBasketButton,
    HostItemMenuItem,
    HostSectionHeader,
    BuyersProtectionBox,
    HostHeader,
    HostPreviewBox,
    PreviewHostGallery
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    itemId: String
  },
  setup() {
    return { getSimpleItemAccommodation };
  },
  data() {
    return {
      hasOpenMenu: false,
      isLoading: true,
      selectionVoucher: undefined,
      itemSelectionVoucher: undefined,
      selectionVoucherItems: undefined,
      voucherHosts: undefined,
      redeemableSelectionVoucherItems: [] // this will not be filled on static selection vouchers
    };
  },
  async mounted() {
    try {
      await this.loadSelectionVoucher();
      if (this.selectedItem && this.host?.Name)
        sendViewItem(this.selectedItem, this.host.Name);
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    async selectedItem() {
      try {
        await this.loadItemSelectionVoucher();
        if (this.selectedItem && this.host?.Name)
          sendViewItem(this.selectedItem, this.host.Name);
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    items() {
      return this.host?.Items || [];
    },
    itemDetailAttributes() {
      try {
        if (!this.selectedItem) return {};
        return {
          title: this.selectedItem.Custom_Product_Arrangement,
          articleNumber: this.selectedItem.SystemItemId,
          accommodation: getItemAccommodation(this.selectedItem),
          caterings: getItemCaterings(this.selectedItem),
          holidayServices: getItemHolidayServices(this.selectedItem),
          touristServices: this.selectedItem.Custom_Product_TouristServices,
          additionalInfo: this.selectedItem.Custom_Product_ServicesExtra,
          bookability: this.selectedItem.Custom_Product_Bookability
        };
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    headline() {
      try {
        if (!this.itemSelectionVoucher) return;
        return this.itemSelectionVoucher.Headline;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    subline() {
      try {
        if (
          !this.itemSelectionVoucher ||
          !this.voucherHosts ||
          this.voucherHosts.length === 0
        )
          return undefined;
        if (this.itemSelectionVoucher.IsStaticSelectionVoucher) {
          return this.itemSelectionVoucher.Subline.replaceAll(
            "itemLength",
            this.voucherHosts.length
          );
        }
        if (
          (!this.itemSelectionVoucher.IsStaticSelectionVoucher &&
            this.itemSelectionVoucher.Subline &&
            Array.isArray(this.itemSelectionVoucher)) ||
          this.itemSelectionVoucher.Subline
        )
          return this.itemSelectionVoucher.Subline.replaceAll(
            "itemLength",
            this.voucherHosts.length
          );
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    sortedItems() {
      try {
        if (!this.availableItems || this.availableItems.length === 0) return [];
        const items = [...this.availableItems];
        return items.sort((a, b) => {
          if (a.Nights > b.Nights && a.CurrentPrice > b.CurrentPrice) return 1;
          return -1;
        });
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    availableItems() {
      try {
        if (!this.selectionVoucherItems) return;
        return this.selectionVoucherItems.filter(
          (item) => !getIsItemOutOfStock(item)
        );
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    selectedItem() {
      try {
        if (!this.selectionVoucherItems) return;
        if (!this.itemId && this.selectionVoucherItems.length > 0)
          return this.sortedItems[0];
        return (
          this.sortedItems.find((item) => item.id == this.itemId) ||
          // fallback to show the first item in the list if there is no item matching this.itemId
          this.sortedItems[0]
        );
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    voucherImage() {
      try {
        return this.selectionVoucher?.VoucherImage;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    voucherDescription() {
      try {
        if (!this.selectionVoucher?.Description) return;
        if (!this.selectionVoucher.Description.includes("itemLength"))
          return this.selectionVoucher.Description;
        return this.selectionVoucher.Description.replaceAll(
          "itemLength",
          this.getNumberOfHostsForItem(this.selectedItem)
        );
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    mapDisplayableVoucherHosts() {
      try {
        if (!this.voucherHosts || this.voucherHosts.length === 0) return [];
        return this.voucherHosts.filter((host) => {
          return (
            "Latitude" in host &&
            "Longitude" in host &&
            isNumeric(host.Latitude) &&
            isNumeric(host.Longitude)
          );
        });
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    checklist() {
      try {
        if (
          !this.itemSelectionVoucher?.Checklist ||
          this.itemSelectionVoucher?.Checklist.trim().length === 0
        )
          return [];
        let checklistString = this.itemSelectionVoucher?.Checklist;
        if (checklistString.includes("itemLength"))
          checklistString = checklistString.replaceAll(
            "itemLength",
            this.getNumberOfHostsForItem(this.selectedItem)
          );
        const points = checklistString.split(/\r?\n/);
        if (!points || points.length === 0) return [];
        return points.filter((point) => point.trim().length > 0);
      } catch (e) {
        console.log(e);
        return undefined;
      }
    }
  },
  methods: {
    getDisplayableHostImages(host) {
      try {
        if (host?.Images && host.Images.length > 0) {
          const images = getDisplayableHostImages(host);
          if (!images) return;
          return images.map((image) => {
            return { url: image.RemoteURL };
          });
        }
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    async loadRedeemableVoucherItems() {
      try {
        this.redeemableSelectionVoucherItems = [];
        const redeemableSelectionVoucherItems = await new Promise(
          (resolve, reject) => {
            this.$apollo
              .query({
                query: Items.Queries.RedeemableItemsBySelectionVoucherId,
                variables: {
                  selectionVoucherId: this.selectionVoucher.id
                }
              })
              .then((response) =>
                resolve(response?.data?.redeemableItemsBySelectionVoucherId)
              )
              .catch((e) => {
                this.$log.error(e);
                reject();
              });
          }
        );
        if (!redeemableSelectionVoucherItems) {
          return;
        }
        this.redeemableSelectionVoucherItems = redeemableSelectionVoucherItems;
      } catch (e) {
        console.log(e);
      }
    },
    async loadSelectionVoucher() {
      try {
        this.selectionVoucher = undefined;
        this.isLoading = true;
        // load the selectionVoucher for the currently selected item
        const selectionVoucher = await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: SelectionVouchers.Queries.SelectionVoucherByHostId,
              variables: {
                hostId: this.host.id
              }
            })
            .then((response) =>
              resolve(response?.data?.selectionVoucherByHostId)
            )
            .catch((e) => {
              this.$log.error(e);
              reject();
            });
        });
        this.isLoading = false;
        if (!selectionVoucher) {
          return;
        }
        this.selectionVoucher = selectionVoucher;
        await this.loadSelectionVoucherItems();
        await this.loadSelectionVoucherHosts();
        if (this.selectionVoucher.IsStaticSelectionVoucher) return;
        await this.loadRedeemableVoucherItems();
      } catch (e) {
        console.log(e);
      }
    },
    async loadSelectionVoucherHosts() {
      try {
        if (!this.selectionVoucher?.id) return;
        if (this.selectionVoucher.IsStaticSelectionVoucher) {
          this.voucherHosts =
            this.selectionVoucher.JsonItems?.map((jsonItem) => {
              return {
                Name: jsonItem.Name,
                Images: [jsonItem.ImageUrl],
                CityRegion: jsonItem.City,
                Latitude: jsonItem?.Latitude,
                Longitude: jsonItem?.Longitude
              };
            }) || [];
        } else {
          this.voucherHosts = await this.$apollo
            .query({
              query: Hosts.Queries.HostsBySelectionVoucherId,
              variables: {
                selectionVoucherId: this.selectionVoucher.id
              }
            })
            .then((response) => response?.data?.hostsBySelectionVoucherId);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadSelectionVoucherItems() {
      try {
        if (!this.selectionVoucher?.id) return;
        const selectionVoucherItems = await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: Items.Queries.ItemsBySelectionVoucherId,
              variables: {
                selectionVoucherId: this.selectionVoucher.id
              }
            })
            .then((response) =>
              resolve(response?.data?.itemsBySelectionVoucherId)
            )
            .catch((e) => {
              this.$log.error(e);
              reject();
            });
        });
        if (!selectionVoucherItems) {
          return;
        }
        this.selectionVoucherItems = selectionVoucherItems;
      } catch (e) {
        console.log(e);
      }
    },
    async loadItemSelectionVoucher() {
      try {
        if (!this.selectedItem?.id) return;
        this.itemSelectionVoucher = undefined;
        // load the selectionVoucher for the currently selected item
        const itemSelectionVoucher = await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: SelectionVouchers.Queries.SelectionVoucherByItemId,
              variables: {
                itemId: this.selectedItem.id
              }
            })
            .then((response) =>
              resolve(response?.data?.selectionVoucherByItemId)
            )
            .catch((e) => {
              this.$log.error(e);
              reject();
            });
        });
        if (!itemSelectionVoucher) {
          return;
        }
        this.itemSelectionVoucher = itemSelectionVoucher;
      } catch (e) {
        console.log(e);
      }
    },
    getNumberOfHostsForItem(item) {
      try {
        if (!this.itemSelectionVoucher || !this.itemSelectionVoucher || !item)
          return 0;
        const voucherHosts = this.voucherHosts;
        if (!voucherHosts) {
          return parseInt(item.Custom_Product_Specials);
        }
        return voucherHosts.length;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    showHotlineModal() {
      try {
        const modal = showHotlineModal.bind(this);
        modal({
          item: this.selectedItem
        });
      } catch (e) {
        console.log(e);
      }
    },
    showHostInfoModal(host) {
      try {
        if (
          !host.id ||
          !this.redeemableSelectionVoucherItems ||
          this.redeemableSelectionVoucherItems.length === 0
        )
          return;
        const item = this.redeemableSelectionVoucherItems.find(
          (itm) => itm?.HostId == host.id
        );
        if (!item) return;
        try {
          this.$modal.show(
            SelectionVoucherItemInfoModal,
            {
              item,
              host
            },
            {
              height: "auto",
              classes: ["rounded-0", "sv-info-modal", "modalMaxHeight"]
            }
          );
        } catch (e) {
          console.log(e);
          this.$alert(e.message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getNumberOfStars(host) {
      try {
        if (!host.Stars) return;
        return parseInt(host.Stars);
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    getIsSuperior(host) {
      try {
        if (!host.Stars || typeof host.Stars !== "string") return;
        return host.Stars.toLowerCase().includes("s");
      } catch (e) {
        console.log(e);
        return undefined;
      }
    }
  }
};

 */
</script>
<style lang="scss">
.host-selection-voucher {
  &:not(.static-voucher) {
    .host-item-menu-item {
      .prices {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.voucher-hero {
  aspect-ratio: 2.65 / 1;
}

.static-voucher {
  .voucher-hero {
    aspect-ratio: 2.86 / 1;
  }
}

.hosts {
  .thumbnail {
    aspect-ratio: 4 / 3;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.headline,
.subline {
  font-weight: 500;
  line-height: 1.2;
}
</style>
