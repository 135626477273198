<template lang="pug">
.host-stars(:title="title")
  span.mr-1(v-if="stars > 0 || isSuperior")
    font-awesome-icon(v-for="star in stars" :key="star" icon="star")
    font-awesome-icon.superior(icon="s" v-if="isSuperior")
</template>
<script>
export default {
  name: "HostPreviewStars",
  props:{
    stars:Number,
    isSuperior:Boolean
  },
  computed:{
    title(){
      if(!this.stars)return;
      return `${this.stars} ${(this.stars > 1) ? 'Sterne' : 'Stern'}${this.isSuperior ? ', Superior' : ''}`;
    }
  }
}
</script>
<style scoped lang="scss">

</style>