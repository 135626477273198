import gql from "graphql-tag";

export const Utils = {
  Queries: {
    getNewsletter: gql`
        query GetNewsletter {
            getNewsletter {
                Data
            }
        }
    `
  },
  Mutations: {
    signUpForNewsletter: gql`
        mutation SignUpNewsletter($data: NlSignUpData!) {
            signUpNewsletter(data: $data) {
                IsSuccess
                Message
                StringData
            }
        }
    `,
    createMarketingNewsletterDiscountCode: gql`
        #graphql
        mutation CreateMarketingNewsletterDiscountCode($data: NlSignUpData!) {
            createMarketingNewsletterDiscountCode(data: $data)
        }
    `
  }
};
