<template lang="pug">
button.phone-order-button(@click="$emit('click',$event)" title="telefonisch bestellen" :class="`button-${buttonClass}`")
  slot
</template>

<script>
export default {
  name: "TelephoneOrderButton",
  props: {
    buttonClass: {
      type: String,
      default: "tdays",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.button[disabled]:hover {
  color: var(--theme-color-lighter, $tdays-lighter) !important;
}
</style>
