import config from "@/config/config";
import { sum } from "@/lib/helper/math";

export const getAffiliation = () => {
  return `touriDat ${config?.runMode === "app" ? "App" : "WebShop"}`;
};

const getValue = (item, multiplier = 1) => {
  if (!item?.CurrentPrice) return;
  return item.CurrentPrice * multiplier;
};
export const sendViewItemList = (items, options) => {
  try {
    options = options || {};
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    const eventData = {
      event: "view_item_list",
      ecommerce: {
        item_list_id: options?.item_list_id,
        item_list_name: options?.item_list_name,
        items: items.map((item) => {
          const args = {
            ...options,
            item_id: item.SystemItemId,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            price: item.RegularPrice,
            quantity: 1,
            affiliation: getAffiliation()
          };
          if (item?.Host?.Name) args.item_name = item.Host.Name;
          return args;
        })
      }
    };
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch (e) {
    this.console.log(e);
  }
};
export const sendSelectItem = (item, options) => {
  try {
    options = options || {};
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }

    const args = {
      ...options,
      item_id: item.SystemItemId,
      discount:
        item.Discount ||
        item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
      item_variant: item.Name,
      price: item.RegularPrice,
      quantity: 1,
      affiliation: getAffiliation()
    };
    if (item?.Host?.Name) args.item_name = item.Host.Name;

    const eventData = {
      event: "select_item",
      ecommerce: {
        item_list_id: options?.item_list_id,
        item_list_name: options?.item_list_id,
        items: [args]
      }
    };
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch (e) {
    this.console.log(e);
  }
};
export const sendViewItem = (item, hostName) => {
  try {
    if (!item) {
      return;
    }
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "view_item",
      ecommerce: {
        value: getValue(item),
        currency: "EUR",
        items: [
          {
            item_id: item.SystemItemId,
            item_name: item.CustomAttributes?.Custom_Hotel_Name || hostName,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name || item.CustomAttributes?.Custom_Product_Arrangement,
            price: item.RegularPrice,
            quantity: 1,
            affiliation: getAffiliation()
          }
        ]
      }
    });
    window.dataLayer?.push({
      currentItem: item
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendAddToCart = (item) => {
  try {
    if (!item || !item.SystemItemId) {
      return;
    }
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "add_to_cart",
      ecommerce: {
        value: getValue(item, item.Quantity || 1),
        currency: "EUR",
        items: [
          {
            item_id: item.SystemItemId,
            item_name: item.HostName,
            quantity: item.Quantity || 1,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            price: item.RegularPrice,
            affiliation: getAffiliation()
          }
        ]
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendRemoveFromCart = (item) => {
  try {
    if (!window.dataLayer || !item || !item.SystemItemId) return;
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "remove_from_cart",
      ecommerce: {
        value: getValue(item, item.Quantity || 1),
        currency: "EUR",
        items: [
          {
            item_id: item.SystemItemId,
            quantity: item.Quantity || 1,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            item_name: item.HostName,
            price: item.RegularPrice,
            affiliation: getAffiliation()
          }
        ]
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendViewCart = (cartItems, totalPrice) => {
  try {
    if (!cartItems?.length) {
      return;
    }
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer?.push({ ecommerce: null });

    let gaItems = [];
    cartItems.forEach((i) =>
      gaItems.push({
        item_id: i.SystemItemId,
        item_name: i.HostName,
        item_variant: i.Name,
        price: i.RegularPrice,
        quantity: i.Quantity,
        discount:
          i.Discount || i.RegularPrice - (i.CurrentPrice || i.DiscountedPrice),
        affiliation: getAffiliation()
      })
    );
    window.dataLayer?.push({
      event: "view_cart",
      ecommerce: {
        value: totalPrice || 0,
        currency: "EUR",
        items: gaItems
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendAddPaymentInfo = (items, totalPrice, paymentType) => {
  try {
    if (!items?.length || !paymentType) return;
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: "EUR",
        value: totalPrice || 0,
        payment_type: paymentType,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
            item_variant: item.Name,
            item_name: item.HostName,
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendAddShippingInfo = (items, shippingCost, type) => {
  try {
    if (!items?.length || !type) return;
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: "EUR",
        value: shippingCost,
        shipping_tier: type,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            item_variant: item.Name,
            item_name: item.HostName,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendBeginCheckout = (items, totalPrice, coupon) => {
  try {
    if (!items?.length) return;
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    const ecommerceData = {
      currency: "EUR",
      value: totalPrice || 0,
      items: items.map((item) => {
        const data = {
          item_id: item.SystemItemId,
          item_variant: item.Name,
          affiliation: getAffiliation(),
          discount:
            item.Discount ||
            item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
          item_list_id: "shopping_basket_items",
          item_list_name: "Shopping Basket Items",
          price: item.RegularPrice,
          quantity: item.Quantity || 1
        };
        if (item.HostName) data.item_name = item.HostName;
        return data;
      })
    };
    if (coupon) ecommerceData.coupon = coupon;
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: ecommerceData
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendSearch = (term) => {
  if (!term || !term?.trim()?.length) return;
  if (!window?.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "search",
    search_term: term
  });
};

export const sendLogin = () => {
  if (!window?.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "login",
    method: "login form"
  });
};

export const sendSignUp = () => {
  if (!window?.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "sign_up",
    method: "registration form"
  });
};

export const sendAddToWishlist = (items) => {
  try {
    if (!items?.length) return;
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_wishlist",
      ecommerce: {
        currency: "EUR",
        value: sum(items.map((item) => getValue(item))),
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "favorite_host_items",
            item_list_name: "Favorite Host items",
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendVisitFromReferrer = (name) => {
  try {
    if (typeof name !== "string" || !name.trim().length) name = "WebShop";
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({
      event: "visit_from_referrer",
      referrer: name
    });
  } catch (e) {
    console.log(e);
  }
};
