<template lang="pug">
  font-awesome-icon(icon="at")
</template>
<script>

export default {
  name: "AtIcon"
};
</script>
<style scoped lang="scss">

</style>
