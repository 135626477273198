<template lang="pug">
.bp-header
  p.m-0: b Bei allen Käufen seit Dezember 2015 inklusive!
    div(:class="`h${headingType}`") Ihr Urlaub in den besten Händen - 100%iger Schutz vor finanziellen Verlusten!
  p.justify Damit Ihr Urlaub immer ein unvergesslich schönes Erlebnis wird, werden alle Angebote auf touriDat nur von ausgewählten Gastgebern angeboten. Zusätzlich sichert touriDat Ihren Kauf mit der touriDays-Garantie gegen finanzielle Verluste ab.
    br
    br
    | Somit ist Ihr Geld beispielsweise auch bei Insolvenz des Gastgebers oder Betriebsschließung zu 100% abgesichert und Sie erhalten in einem solchen Fall Ihr Geld zurück. Darüber hinaus bietet touriDat Ihnen den bestmöglichen Gästeservice inklusive kostenloser Hotline und kostenlosem Support sowie einem 30tägigen Rückgaberecht.
</template>

<script>
export default {
  name: "BuyersProtectionHeader",
  props:{
    headingType:{
      type:Number,
      default:1
    }
  }
}
</script>