<template lang="pug">
  .modal-dialog.m-0
    .modal-content(:data-template="theme")
      .modal-header
        slot(name="header" v-if="hasHeaderSlot")
        template(v-else)
          h3.modal-title {{title}}
          .px-2.float-right
            a.close(@click="$emit('close');")
              font-awesome-icon(icon="times")
      .modal-body.p-0.m-0
        slot(name="default")
      .modal-footer
        slot(name="footer" v-if="hasFooterSlot")
        template(v-else)
          .p-2.float-right
            button.button.button-secondary(@click="$emit('close')" :class="isThemed ? 'button-themed' : 'button-td-grey'") schließen
</template>
<script>
export default {
  name: "ModalComponent",
  props:{
    title:String,
    themed:Boolean,
    theme:String,
  },
  data(){
    return {
      isThemed:false,
    }
  },
  computed:{
    hasHeaderSlot(){
      return !!this.$slots.header;
    },
    hasFooterSlot(){
      return !!this.$slots.footer;
    }
  },
  created(){
    if(this.themed || this.theme)this.isThemed = true;
  }
}
</script>
<style scoped lang="scss">

</style>