import HotlineModal from "@/views/components/modals/hotline-modal";

export function showHotlineModal(props) {
    try {
        this.$modal.show(
            HotlineModal,
            props,
            {
                height: "auto",
                classes: [
                    "modalMaxHeight",
                    "rounded-0"
                ]
            });
    } catch (e) {
        console.log(e);
    }
}