<template lang="pug">
  a.facebook.d-flex.align-items-center(href="https://www.facebook.com/touriDat" :target="target")
    font-awesome-icon(icon="fa-brands fa-facebook")
</template>
<script>
export default {
  name: "SocialLinkFacebook",
  props: {
    target: {
      type: String,
      default: "_blank"
    }
  }
};
</script>
<style scoped lang="scss"></style>
