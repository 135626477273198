<template lang="pug">
  .shopping-basket-overview
    .h2.txt-td-grey-basic Übersicht
    CheckoutOverview(
      :total-item-price="totalItemPrice"
      :total-item-value="totalItemValue"
      :shipping-costs="shippingCosts"
      :discount-value="discountValue"
      :discount-percentage="discountPercentage"
      :remaining-discount-value="remainingDiscountValue"
      :total-price="totalPrice"
    )
      template(v-slot:voucherInput)
        .input-group
          template(v-if="!orderDiscountCode")
            input.form-control.rounded-0(type="text" v-model="voucherCode" placeholder="Ihr Gutschein-Code" maxlength="14" v-on:keyup.enter="confirmVoucher" :class="{ 'text-uppercase': voucherCode.length > 0 }")
            .input-group-append(@click="confirmVoucher")
              a.button.button-primary.button-tdays.button-icon
                font-awesome-icon(icon="check")
          template(v-else)
            input.form-control.rounded-0.disabled(type="text" :value="orderDiscountCode.Code" disabled)
            .input-group-append(@click="removeVoucher")
              a.button.button-primary.button-tpics.button-icon
                font-awesome-icon(icon="trash")
        .bg-tpics-basic.p-2.txt-white.font-weight-bold.my-4.text-center(v-if="containsDiscountVoucher || isInvalidForLiveShoppingDiscount") Hinweis:&nbsp
          template(v-if="containsDiscountVoucher") Wertgutscheine sind von der Rabattierung ausgeschlossen.
          template(v-if="isInvalidForLiveShoppingDiscount") Der aktuelle Rabatt-/Wertgutschein ist nicht auf Artikel zum Deal-Preis anwendbar.
</template>
<script>
import CheckoutOverview from "@/views/checkout/partials/CheckoutOverview.vue";
import { OrderDiscountCodes } from "@/graphql/OrderDiscountCodes.ts";
import { useCalculate } from "@touridev/shopping-basket-calculator";

export default {
  name: "ShoppingBasketOverview",
  components: { CheckoutOverview },
  data() {
    return {
      voucherCode: "",
      loadingVoucher: false,
      orderDiscountCode: undefined
    };
  },
  watch: {
    async items() {
      await this.updateVoucher();
    }
  },
  computed: {
    isInvalidForLiveShoppingDiscount() {
      const discounts = this.$shoppingBasket?.discounts || [];
      const [discount] = discounts;
      return discount?.appliesToDiscountedItems === false;
    },
    containsDiscountVoucher() {
      return this.$shoppingBasket.uniqueItems.find((item) => [5, 9].includes(item.WebTemplateId));
    },
    shoppingBasketValues() {
      const items = this.items.map((item) => {
        return {
          currentPrice: item.CurrentPrice,
          quantity: item.Quantity,
          hasDealPrice: item.IsDiscounted,
          isDiscountable: ![5, 9].includes(item.WebTemplateId) && (!this.orderDiscountCode?.IsConstraintToItems?.length || this.orderDiscountCode.IsConstraintToItems.includes(item.id))
        };
      });
      const discounts = this.orderDiscountCode ? [{
        value: this.orderDiscountCode.Value,
        isPercentage: this.orderDiscountCode.IsPercentage,
        appliesToDiscountedItems: this.orderDiscountCode.IsValidForLiveShoppingDiscount
      }] : [];
      return useCalculate(items, discounts);
    },
    items() {
      return this.$shoppingBasket.items || [];
    },
    totalItemPrice() {
      const { total } = this.shoppingBasketValues;
      return total || 0;
    },
    totalItemValue() {
      return this.$shoppingBasket.overallItemValue || 0;
    },
    shippingCosts() {
      return this.$shoppingBasket.overallShippingCost || 0;
    },
    discountValue() {
      if (!this.orderDiscountCode) return 0;
      const { discount } = this.shoppingBasketValues;
      const value = discount || 0;
      return parseFloat(value.toFixed(2));
    },
    discountPercentage() {
      return this.orderDiscountCode?.IsPercentage ? this.orderDiscountCode.Value : undefined;
    },
    remainingDiscountValue() {
      const { remaining } = this.shoppingBasketValues;
      return remaining || 0;
    },
    totalPrice() {
      const total = this.totalItemPrice;
      const discount = this.discountValue;
      return (total - discount) + this.shippingCosts;
    }
  },
  methods: {
    async loadVoucher(code) {
      code = code.toUpperCase();
      return this.$apollo
        .query({
          query: OrderDiscountCodes.Queries.OrderDiscountCode,
          variables: {
            code,
            itemIds: this.$shoppingBasket.items.map((item) => item.id)
          }
        })
        .then((response) => {
          const errors = response?.errors;
          if (errors?.length) {
            const [err] = errors;
            const details = err?.extensions;
            if (details.code === "CODE_TOTAL_TO_LOW" && details?.minRequiredOrderValue) {
              const total = details?.total || 0;
              const remainingValue = details.minRequiredOrderValue - total;
              throw new Error(`Für den ausgewählten Gutschein ist ein Mindestbestellwert ${details.minRequiredOrderValue.toLocaleString()}€ erforderlich. Fügen Sie ihrem Warenkorb weitere Produkte im Wert von mindestens ${remainingValue.toLocaleString()}€ hinzu.`);
            }
          }
          return response?.data?.orderDiscountCode;
        });
    },
    async updateVoucher() {
      if (!this.voucherCode?.trim()?.length) return;
      const orderDiscountCode = await this.loadVoucher(this.voucherCode);
      if (!orderDiscountCode) {
        await this.removeVoucher();
        return;
      }
      this.orderDiscountCode = orderDiscountCode;
      await this.$shoppingBasket.setDiscount(0, {
        id: this.orderDiscountCode.Code,
        isPercentage: this.orderDiscountCode.IsPercentage,
        value: this.orderDiscountCode.Value,
        appliesToDiscountedItems: this.orderDiscountCode.IsValidForLiveShoppingDiscount
      });
    },
    async confirmVoucher() {
      if (typeof this.voucherCode !== "string") {
        return this.$alert(
          "Bitte geben Sie einen gültigen Gutschein-Code ein."
        );
      }

      try {
        this.loadingVoucher = true;
        await this.updateVoucher();
        if (!this.orderDiscountCode)
          throw new Error(
            `Es konnte kein gültiger Gutschein für Ihren Code gefunden werden.`
          );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      } finally {
        this.loadingVoucher = false;
      }
    },
    async removeVoucher() {
      this.voucherCode = "";
      if (!this.orderDiscountCode) return;
      await this.$shoppingBasket.removeDiscount({
        id: this.orderDiscountCode.Code,
        value: this.orderDiscountCode.Value,
        isPercentage: this.orderDiscountCode.IsPercentage
      });
      this.orderDiscountCode = undefined;
    }
  },
  async mounted() {
    if (!this.$shoppingBasket.discounts.length) return;
    const voucher = this.$shoppingBasket.discounts[0];
    if (voucher) {
      this.voucherCode = voucher.id;
    }
    await this.updateVoucher();
  }
};
</script>
