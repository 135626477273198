import gql from "graphql-tag";

export const UserShoppingBasketItems = {
  Queries: {
    UserShoppingBasketItem: gql`
      query userShoppingBasketItem($itemId: ID!, $isDiscounted: Boolean) {
        userShoppingBasketItem(itemId: $itemId, isDiscounted: $isDiscounted) {
          id
          UserId
          ItemId
          Quantity
          IsDiscounted
          DiscountType
        }
      }
    `,
    UserShoppingBasketItems: gql`
      query userShoppingBasketItems {
        userShoppingBasketItems {
          id
          UserId
          ItemId
          Quantity
          IsDiscounted
          DiscountType
          IsPurchasable
          AvailableDealQuantity
          AvailableRegularQuantity
        }
      }
    `,
  },
  Mutations: {
    EmptyShoppingBasket: gql`
      mutation EmptyUserShoppingBasket {
        emptyUserShoppingBasket {
          IsSuccess
        }
      }
    `,
    UpdateUserShoppingBasketItemDiscount: gql`
      mutation UpdateUserShoppingBasketItemDiscount(
        $isDiscounted: Boolean!
        $id: ID!
      ) {
        updateUserShoppingBasketItemDiscount(
          isDiscounted: $isDiscounted
          id: $id
        ) {
          IsSuccess
          Message
        }
      }
    `,
    AddItemToUserShoppingBasket: gql`
      mutation addItemToUserShoppingBasket($item: ShoppingBasketItem) {
        addItemToUserShoppingBasket(item: $item) {
          IsSuccess
          Message
        }
      }
    `,
    RemoveItemFromUserShoppingBasket: gql`
      mutation removeItemFromUserShoppingBasket($id: ID!) {
        removeItemFromUserShoppingBasket(id: $id) {
          IsSuccess
          Message
        }
      }
    `,
    RemoveItemsFromUserShoppingBasket: gql`
      mutation removeItemsFromUserShoppingBasket($itemId: ID!) {
        removeItemsFromUserShoppingBasket(itemId: $itemId) {
          IsSuccess
          Message
        }
      }
    `,
    UpdateItemQuantityInUserShoppingBasket: gql`
      mutation updateItemQuantityInUserShoppingBasket(
        $id: ID!
        $quantity: Int!
      ) {
        updateItemQuantityInUserShoppingBasket(id: $id, quantity: $quantity) {
          IsSuccess
          Message
        }
      }
    `,
    DeleteUserShoppingBasketItems: gql`
      mutation deleteUserShoppingBasketItems {
        deleteUserShoppingBasketItems {
          IsSuccess
          Message
        }
      }
    `,
    CleanUpUserShoppingBasket: gql`
      mutation CleanUpUserShoppingBasket {
        cleanUpUserShoppingBasket {
          id
          SystemItemId
          Name1
        }
      }
    `,
  },
};
