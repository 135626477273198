<template lang="pug">
  .registration-bulletin-points
      .mb-1(:class="titleClass") Jetzt Kundenkonto anlegen und Vorteile sichern!
      .points.text-center
        .point.d-block.d-lg-inline-flex.align-items-center.mx-2(v-for="point in registrationBulletPoints")
            font-awesome-icon.mr-1(icon="check")
            span {{point}}
</template>
<script>
export default {
  name: "RegistrationBulletinPoints",
  props: {
    orientation: {
      type: String,
      validator(value) {
        return ["horizontal", "vertical"].includes(value);
      },
      default: "vertical",
    },
    titleSize: {
      type: String,
      validator(value) {
        return ["small", "medium", "big"].includes(value);
      },
      default: "small",
    },
  },
  setup() {
    const registrationBulletPoints = [
      "Warenkorb und Favoriten speichern",
      "Schnell einkaufen ohne erneute Dateneingabe",
      "Verkaufshistorie immer im Blick",
      "Gutscheindownload",
    ];

    return { registrationBulletPoints };
  },
  computed: {
    titleClass() {
      switch (this.titleSize) {
        default:
        case "small":
          return "font-weight-bold";
        case "medium":
          return "h2";
        case "big":
          return "h1";
      }
    },
    pointColumnWidth() {
      if (!this.registrationBulletPoints.length) return 12;
      return 12 / this.registrationBulletPoints.length;
    },
  },
};
</script>
<style scoped lang="scss">
.point {
  span {
    width: max-content;
  }
}
</style>
