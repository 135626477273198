<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 41.5 41.5">
    <path fill="currentColor"
          d="M29.4,23h0s2-4.2,2-4.2h0s0,0,0,0h0s0,0,0,0l2,4.2h0c.5.1.9.3,1.4.5v-12.5l-13.3,11.4c-.2.2-.4.2-.6.2s-.5,0-.6-.2L6.7,11v19.7c0,.5.5,1,1,1h15.6c0-.3,0-.6,0-.9,0-.7,0-1.3.3-2,.3-1.3.8-2,.8-2,1.1-1.9,2.9-3.3,5.1-3.9Z" />
    <polygon fill="currentColor" points="33.2 10.2 20.7 10.2 8.3 10.2 20.7 20.8 33.2 10.2" />
    <path fill="currentColor"
          d="M38.2,31.6c-.9.3-1.7.5-2.6.5-1.6,0-3.2-.5-4.8-1.6-2.1-1.4-4.1-1.7-6.2-.9-.1.5-.2,1.1-.2,1.7s0,1.1.2,1.7c2.3-.7,4.7-.3,7,1.2,1.8,1.2,3.5,1.6,5.2,1.2.1-.2.3-.4.4-.6,0,0,0,0,0,0h0c.6-.9.9-2,.9-3.1Z" />
    <path fill="currentColor"
          d="M33,24.6l-1.7-3.5h0s0,0,0,0h0s0,0,0,0l-1.7,3.5h0c-1.8.5-3.4,1.7-4.3,3.3,2.1-.4,4.2,0,6.3,1.4,2.2,1.5,4.3,1.7,6.5.8-.5-2.7-2.5-4.8-5.1-5.5h0ZM30.8,24.6l.5-1.8.5,1.8c-.5-.2-1,0-1,0Z" />
    <path fill="currentColor"
          d="M25.1,34.3c1.1,2.3,3.5,3.9,6.2,3.9s2.8-.5,3.9-1.2c-1.5,0-2.9-.6-4.4-1.6-1.9-1.3-3.8-1.6-5.7-1.1Z" />
  </svg>
</template>

<script>
export default {
  name: "EnvelopeChristmasIcon"
};
</script>
