<template lang="pug">
  ul(:data-level="level")
    li(:data-level="level" v-for="node in nodes")
      template(v-if="node.Children")
        a.label.branches(@click="$emit('toggleBranch',$event)")
          | {{node.Name}}
          i.plus
            font-awesome-icon(icon="plus")
          i.minus
            font-awesome-icon(icon="minus")
        .branch
          router-link.label.category(v-if="node.Url" :to="getRoute(node.Url)") Alle Anzeigen
          TreeMenuCollapsible(:nodes="node.Children" :level="level + 1" :base-path="basePath" :ref-type="refType")
      template(v-else)
        router-link.label(v-if="node.Url" :to="getRoute(node.Url)") {{node.Name}}
</template>
<script>
export default {
  name: "TreeMenuCollapsible",
  props:{
    nodes:Array,
    level:Number,
    refType:String,
    basePath:String,
  },
  methods:{
    getRoute(path){
      return {path:this.getFullPath(path),query:{ref:this.refType}};
    },
    getFullPath(path){
      return this.basePath + path;
    }
  }
}
</script>

<style scoped>

</style>