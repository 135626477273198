<template lang="pug">
  #buyer-protection
    .container-fluid
      .row
        BuyersProtectionTeaser.mt-3
    .container.mt-3.mt-md-5
      .row
        .col-12
          BuyersProtectionHeader
    .container-fluid.mt-3.mt-md-5
      .row
        BuyersProtectionManagement
    .container.mt-3.mt-md-5
      .row
        .col-12
          BuyersProtectionBenefits
    .mt-4
      BuyersProtectionFooter
</template>

<script>
import BuyersProtectionHeader from "@/views/components/partials/buyersProtection/partials/BuyersProtectionHeader";
import BuyersProtectionManagement
  from "@/views/components/partials/buyersProtection/partials/BuyersProtectionManagement";
import BuyersProtectionBenefits from "@/views/components/partials/buyersProtection/partials/BuyersProtectionBenefits";
import BuyersProtectionFooter from "@/views/components/partials/buyersProtection/partials/BuyersProtectionFooter";
import BuyersProtectionTeaser from "@/views/components/partials/buyersProtection/partials/BuyersProtectionTeaser";
export default {
  name: "static-buyer-protection",
  components: {
    BuyersProtectionTeaser,
    BuyersProtectionFooter,
    BuyersProtectionBenefits,
    BuyersProtectionManagement,
    BuyersProtectionHeader
  },
  metaInfo() {
    return {
      title:  "touriDays-Garantie | Informationen und vieles mehr",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Testsieger DtGV✔ 100% Käuferschutz✔ | touriDays-Garantie – Sie suchen Antworten oder mehr Informationen? Jetzt hier finden und informieren!"
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: ""
        },
        {
          vmid: "og:title",
          name: "og:title",
          content:
            "touriDays-Garantie | Informationen und vieles mehr | touriDat.com",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "Testsieger DtGV✔ 100% Käuferschutz✔ | touriDays-Garantie – Sie suchen Antworten oder mehr Informationen? Jetzt hier finden und informieren!",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
