<template lang="pug">
  label.scalable-checkbox.position-relative(:class="{'checked':value}")
    .position-absolute.w-100.h-100.bg-white(v-if="!value")
    input(type="checkbox" :value="value" style="display:none!important" @input="$emit('input', !value)")
    .check.d-flex.align-items-center.justify-content-center.position-absolute.w-100.h-100
      font-awesome-icon(icon="check")
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScalableCheckbox",
  emits: ["input"],
  props: {
    value: Boolean
  }
});
</script>
<style scoped lang="scss">
.scalable-checkbox {
  width: 1.2em;
  aspect-ratio: 1;
  color: #111;
  margin-bottom: 0;

  &:not(.checked) {
    border: 2px solid #c2c2c2;
  }

  box-sizing: border-box;
  border-radius: 0.2em;
  overflow: hidden;
}
</style>
