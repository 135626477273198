import moment from "moment";

export const VueFilter = {
    formatDateFilter: {
        name: "formatDate",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("DD.MM.YYYY");
            }
        },
    },
    formatDateTimeFilter: {
        name: "formatDateTime",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("DD.MM.YYYY HH:mm");
            }
        },
    },
    formatDatePickerValueFilter: {
        name: "formatDatePickerValue",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD");
            }
        },
    },
    formatDateWithDayFilter: {
        name: "formatDateWithDay",
        func: (value) => {
            if (value) {
                const date = moment(String(value)).lang("de");
                return date.format("dd") + ". " + date.format("DD.MM.YYYY");
            }
        },
    },
    formatValueFilter: {
        name: "formatValue",
        func: (value) => {
            if (value) {
                const formatted = parseFloat(value).toFixed(2);
                if (formatted.length > 6) {
                    const firstPart = formatted.substring(
                        0,
                        formatted.length - 6
                    );
                    const secondPart = formatted.substring(
                        formatted.length - 6,
                        formatted.length - 3
                    );
                    const thirdPart = formatted.substring(
                        formatted.length - 3,
                        formatted.length
                    );
                    return `${firstPart}.${secondPart}${thirdPart.replace(
                        ".",
                        ","
                    )} €`;
                }
                return formatted.replace(".", ",") + " €";
            }
            // @ts-ignore
            return parseFloat(0.0).toFixed(2).replace(".", ",") + " €";
        },
    },
    formatPriceFilter: {
        name: "formatPrice",
        func: (value) => {
            if (value) {
                let negativeSlot = 0;
                if (value < 0) {
                    negativeSlot = 1;
                }
                const formatted = parseFloat(value).toFixed(2);
                if (formatted.length > 6 + negativeSlot) {
                    const firstPart = formatted.substring(
                        0,
                        formatted.length - 6 + negativeSlot
                    );
                    const secondPart = formatted.substring(
                        formatted.length - 6 + negativeSlot,
                        formatted.length - 3 + negativeSlot
                    );
                    const thirdPart = formatted.substring(
                        formatted.length - 3 + negativeSlot,
                        formatted.length
                    );
                    return `${firstPart}.${secondPart}${thirdPart.replace(
                        ".",
                        ","
                    )} €`;
                }
                return formatted.replace(".", ",") + " €";
            }
            // @ts-ignore
            return parseFloat(0.0).toFixed(2).replace(".", ",") + " €";
        },
    },
    formatPercentageFilter: {
        name: "formatPercentage",
        func: (value, withWhitespace) => {
            if (value) {
                return Math.round(value) + (withWhitespace ? " %" : "%");
            }
            // @ts-ignore
            return `0${withWhitespace ? ' ' : ''}%`;
        },
    },
    escapeHTMLFilter: {
        name:'sanitize',
        func: (value) => {
            value = value.split('<br>').join(' ');
            const el = document.createElement('div');
            el.innerHTML = value;
            return el.innerText;
        }
    }
};
