<template lang="pug">
    .user-cart-info(v-if="removedBasketItems.length && showChangedItemsMessage")
        .bg-white.p-3.mb-3
            .font-weight-bold.mb-2 Ein oder mehrere Artikel in Ihrem Warenkorb sind seit Ihrem letzten Besuch leider nicht mehr verfügbar:
            .item.small(v-for="item in removedBasketItems") {{item.Name1}} (Art.Nr.: {{item.SystemItemId}})
            .text-center
              button.button.button-primary.button-tdays.mt-3(@click="hideChangedItemsMessage") Ausblenden
</template>
<script>
import { useUserStore } from "@/store/user-store";
import { mapState } from "pinia";
export default {
  name: "UserCartInfo",
  data() {
    return {
      showChangedItemsMessage: true,
    };
  },
  computed: {
    ...mapState(useUserStore, ["removedBasketItems"]),
  },
  mounted() {
    this.showChangedItemsMessage = !this.$session.has(
      "hideChangedItemsMessage"
    );
  },
  methods: {
    hideChangedItemsMessage() {
      this.$session.set("hideChangedItemsMessage", true);
      this.showChangedItemsMessage = false;
    },
  },
};
</script>
<style scoped lang="scss"></style>
