<template lang="pug">
.text-countdown {{timeString}}
</template>
<script>
import moment from "moment";
import { stripCharacter } from "@/lib/helper/strings";
const pad = (val) => {
  if (!val) return "00";
  if (val < 10) return `0${val}`;
  return val.toString();
};

let interval;
export default {
  name: "TextCountdown",
  props: {
    deadline: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      secondsTillDeadline: 0,
    };
  },
  computed: {
    timeString() {
      return stripCharacter(
        `${this.days ? this.days + "," : ""} ${
          this.hours ? this.hours + "," : ""
        } ${this.minutes ? this.minutes + "," : ""} ${
          this.seconds ? this.seconds + "," : ""
        }`.trim(),
        ","
      );
    },
    time() {
      const days = this.duration.days();
      const hours = this.duration.hours();
      const minutes = this.duration.minutes();
      const seconds = this.duration.seconds();

      return { days, hours, minutes, seconds };
    },
    duration() {
      return moment.duration(this.secondsTillDeadline, "seconds");
    },
    days() {
      const days = this.time.days;
      if (!days) return "";
      return `${days} ${days === 1 ? "Tag" : "Tage"}`;
    },
    hours() {
      const hours = this.time.hours;
      if (!hours) return "";
      return `${pad(hours)} ${hours === 1 ? "Stunde" : "Stunden"}`;
    },
    minutes() {
      const minutes = this.time.minutes;
      if (!minutes) return "";
      return `${pad(minutes)} ${minutes === 1 ? "Minute" : "Minuten"}`;
    },
    seconds() {
      const seconds = this.time.seconds;
      if (!seconds) return "";
      return `${pad(seconds)} ${seconds === 1 ? "Sekunde" : "Sekunden"}`;
    },
  },
  mounted() {
    const endDate = moment(this.deadline);
    this.secondsTillDeadline = moment
      .duration(endDate.diff(Date.now()))
      .asSeconds();
    clearInterval(interval);
    interval = setInterval(() => {
      this.secondsTillDeadline--;
    }, 1000);
  },
  unmounted() {
    clearInterval(interval);
  },
  destroyed() {
    clearInterval(interval);
  },
};
</script>
<style scoped lang="scss"></style>
