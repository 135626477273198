<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 41.5 41.5">
    <path fill="currentColor"
          d="M21.4,22.4c-.2.2-.4.2-.6.2s0,0,0,0,0,0,0,0c-.2,0-.5,0-.6-.2L6.7,11v19.7c0,.5.5,1,1,1h26c.5,0,1-.5,1-1V11l-13.4,11.3Z" />
    <polygon fill="currentColor" points="33.2 9.7 20.7 9.7 8.3 9.7 20.7 20.3 33.2 9.7" />
  </svg>
</template>

<script>
export default {
  name: "EnvelopeIcon"
};
</script>
