<template lang="pug">
  a.instagram.d-flex.align-items-center(href="https://www.instagram.com/touridat/" :target="target")
    font-awesome-icon(icon="fa-brands fa-instagram")
</template>
<script>
export default {
  name: "SocialLinkInstagram",
  props: {
    target: {
      type: String,
      default: "_blank"
    }
  }
};
</script>
<style scoped lang="scss"></style>
