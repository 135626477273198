<template lang="pug">
.loader
</template>

<script>
// the actual component has moved to views/components/loading/AppLoadingSpinner.vue
// this component can be used to trigger the loading animation via EventBus
import EventBus from "@/event-bus";

export default {
  name: "LoadingSpinnerOverlay",
  mounted(){
    EventBus.$emit("changeLoadingState",true)
  },
  destroyed(){
    EventBus.$emit("changeLoadingState",false)
  }
}
</script>
