<template lang="pug">
header.section-header(:class="{'sm-sticky':stickyOnSmall}")
  .container
    slot
</template>

<script>
export default {
  name: "HostSectionHeader",
  props:{
    stickyOnSmall:Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries.scss";
@import "@/assets/styles/_colors.scss";
.section-header {
  background-color:$td-grey-lighter;
  color:$td-grey-basic;
  font-weight:500;
  padding:1.5rem 0;
  margin:1.5rem 0;

  &.sm-sticky {
    position: sticky;
    top: 0;
    z-index: 1;

    @include md {
      position:relative;
    }
  }
}
</style>
