/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
  return (item && typeof item === "object" && !Array.isArray(item));
};

/**
 * Deep merge two objects.
 * @param target
 * @param source
 * @returns Record<string,any>
 */
export const mergeDeep = (target, source) => {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};
/**
 * Returns a copy of the given Object with all undefined values removed.
 * Returns undefined if the resulting Object is empty
 * @param { Record<string,any> } obj
 * @returns { Record<string,any> | undefined }
 */
export const definedObject = (obj) => {
  obj = JSON.parse(JSON.stringify(obj));
  if (!Object.keys(obj).length) return undefined;
  return obj;
};
/**
 * Returns a copy of the given Object with the given keys removed
 * @param { Record<string,any> } obj
 * @param { Array<string> } keys
 * @returns { Record<string,any> }
 */
export const objectWithout = (obj, keys) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (keys.includes(key)) return;
    result[key] = obj[key];
  });
  return result;
};
