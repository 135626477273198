<template lang="pug">
    .registration-form(:class="{'has-labels':showsLabels}")
        .inputs(@input="resetFormValidation")
          .form-group
              label.form-label.small {{ labels[0] }}
              input.form-control.custom-control(v-model="email" type="email" :placeholder="labels[0]" name="email" required)
          .form-group
              label.form-label.small {{ labels[1] }}
              input.form-control.custom-control(v-model="emailVerification" type="email" :placeholder="labels[1]"  name="emailVerification" required)
          .row
              .col-12.col-md-6
                .form-group
                    label.form-label.small {{ labels[2] }}
                    input.form-control.custom-control(v-model="firstName" :placeholder="labels[2]" name="firstName" required)
              .col-12.col-md-6
                  .form-group
                    label.form-label.small {{ labels[3] }}
                    input.form-control.custom-control(v-model="lastName" :placeholder="labels[3]" name="lastName" required)
          .row
              .col-12.col-md-6
                  .form-group
                      label.form-label.small {{ labels[4] }}
                      input.form-control.custom-control(v-model="password" type="password" :placeholder="labels[4]" name="password" required)
              .col-12.col-md-6
                  .form-group
                      label.form-label.small {{ labels[5] }}
                      input.form-control.custom-control(v-model="passwordVerification" type="password" :placeholder="labels[5]" name="passwordVerification" required)
        slot(name="submitButton" :submit="submit")
</template>
<script>
import EventBus from "@/event-bus";
import { Users } from "@/graphql/Users.ts";
import { sendSignUp } from "@/lib/helper/gtm";

export default {
  name: "RegistrationForm",
  props: {
    showsLabels: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const labels = [
      "E-Mail Adresse",
      "E-Mail Adresse bestätigen",
      "Vorname",
      "Nachname",
      "Passwort",
      "Passwort wiederholen",
    ];

    return { labels };
  },
  data() {
    return {
      email: "",
      emailVerification: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordVerification: "",
    };
  },
  methods: {
    /**
     * Removes the "invalid" class from each required input inside this.$el
     */
    resetFormValidation() {
      const inputs = [...this.$el.querySelectorAll("input[required]")];
      inputs.forEach((el) => {
        el.classList.remove("invalid");
      });
    },
    validateInput(input) {
      if (input.checkValidity() !== true) {
        input.classList.add("invalid");
        return false;
      }
      return true;
    },
    /**
     * Validates a String (Password) using regex
     * @param str
     * @returns {boolean}
     */
    validatePassword(str) {
      return str.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/);
    },
    /**
     * Triggers the sumbit process, including validation and sending out requests to create a new user and sign-up the user to the newsletter if the checkbox is checked.
     * Errors are being catched and displayed to the client using this.$alert method.
     * Successfully user creation will display a message using this.$alert method.
     * The Newsletter-Signup will only display Errors, in order to not populate the client window with to many alerts
     * @returns {Promise<void>}
     */
    async submit() {
      EventBus.$emit("changeLoadingState", true);
      // try creating the user, alert any errors
      try {
        await this.verify();
        const registrationSuccessMessage = await this.send();
        EventBus.$emit("changeLoadingState", false);
        await this.$alert(registrationSuccessMessage);
        sendSignUp();
        this.$router.replace({ name: "Home" });
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        this.$alert(e.message);
      }
    },
    /**
     * Verifies the required Registration Form Inputs
     * @returns {Promise<boolean>}
     */
    async verify() {
      const reject = (message) => {
        return Promise.reject({ message });
      };

      const getInput = (name) => {
        return this.$el.querySelector(`input[name="${name}"]`);
      };

      const rejectOnEmailVerification = reject(
        `Bitte bestätigen Sie Ihre E-Mail Adresse.`
      );
      const rejectOnPasswordVerification = reject(
        `Bitte bestätigen Sie Ihr Passwort.`
      );

      this.resetFormValidation();
      if (!this.validateInput(getInput("email")))
        return await reject(`Bitte geben Sie Ihre E-Mail Adresse an.`);
      if (!this.validateInput(getInput("emailVerification")))
        return rejectOnEmailVerification;
      if (this.email !== this.emailVerification) {
        getInput("emailVerification").classList.add("invalid");
        return rejectOnEmailVerification;
      }
      if (!this.validateInput(getInput("firstName")))
        return await reject(`Bitte geben Sie Ihren Vornamen an.`);
      if (!this.validateInput(getInput("lastName")))
        return await reject(`Bitte bestätigen Sie Ihren Nachnamen an.`);
      if (!this.validateInput(getInput("password")))
        return await reject(`Bitte geben Sie ein Passwort an.`);
      if (!this.validatePassword(this.password)) {
        getInput("password").classList.add("invalid");
        return await reject(
          "Bitte gehen Sie sicher, dass Ihr Passwort aus mindestens 8 Zeichen besteht. Es muss sowohl einen Kleinbuchstaben, Großbuchstaben als auch eine Zahl enthalten."
        );
      }
      if (!this.validateInput(getInput("passwordVerification")))
        return rejectOnPasswordVerification;
      if (this.password !== this.passwordVerification) {
        getInput("passwordVerification").classList.add("invalid");
        return rejectOnPasswordVerification;
      }

      return Promise.resolve(true);
    },
    /**
     * Triggers the CreateUser Mutation on the Server. Payload contains email, firstName, lastName and password in the required format.
     * If there is no valid MutationResponse, this will throw an Error with a general message.
     * If the MutationResponse's IsSuccess property is false, and a value for Message is present, this will throw an Error with the respective Message value.
     * @returns {Promise<string>}
     */
    async send() {
      return await this.$apollo
        .mutate({
          mutation: Users.Mutations.CreateUser,
          variables: {
            user: {
              LoginEmail: this.email,
              FirstName: this.firstName,
              LastName: this.lastName,
              Password: this.password,
            },
          },
        })
        .then(({ data }) => {
          if (!data || !data.createUser)
            throw new Error(
              "Der Benutzer konnte leider nicht erstellt werden, bitte versuchen Sie es später erneut."
            );
          if (!data.createUser.IsSuccess && data.createUser.Message)
            throw new Error(data.createUser.Message);
          return data.createUser.Message;
        });
    },
  },
  mounted() {
    EventBus.$on("submitRegistrationForm", this.submit);
  },
  unmounted() {
    EventBus.$off("submitRegistrationForm", this.submit);
  },
  destroyed() {
    EventBus.$off("submitRegistrationForm", this.submit);
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/colors";
.registration-form {
  label {
    color: $td-grey-basic;
    user-select: none;
  }
  &:not(.has-labels) {
    label {
      display: none;
    }
  }
  .check-newsletter {
    aspect-ratio: 1;
    width: 20px;
  }

  .invalid {
    box-shadow: 0 0 3px 3px $tpics-light;
  }
}
</style>
