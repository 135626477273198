<template lang="pug">
a.favorite.d-inline-block(@click="toggleFavHost" :class="{'active':!!userHostFavorite}" :title="iconTitle")
  font-awesome-icon(icon="heart")
</template>

<script>
import { UserHostFavorites } from "@/graphql/UserHostFavorites.ts";
import LoginModal from "@/views/components/modals/loginModal/LoginModal";
import { useUserStore } from "@/store/user-store";
import { mapState } from "pinia";
import { FavoriteHost } from "@/graphql/FavoriteHost.ts";
import EventBus from "@/event-bus";

export default {
  name: "HostFavoriteIcon",
  props: {
    host: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      userHostFavorite: false,
    };
  },
  apollo: {
    userHostFavorite: {
      query: UserHostFavorites.Queries.UserHostFavorite,
      variables() {
        return {
          hostId: this.host.id,
        };
      },
      skip() {
        return !this.$session.has("token");
      },
    },
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    iconTitle() {
      return `Gastgeber ${
        !this.userHostFavorite
          ? "zu favoriten hinzufügen"
          : "aus favoriten entfernen"
      }`;
    },
  },
  methods: {
    async toggleFavHost() {
      if (!this.user)
        return this.$modal.show(
          LoginModal,
          {},
          {
            height: "auto",
            classes: ["modalMaxHeight", "rounded-0"],
          }
        );
      try {
        EventBus.$emit("changeLoadingState", true);
        if (this.userHostFavorite) {
          const isUnfaved = await this.$apollo
            .mutate({
              mutation: FavoriteHost.Mutations.UnfavoriteHost,
              variables: {
                hostId: this.host.id,
              },
            })
            .then(
              (response) => response?.data?.unfavoriteHost?.IsSuccess || false
            );
          if (isUnfaved) this.userHostFavorite = false;
          return;
        }

        const isFaved = await this.$apollo
          .mutate({
            mutation: FavoriteHost.Mutations.FavoriteHost,
            variables: {
              hostId: this.host.id,
            },
          })
          .then((response) => response?.data?.favoriteHost?.IsSuccess || false);
        if (isFaved) this.userHostFavorite = true;
      } catch (e) {
        this.$log.error(e);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
  },
  async mounted() {
    if (!this.user) return;
    const favorite = await this.$apollo
      .query({
        query: UserHostFavorites.Queries.UserHostFavorite,
        variables: {
          hostId: this.host.id,
        },
      })
      .then((response) => response.data.userHostFavorite);

    this.userHostFavorite = !!favorite;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.favorite {
  cursor: pointer;
  transition: opacity 0.5s, color 0.5s, filter 0.5s;
  color: var(--theme-color-light, #{$tdays-lighter});
  opacity: 0.35;
  filter: grayscale(1);
  &.active,
  &:hover {
    opacity: 1;
    filter: grayscale(0);
  }
}
</style>
