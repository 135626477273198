<template lang="pug">
.discount-badge.d-inline-flex.flex-wrap.align-items-center.justify-content-center
  .w-100.text-center(v-if="showsLabel") bis zu
  span.percentage {{formatValue(value)}}
</template>
<script>
export default {
  name: "HostPreviewDiscount",
  props: {
    value: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0;
      },
    },
    showsLabel: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const formatValue = (value) => {
      return `-${Math.round(value)}%`;
    };
    return { formatValue };
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/mediaqueries.scss";

.discount-badge {
  color: $white;
  padding: 0.3rem 0;

  .percentage {
    position: relative;
    &:after {
      content: "²";
      position: absolute;
    }
  }
}
</style>
