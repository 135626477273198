import { Users } from "@/graphql/Users.ts";
import { UserShoppingBasketItems } from "@/graphql/UserShoppingBasketItems.ts";
import { useUserStore } from "@/store/user-store";
import { Items } from "@/graphql/Items.ts";
import { toShoppingBasketItem } from "@/lib/plugins/shoppingBasket";
import { DiscountTypesEnum } from "@/utils/DiscountTypesEnum.ts";
import { ChangedItemCauseEnum } from "@/utils/ChangedItemCauseEnum.ts";

import ShoppingBasketSelector from "@/views/components/userAccount/ShoppingBasketSelector.vue";
import EventBus from "@/event-bus";

export const showShoppingBasketSelector = async (
  $modal,
  currentItems,
  userItems
) => {
  EventBus.$emit("changeLoadingState", false);
  return new Promise((resolve, reject) => {
    try {
      $modal.show(
        ShoppingBasketSelector,
        {
          currentItems,
          userItems,
          onSelect: (items) => {
            resolve(items);
          },
        },
        {
          clickToClose: false,
          width: "600px",
          height: "auto",
          classes: ["modalMaxHeight", "rounded-0"],
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const authUser = async (
  showBasketSelection,
  $apollo,
  $shoppingBasket,
  $modal
) => {
  // load the current user
  const user = await $apollo
    .query({
      query: Users.Queries.User,
    })
    .then((response) => response?.data?.user);
  if (!user) return;
  // store the user
  useUserStore().setUser(user);
  // restore the user's shopping basket
  const changedItems = [];
  const userShoppingBasketItems = await $apollo
    .query({
      query: UserShoppingBasketItems.Queries.UserShoppingBasketItems,
    })
    .then((response) => response?.data?.userShoppingBasketItems || []);

  // load the actual shopping basket items
  const items = await $apollo
    .query({
      query: Items.Queries.ShoppingBasketItems,
    })
    .then((response) => response?.data?.shoppingBasketItems || []);

  const shoppingBasketItems = [];
  for (const item of items) {
    for (let q = 0; q < item.Quantity; q++) {
      const basketItem = toShoppingBasketItem(item);
      const userItem = userShoppingBasketItems.find(
        (ui) => ui.ItemId === item.id
      );
      if (!userItem) continue;
      if (userItem.Quantity !== item.Quantity) {
        changedItems.push({
          itemId: item.id,
          cause: ChangedItemCauseEnum.LowQuantity,
        });
        await $apollo.mutate({
          mutation:
            UserShoppingBasketItems.Mutations
              .UpdateItemQuantityInUserShoppingBasket,
          variables: {
            id: userItem.id,
            quantity: item.Quantity,
          },
        });
      }

      const IsDiscounted =
        userItem.IsDiscounted &&
        (item.IsTouriTippsItem || item.IsInLiveShopping);

      const i = {
        ...basketItem,
        IsDiscounted,
        CurrentPrice: IsDiscounted
          ? item.LiveShoppingPrice
          : item.DiscountedPrice,
        IsTouriTippsItem:
          (item.IsTouriTippsItem &&
            userItem.DiscountType === DiscountTypesEnum.TippsDiscount) ||
          false,
      };

      shoppingBasketItems.push(i);
    }
  }
  //console.log({ shoppingBasketItems, userShoppingBasketItems, changedItems });
  const unavailableItems = userShoppingBasketItems.filter(
    (i) => !i.IsPurchasable
  );

  for (const unavailableItem of unavailableItems) {
    changedItems.push({
      itemId: unavailableItem.ItemId,
      cause: ChangedItemCauseEnum.NotPurchasable,
    });
    await $apollo.mutate({
      mutation:
        UserShoppingBasketItems.Mutations.RemoveItemFromUserShoppingBasket,
      variables: {
        id: unavailableItem.id,
      },
    });
  }

  const currentShoppingBasketItems = [...$shoppingBasket.items];
  useUserStore().setChangedBasketItems(changedItems);
  if (!showBasketSelection) return;
  if (shoppingBasketItems.length) {
    // items in the user shopping basket...
    if (
      !currentShoppingBasketItems.length ||
      currentShoppingBasketItems.every((item) =>
        shoppingBasketItems.map((basketItem) => basketItem.id).includes(item.id)
      )
    ) {
      // ...but no items in the localStorage
      $shoppingBasket.clearItems();
      $shoppingBasket.items = shoppingBasketItems;
      await $shoppingBasket.save();
    } else {
      // ... and items in the localStorage
      const selectedItems = await showShoppingBasketSelector(
        $modal,
        currentShoppingBasketItems,
        shoppingBasketItems
      );

      $shoppingBasket.clearItems();
      $shoppingBasket.items = selectedItems;
      await $shoppingBasket.save();
    }
  }
};
