<template lang="pug">
  .host-feature-drawer
    .row
      .col-md-6.col-sm-12(v-for="(type,index) in [commonSearchCriteria,hotelSearchCriteria]" v-if="type && type.length")
        h2.h2.mt-4
          template(v-if="!index") Allgemein
          template(v-else) Gastgeber
        .criteria.mt-2(v-for="criteria in type" v-if="criteria.Values && criteria.Values.length")
          .d-flex.align-items-center
            .icon.mr-2(v-if="criteria.Icon")
              img.img-fluid.w-100(:src="criteria.Icon")
            b.category {{criteria.Category}}
          div {{criteria.Values.join(", ")}}
</template>
<script>
import HostFeature from "@/views/host/partials/hostFeature/HostFeature";

export default {
  name: "HostFeatureDrawer",
  components: {
    HostFeature
  },
  props: {
    host: {
      type: Object,
      required: true
    }
  },
  computed: {
    searchCriteria() {
      return this.host?.HostCriteria || [];
    },
    commonSearchCriteria() {
      if (!this.searchCriteria?.length) return [];
      return this.searchCriteria.filter((criteria) => ["lage", "umgebung", "serviceleistungen", "in der nähe"].some((word) => criteria.Category.trim().toLowerCase().includes(word)));
    },
    hotelSearchCriteria() {
      if (!this.searchCriteria?.length) return [];
      return this.searchCriteria.filter((criteria) => !this.commonSearchCriteria.find((common) => common.Category === criteria.Category));
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries.scss";

.criteria {
  .icon {
    width: 25px;
  }
}
</style>
