<template lang="pug">
  #rr-gh.position-relative
    .px-4.py-2
      .row.d-flex.align-items-center
        .col-6
          a(href="https://deals.reisereporter.de").d-flex.align-items-center
            .small In Kooperation mit
            img.rr-logo.ml-1(src="https://deals.reisereporter.de/_layout/logo.svg")
        .col-6.text-right
          .h2.m-0(style="font-size:18px")
            a.link.text-decoration-none(@click="openHotlineModal")
              font-awesome-icon.mr-1(icon="phone")
              | +49 (0) 2974 / 9697-52

</template>
<script>
import {showHotlineModal} from "@/lib/components/hotlineModal";
import * as t from "@/store/main.json";

export default {
  name: "ReisereporterHeader",
  methods:{
    openHotlineModal(){
      const modal = showHotlineModal.bind(this)
      modal({
        hotline:t.main.rrHotlinePhone,
        hotlineHours:t.main.buisnessHourHotline,
        hotlineColorClass:'txt-reisereporter'
      });
    }
  }
}
</script>
<style scoped lang="scss">
.rr-logo{
  max-width:100px;
}
</style>