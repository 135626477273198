import * as t from "@/store/main.json";

export const stripSlashes = (str) => {
  return str.replace(/^\/|\/$/g, "");
};

export const stripCharacter = (str, character) => {
  if (typeof str !== "string") throw new Error(`the given value is not a string`);
  if (typeof character !== "string") throw new Error(`the given character is not a string`);
  if (str.startsWith(character)) str = str.slice(1);
  if (str.endsWith(character)) str = str.slice(0, str.length - 1);
  return str;
};

export const kebabize = (str) => {
  return str
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}` : letter;
    })
    .join("");
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const asCloudFrontUrl = (str) => {
  return str.replace("https://td-cloud.s3.eu-central-1.amazonaws.com/", t.cloudFront.cloudFrontTouriDatPath);
};
