<template lang="pug">
  .prize-door.bg-td-grey-light.aspect-square.position-relative.cursor-pointer(:data-number="number" :class="{open}" @click="handleClick")
    .front.position-absolute.w-100.h-100.d-flex.align-items-center.justify-content-center(:class="disabled ? 'bg-light-red' : currentDay === number ? 'bg-ttipps-lighter' : 'bg-tpics-light'" style="z-index:1;")
      .number.position-absolute(v-if="disabled" style="font-size: 1.75rem!important;right:1.25rem;bottom:0.75rem;z-index:2;") {{number}}
      .position-absolute(v-if="[6,24].includes(number)" style="right:0.75rem;top:0.75rem;z-index:2;")
        img(src="@/assets/images/star.png")
      .number(v-if="!disabled") {{number}}
      .position-relative(v-else)
        .d-flex.align-items-center.justify-content-center.w-100.h-100
          .position-relative.aspect-square.rounded-circle.overflow-hidden(style="max-width:65px;")
            .bg-td-grey-darker.position-absolute.w-100.h-100
            LogoComponent.bg-light-red(style="min-width:65px")
    .d-flex.align-items-center.justify-content-center.w-100.h-100
      .position-relative.aspect-square.rounded-circle.overflow-hidden(style="max-width:65px;")
        .bg-td-grey-darker.position-absolute.w-100.h-100
        LogoComponent.bg-td-grey-light(style="min-width:65px")
</template>

<script>
import LogoComponent from "@/views/components/logo/LogoComponent.vue";

export default {
  name: "PrizeDoor",
  components: { LogoComponent },
  inject: ["loadPrizesForDay", "showPrizeModal"],
  props: {
    currentDay: Number,
    number: {
      type: Number,
      required: true
    },
    showDisabledState: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    disabled() {
      if (!this.showDisabledState) return false;
      return this.number < this.currentDay;
    }
  },
  methods: {
    async handleClick() {
      if (this.number > this.currentDay) return this.$alert(
        `Diese Tür kann erst ab dem ${this.number}. Dezember geöffnet werden`,
        "Sie sind zu früh dran..."
      );
      this.open = true;
      const prizes = await this.loadPrizesForDay(this.number);
      await this.showPrizeModal(this.number, prizes);
      this.open = false;
      console.log({ prizes });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/mediaqueries";
@import "@/assets/styles/colors";

.prize-door {
  &:hover {
    .front {
      transform: perspective(1200px) rotateY(-10deg);
      box-shadow: 5px 0 25px rgba($td-grey-darker, 0.25)
    }
  }

  &.open {
    .front {
      transform: perspective(1200px) rotateY(-120deg);
      box-shadow: 25px 0 25px rgba(34, 34, 34, 0.5);
    }
  }
}

.bg-light-red {
  background-color: #946f6f;
}

.front {
  box-shadow: 0 0 25px rgba($td-grey-darker, 0);
  transform-origin: 0 50%;
  transition: transform 0.4s, box-shadow 0.4s;
  --border-spacing: 0.5rem;

  &:before {
    inset: var(--border-spacing);
    --w: calc(var(--border-spacing) * 2);
    content: "";
    position: absolute;
    width: calc(100% - var(--w));
    height: calc(100% - var(--w));
    border: 1px solid rgba(34, 34, 34, 0.8);
  }

  .number {
    font-family: 'DidotRegular', serif;
    font-size: 4.25rem;
    @include sm {
      font-size: 2rem;
    }
    @include lg {
      font-size: 3.5rem;
    }
  }
}
</style>
