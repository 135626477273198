import SealsModal from "@/views/components/modals/sealsModal/SealsModal";

export function showSealsModal(args){
    const has = (str) => {
        return args.map(arg => arg.toLowerCase()).indexOf(str) !== -1
    }
    this.$modal.show(
        SealsModal,{
            hasWinner:has('winner'),
            hasTouriDays:has('touridays'),
            hasTrustami:has('trustami'),
            hasSSL:has('ssl'),
            hasCrefo:has('crefo'),
        },
        {
            width:'500px',
            height: "auto",
            classes: [
                "seals-modal",
                "modalMaxHeight",
                "rounded-0"
            ]
        });
}