<template lang="pug">
.bp-management.w-100.position-relative
  .zitat-holder.w-100
    img(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/about-tdays/edmund-rund.png" alt="Edmund Schulte")
    img(src="https://s3-cloud.td-cdn.de/Images/makaira/modals/about-tdays/marcel-rund.png" alt="Marcel Schulte")
    .h3 "Es ist unser Anspruch,
      br
      | Ihnen ein perfektes Urlaubserlebnis zu bieten."
      br
      small (Edmund & Marcel Schulte, Geschäftsleitung)
</template>

<script>
export default {
  name: "BuyersProtectionManagement"
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';
.zitat-holder {
  padding: 30px 30px 20px;
  text-align: center;
  background: radial-gradient(
          ellipse at center,
          rgba(240, 240, 240, 0) 0%,
          rgba(221, 221, 221, 1) 100%
  );
  img {
    width: 120px;
    margin: 10px;
    border: 1px solid $td-grey-lighter;
    border-radius: 100%;
  }
}
</style>