import { calculateDiscount, clamp, isNumeric } from "@/lib/helper/math";
import moment from "moment";
import { Items } from "@/graphql/Items.ts";
import { DiscountTypesEnum } from "@/utils/DiscountTypesEnum.ts";

export const getCurrentItemPrice = (item) => {
  if (item.CurrentPrice) return item.CurrentPrice;
  if (item.IsInLiveShopping && item.LiveShoppingPrice) return item.LiveShoppingPrice;
  return item.DiscountedPrice;
};

export const listItemDiscounts = (items) => {
  if (!Array.isArray(items) || items.length === 0) return [];
  return items.map((item) => {
    const isDiscounted = item?.IsTouriTippsItem || false;
    return calculateDiscount(item, isDiscounted);
  });
};
export const getIsItemOutOfStock = (item) => {
  if (item.IsInLiveShopping && item.StockAvailableQuantityDealPrice - item.StockReservedQuantityDealPrice <= 0) return true;
  return item.StockAvailableQuantityRegularPrice - item.StockReservedQuantityRegularPrice <= 0;
};
/**
 * Checks if the given item is either out of stock or has IsVisibleInItemListing set to false
 * @param item
 * @returns {boolean}
 */
export const getIsItemPurchasable = (item) => {
  return !getIsItemOutOfStock(item) && item.IsVisibleInItemListing;
};

export const getHighestDiscountForItems = (items) => {
  const discounts = listItemDiscounts(items);
  const sortedDiscounts = [...discounts].sort((a, b) => b - a);
  return sortedDiscounts[0];
};
const matchesCatering = (str) => {
  const matchList = ["frühstück", "buffet", "pension", "3/4", "all inklusive", "menü", "gericht", "jause", "dinner", "snack", "getränke", "kaffee", "kuchen", "essen", "langschläferbrunch", "mahlzeit"];
  for (const entry of matchList) {
    if (str.includes(entry)) return true;
  }
  return false;
};
/**
 *
 * @param item
 * @returns {Array<string>}
 */
export const getItemCaterings = (item) => {
  try {
    if (!item) return [];
    let caterings;
    if (item.CustomAttributes?.Caterings) {
      caterings = item.CustomAttributes?.Caterings;
    } else if (item.CustomAttributes?.Custom_Product_HotelServices) {
      caterings = item.CustomAttributes?.Custom_Product_HotelServices;
    }
    if (!caterings) {
      return [];
    }
    caterings = caterings.filter((service) => {
      return matchesCatering(service.toLowerCase());
    });
    return caterings?.length ? caterings : [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getItemSpecial = (item) => {
  const special = item?.CustomAttributes?.Custom_Product_Specials;
  if (!special) return;
  let parts = special.split("+");
  if (parts.length <= 1) parts = special.split("&");
  if (parts.length <= 1 && matchesCatering(special.toLowerCase())) return;
  if (parts.length > 1) return parts[parts.length - 1];
  return special.replace("inkl.", "").trim();
};

export const getItemHolidayServices = (item) => {
  if (!item.CustomAttributes?.Custom_Product_HotelServices) return [];
  const caterings = getItemCaterings(item);
  const accommodation = getItemAccommodation(item);
  return item.CustomAttributes?.Custom_Product_HotelServices.filter((service) => {
    return service !== accommodation && !caterings.includes(service);
  });
};

export const getItemDiscountPercentage = (item) => {
  if (!item.CurrentPrice || !item.RegularPrice) return;
  return (1 - (item.CurrentPrice / item.RegularPrice)) * 100;
};

export const getLiveShoppingDeadline = (item, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!item.AdvertisedTill || !isItemInLiveShopping(item)) return;
  return moment(item.AdvertisedTill).format(format);
};

export const isItemInLiveShopping = (item) => {
  if (!item.IsInLiveShopping || !item.AdvertisedFrom || !item.AdvertisedTill) return false;
  const advertisedFrom = moment(item.AdvertisedFrom).format("YYYY-MM-DD HH:mm:ss");
  const advertisedTill = moment(item.AdvertisedTill).format("YYYY-MM-DD HH:mm:ss");
  const now = Date.now();
  return moment(now).isAfter(advertisedFrom) && moment(now).isSameOrBefore(advertisedTill);
};

export const getItemAccommodation = (item) => {
  if (!item) return;
  if (item.CustomAttributes?.Accomodations) return Array.isArray(item.CustomAttributes.Accomodations) ? item.CustomAttributes.Accomodations[0] : item.CustomAttributes.Accomodations;
  if (!item.CustomAttributes?.Custom_Product_HotelServices) return;
  const accommodation = item.CustomAttributes?.Custom_Product_HotelServices?.find((service) => service.toLowerCase().includes("übernachtung"));
  return Array.isArray(accommodation) ? accommodation[0] : accommodation;
};

export const availableAccommodations = [
  "Einzelzimmer",
  "Doppelzimmer",
  "Suite",
  "Appartement",
  "Ferienhaus",
  "Ferienwohnung"
  //'Familienzimmer'
];

export const getSimpleItemAccommodation = (item) => {
  const accommodation = getItemAccommodation(item);
  if (!accommodation) return;
  for (const type of availableAccommodations) {
    if (type === "Apartment" && (accommodation.toLowerCase().includes("appartement") || accommodation.toLowerCase().includes("apartement") || accommodation.toLowerCase().includes("appartment")))
      return type;
    if (accommodation.toLowerCase().includes(type.toLowerCase())) return type;
  }
  const parts = accommodation.split("im ");
  return parts[parts.length - 1];
};

export const calculateScore = (SoldWithin24h, SoldWithin1W, SoldWithin1M, SoldWithin1Y) => {
  if (typeof SoldWithin24h !== "number") SoldWithin24h = 0;
  if (typeof SoldWithin1W !== "number") SoldWithin1W = 0;
  if (typeof SoldWithin1M !== "number") SoldWithin1M = 0;
  if (typeof SoldWithin1Y !== "number") SoldWithin1Y = 0;

  return SoldWithin24h * 0.5 + SoldWithin1W * 0.25 + SoldWithin1M * 0.15 + SoldWithin1Y * 0.1;
};

export const getScoreForItem = (item) => {
  return calculateScore(item.SoldWithin24h, item.SoldWithin1W, item.SoldWithin1M, item.SoldWithin1Y);
};

export const loadHostItems = async (apollo, hostId) => {
  if (!isNumeric(hostId) || !apollo?.query) return [];
  return await apollo
    .query({
      query: Items.Queries.HostItems,
      variables: {
        hostId,
        isVisibleInItemListing: true
      }
    })
    .then((response) => response?.data?.hostItems || []);
};

export const orderItemsAscendingByPrice = (items) => {
  if (!Array.isArray(items) || !items.every((item) => isNumeric(item.CurrentPrice))) return items;
  const _items = [...items];
  return _items.sort((a, b) => {
    return a.CurrentPrice - b.CurrentPrice;
  });
};

export const getCurrentTippsDiscounts = (session) => {
  try {
    const sessionKey = "appliedTippsDiscounts";
    return session ? session.get(sessionKey) || [] : [];
  } catch (e) {
    console.error(e);
  }
};

export const hasTippsDiscount = (session, itemId) => {
  try {
    const discounts = getCurrentTippsDiscounts(session);
    return discounts.includes(itemId);
  } catch (e) {
    console.error(e);
  }
};

export const getTippsDiscountValue = (item) => {
  if (!item || !item.DiscountedPrice || !item.LiveShoppingPrice) return;
  return item.DiscountedPrice - item.LiveShoppingPrice;
};

export const createCheckoutBasket = (items) => {
  if (!Array.isArray(items)) return;
  return items
    .filter((item) => {
      return !!(item.id || item.ItemId);
    })
    .map((i) => {
      return {
        ItemId: i.id || i.ItemId,
        Quantity: i.Quantity ? parseInt(i.Quantity) : 1,
        IsTippsItem: i.IsTippsItem || (i.IsDiscounted && i.DiscountType === DiscountTypesEnum.TippsDiscount)
      };
    });
};

export const getRegularQuantity = (item) => {
  const availableQuantity = item?.StockAvailableQuantityRegularPrice || 0;
  const reservedQuantity = item?.StockReservedQuantityRegularPrice || 0;

  return clamp(availableQuantity - reservedQuantity, 0);
};

export const getDealQuantity = (item) => {
  const availableQuantity = item?.StockAvailableQuantityDealPrice || 0;
  const reservedQuantity = item?.StockReservedQuantityDealPrice || 0;

  return clamp(availableQuantity - reservedQuantity, 0);
};
