<template lang="pug">
header.host-header
  .d-flex.align-items-center.justify-content-between.h1.m-0
    .title.w-100
      .d-inline-flex.align-items-center(v-if="title")
        h1 {{title}}
        HostPreviewStars.d-inline-block.ml-2(v-if="stars" :stars="stars" :is-superior="isSuperior" style="font-size:50%;")
      .loader(v-else) &nbsp;
    slot(name="afterTitle")
      //HostFavoriteIcon.fav-icon(v-if="hasFavIcon && hostId" :host-id="hostId")
  .txt-td-grey-basic.h2.mx-0.mt-2.mb-0
    template(v-if="subTitle !== undefined") {{subTitle}}
    .loader(v-else) &nbsp;
</template>

<script>
import HostPreviewStars from "@/views/components/hostPreview/HostPreviewStars";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon";
export default {
  name: "HostHeader",
  components: { HostFavoriteIcon, HostPreviewStars },
  props: {
    title: {
      type: String,
    },
    subTitle: String,
    stars: Number,
    isSuperior: Boolean,
    hasFavIcon: {
      type: Boolean,
      default: true,
    },
    hostId: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries.scss";
.fav-icon {
  font-size: 2rem;
  @include md {
    font-size: 1.6875rem;
  }
}
</style>
