<template lang="pug">
  .host-gallery.big-gallery(@click="handleClick")
    .grid.w-100
      template(v-if="imageUrls && imageUrls.length > 0")
        .gallery-item(v-for="imageUrl in imageUrls")
          img.img-fluid.w-100(:src="imageUrl")
      .gallery-item(v-else v-for="n in 5")
    button.button.button-primary.button-tdays.position-absolute(v-if="hasShowAllButton && imageUrls && imageUrls.length > 0") Alle Bilder
</template>
<script>
import FullHostGallery from "@/views/components/host/gallery/FullHostGallery";

export default {
  name: "BigHostGallery",
  props: {
    images: {
      type: Array
    },
    hasShowAllButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imageUrls() {
      if (!this.images) return [];
      const images = this.images.slice(0, 5);
      return images.map((image, index) => {
        if (!index) return `${image.RemoteURL}large.jpg`;
        return `${image.RemoteURL}medium.jpg`;
      });
    }
  },
  methods: {
    handleClick(e) {
      if (!this.hasShowAllButton) return;
      if (!e?.target || e.target?.className === "grid") return;
      this.showExtendedGallery();
    },
    showExtendedGallery() {
      try {
        this.$modal.show(
          FullHostGallery,
          { images: this.images },
          {
            classes: [
              "full-host-gallery"
            ]
          },
          {
            closed: () => {
              document.body.classList.remove("no-scroll");
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mediaqueries.scss";

.host-gallery.big-gallery {
  position: relative;

  .grid {
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    grid-column-gap: 13px;
    grid-row-gap: 10px;

    @include lg {
      grid-column-gap: 25px;
      grid-row-gap: 18px;
    }

    & > .gallery-item {
      aspect-ratio: 4 / 3;
      overflow: hidden;
      cursor: pointer;
      background-image: linear-gradient(90deg, rgba(221, 221, 221, 1) 0%, rgba(255, 255, 255, 1) 12.5%, rgba(221, 221, 221, 1) 50%, rgba(255, 255, 255, 1) 62.5%, rgba(221, 221, 221, 1) 100%);
      background-size: 200%;
      animation: loaderAnimation 2s linear infinite normal forwards;

      @keyframes loaderAnimation {
        0% {
          background-position: 0;
        }
        100% {
          background-position: 100%;
        }
      }

      &:first-of-type {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      img {
        transform: scale(1);
        transition: transform 0.8s;
        pointer-events: none;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  .button {
    bottom: 25px;
    right: 25px;
    opacity: 0;
    transition: 0.4s;
  }

  &:hover {
    .button {
      opacity: 1;
    }
  }
}
</style>
