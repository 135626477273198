<template lang="pug">
  .modal-dialog.m-0
    .modal-content(v-if="item")
      .modal-header
        .w-100.d-flex.align-items-center.justify-content-between
          h3.modal-title {{hotelName}}
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body
        .scroller
          .row.mb-3
            .col-12
              ResponsiveHostGallery(:images="galleryImages")
          b-tabs.w-100.position-relative
            b-tab(title="Übersicht")
              .w-100
                HostItemDetails(v-bind="itemDetailAttributes" :shows-touri-book-info="false" :shows-article-number="false" :animated="false")
            b-tab(title="Gastgeber" v-if="host && host.id")
              div(style="max-height:500px;overflow:hidden;overflow-y:auto;")
                HotelDescription(:host-id="host.id")
      .modal-footer
</template>

<script>
import ResponsiveHostGallery from "@/views/components/host/gallery/ResponsiveHostGallery";
import HotelDescription from "@/views/components/partials/hotelDescription/HotelDescription";
import HostItemDetails from "@/views/components/host/layout/HostItemDetails.vue";
import { getItemAccommodation, getItemCaterings, getItemHolidayServices } from "@/lib/helper/items";

export default {
  name: "SelectionVoucherItemInfoModal",
  components: { HostItemDetails, HotelDescription, ResponsiveHostGallery },
  props: {
    item: {
      type: Object,
      required: true
    },
    host: Object
  },
  computed: {
    itemDetailAttributes() {
      if (!this.item) return {};
      return {
        title: this.item?.CustomAttributes?.Custom_Product_Arrangement,
        articleNumber: this.item?.SystemItemId,
        accommodation: getItemAccommodation(this.item),
        caterings: getItemCaterings(this.item),
        holidayServices: getItemHolidayServices(this.item),
        touristServices: this.item?.CustomAttributes?.Custom_Product_TouristServices,
        additionalInfo: this.item?.CustomAttributes?.Custom_Product_ServicesExtra,
        bookability: this.item?.CustomAttributes?.Custom_Product_Bookability,
        validity: this.item?.CustomAttributes?.ProductValidity,
        id: this.item.id
      };
    },
    hotelName() {
      return this.item?.CustomAttributes.Custom_Hotel_Name || this?.host?.Name;
    },
    galleryImages() {
      const images = this.item?.CustomAttributes?.Custom_Product_Images || this?.host?.Images;
      if (!images) return [];
      return images.filter((image) => {
        const name = image?.Name?.toLowerCase();
        if (!name) return false;
        const remoteUrl = image?.RemoteURL;
        if (!remoteUrl) return false;
        return (
          !name.includes("logo") &&
          !name.includes("ebay") &&
          !remoteUrl.includes(".jpg")
        );
      });
    }
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/mediaqueries.scss";

.sv-info-modal {
  width: 100% !important;
  max-width: 800px !important;
  left: 50% !important;
  transform: translate(-50%, 0%);
  max-height: 100% !important;
  @include md {
    max-height: calc(100% - 50px) !important;
  }

  .scroller {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .modal-dialog {
    max-width: none !important;
  }

  .modal-header {
    flex-wrap: wrap;
  }
}
</style>
