<template lang="pug">
  button.shopping-basket-button.button.button-primary.bg-rainbow.txt-white(title="Warenkorb Anzeigen" @click="$emit('click',$event)")
    .quantity.d-flex.align-items-center.justify-content-center.font-weight-bold {{ shoppingBasketQuantity }}
    font-awesome-icon.icon(icon="shopping-cart")
</template>
<script>
export default {
  name: "ShoppingBasketButton",
  computed: {
    shoppingBasketQuantity() {
      return this.$shoppingBasket.items.length;
    }
  }
};
</script>
<style scoped lang="scss">
.shopping-basket-button {
  position: relative;

  & > * {
    pointer-events: none !important;
  }

  .quantity {
    background-color: red;
    position: absolute;
    left: calc(var(--quantity-offset, 5px) * -1);
    top: calc(var(--quantity-offset, 5px) * -1);
    aspect-ratio: 1;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
  }

  .icon {
    transition: transform 0.4s;
  }

  &:hover {
    .icon {
      transform: scale(1.1);
    }
  }
}
</style>
