import gql from "graphql-tag";

export const Mailer = {
  Mutations: {
    sendCallbackMail: gql`
        mutation sendCallbackMail($mailData: MailData, $token:String!) {
            sendCallbackMail(mailData: $mailData, token:$token) {
                IsSuccess
                Message
            }
        }`,
    sendContactFormMail: gql`
        mutation sendContactFormMail($mailData: MailData) {
            sendContactFormMail(mailData: $mailData) {
                IsSuccess
                Message
            }
        }`,
    sendBecomeAHostMail: gql`
        mutation sendBecomeAHostMail($mailData: MailData) {
            sendBecomeAHostMail(mailData: $mailData) {
                IsSuccess
                Message
            }
        }`
  }
};
