<template lang="pug">
  .text-white
    header.d-flex.align-items-start.justify-content-center
      button.close-btn.no-style.position-absolute.rounded-circle.d-flex.align-items-center(style="right:0.5rem;top:0.5rem;aspect-ratio:1;border:1px solid white;" @click="handleClose")
        font-awesome-icon.text-white.p-1(icon="times")
      .bg-black.rounded-circle.w-100(style="max-width:120px;aspect-ratio:1;margin-top:-80px;border:5px solid white;")
        LogoComponent.bg-white.w-100(v-if="!isLoading")
        LoadingSpinner.w-100.h-100(v-else)
    .p-4
      .contents(:style="isLoading ? `opacity:0.5;cursor:wait;` : `opacity:1`")
        .h2 Jetzt anmelden und 10€ Gutschein für den nächsten Einkauf sichern!
        div Abonnieren Sie unseren Newsletter und erhalten Sie die besten Deals für unvergessliche Reisen direkt in Ihr Postfach.
        .mt-4
          input.w-100.p-2(type="email" v-model.trim="emailAddress" placeholder="Ihre E-Mail Adresse")
          button.button.button-primary.button-tdays.w-100.mt-2(:disabled="!hasValidEmailAddress || isLoading" :title="!hasValidEmailAddress ? `Bitte geben Sie zunächst eine gültige E-Mail Adresse ein` : `Anmelden`" @click="handleRegistration") Anmelden
</template>
<script>
import LogoComponent from "@/views/components/logo/LogoComponent.vue";
import DiscountVoucherCategory from "@/views/category/DiscountVoucherCategory.vue";
import { Utils } from "@/graphql/Utils.ts";
import moment from "moment";
import LoadingSpinner from "@/views/components/loading/LoadingSpinner.vue";

export default {
  name: "NewsletterPopup",
  emits: ["close"],
  components: { LoadingSpinner, DiscountVoucherCategory, LogoComponent },
  data() {
    return {
      isLoading: false,
      emailAddress: ""
    };
  },
  computed: {
    hasValidEmailAddress() {
      return this.emailAddress?.includes("@") && this.emailAddress?.includes(".");
    }
  },
  methods: {
    setSessionInfo() {
      const hideTill = moment(new Date()).add(2, "weeks").toDate();
      this.$session.set("hideNlDiscountPopupTill", hideTill);
    },
    handleClose() {
      this.setSessionInfo();
      this.$emit("close");
    },
    async handleRegistration() {
      await this.sendData();
      this.setSessionInfo();
    },
    async sendData() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: Utils.Mutations.createMarketingNewsletterDiscountCode,
          variables: {
            data: {
              email: this.emailAddress,
              referer: `touridat.com - 10% Gutschein für Newsletteranmeldung`
            }
          }
        }).then((response) => response.data.createMarketingNewsletterDiscountCode);
        await this.$alert(
          "Sie haben sich erfolgreich für unseren Newsletter angemeldet. Nachdem Sie Ihre Anmeldung bestätigt haben, sollten Sie in kürze eine E-Mail mit dem Gutschein-Code erhalten",
          "Anmeldung erfolgreich",
          "success"
        );
        this.$emit("close");
      } catch (e) {
        const [err] = e?.graphQLErrors || [undefined];
        const errorCode = err?.extensions?.code;
        switch (errorCode) {
          default:
            this.$alert("Es ist ein unbekannter Fehler aufgetreten.");
            break;
          case "DUPLICATE_RECEIVER":
          case "SIGN_UP_ERROR":
            this.$alert("Diese E-Mail Adresse ist bereits vorhanden.");
            break;
        }
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/colors";

.newsletter-modal {
  background-color: $tdays-light !important;
  overflow: initial !important;
}
</style>
<style lang="scss" scoped>
.close-btn {
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.loading,
.contents {
  transition: opacity 0.4s;
}
</style>
